import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import cc from "../../../../assets/svg/cc-pay.svg"
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import useSnackMessages from "src/hooks/useSnackMessages";
import ShippingForm from "../Steps/ShippingForm";
import { usePrevious } from "src/utils/hooks";

import styles from "./CreditCardPayment.module.scss";

const getCardOptions = () => {
  const element = document.getElementsByTagName('body')[0];
  const isDarkTheme = element.classList.contains("dark-mode");
  const primaryTextColor = isDarkTheme ? "#FFFFFF" : "#000000";

  const CARD_OPTIONS = {
    iconStyle: "solid",
    height: "56px",
    background: 'transparent',
    border: `1px solid ${primaryTextColor}`,
    backgroundColor: 'transparent',
    style: {
      base: {
        backgroundColor: 'transparent',
        border: `1px solid ${primaryTextColor}`,
        height: '76px',
        lineHeight: '52px',
        color: primaryTextColor,
        iconColor: primaryTextColor,
        fontWeight: 500,
        fontFamily: "inherit",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        textTransform: "uppercase",
        ":-webkit-autofill": {
          color: primaryTextColor
        },
        "::placeholder": {
          color: primaryTextColor
        },
        ":active": {
          backgroundColor: 'transparent'
        }
      },
      invalid: {
        iconColor: "#FCBA04",
        color: "#FCBA04"
      }
    }
  };

  return CARD_OPTIONS
}


const defaultBillingAggress = {
  address_line1: '',
  address_line2: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  address_country: 'US'
};

const CreditCardPayment = ({
  proceed,
  deliveryInfo
}) => {
  const { msgError, msgWarning } = useSnackMessages();
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const [userName, setUserName] = useState(`${deliveryInfo.firstName || ''} ${deliveryInfo.lastName || ''}`);
  const [isSameAddress, setIsSameAddress] = useState(true);
  const [billingAggress, setBillingAggress] = useState(defaultBillingAggress);

  const prevIsSameAddress = usePrevious(isSameAddress);

  useEffect(() => {
    if (isSameAddress && !prevIsSameAddress) {
      setBillingAggress({
        address_line1: deliveryInfo.street,
        address_line2: '',
        address_city: deliveryInfo.city,
        address_state: deliveryInfo.state,
        address_zip: deliveryInfo.zip,
        address_country: deliveryInfo.country
      })
    }

    if (!isSameAddress && prevIsSameAddress) {
      setBillingAggress(defaultBillingAggress);
    }
  }, [deliveryInfo, billingAggress, isSameAddress, prevIsSameAddress]);

  const checkout = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      msgWarning("Error with stripe")
      return;
    }
    if (!cardComplete) {
      msgWarning("Card details incomplete")
      return
    }
    const billingAddressFilled = Object.keys(billingAggress)
      .filter((key) => key !== 'address_line2')
      .every((key) => billingAggress[key] !== '');
    if (!billingAddressFilled) {
      msgWarning("Billing address incomplete");
      return
    }

    const cardElement = elements.getElement(CardElement);
    const data = await stripe.createToken(cardElement, {
      ...billingAggress,
      name: userName
    });
    if (data.token) {
      proceed(data.token.id, data.token.card.last4)
    } else {
      msgError("Ups, something went wrong with stripe, try again later or contact us directly")
    }
  }

  return (
    <div className={styles.container}>
      <img className={styles.cardImage} src={cc} alt="credit-card" />
      <div className={styles.cardWrapper}>
        <CardElement
          className={styles.card}
          options={getCardOptions()}
          onChange={(e) => setCardComplete(e.complete)}
        />
      </div>
      <TextField
        fullWidth
        color="secondary"
        variant="outlined"
        name="fullName"
        placeholder="Full Name"
        className={styles.field}
        value={userName}
      />
      <p className={styles.address}>
        Billing Address
      </p>
      <div className={styles.section}>
        <span
          className={`${styles.toggle} ${isSameAddress ? styles.active : ""}`}
          onClick={() => setIsSameAddress(true)}
        />
        <div className={styles.titleWrapper}>
          <span className={styles.value}>
            Same as delivery address
          </span>
        </div>
      </div>
      <div className={styles.section}>
        <span
          className={`${styles.toggle} ${!isSameAddress ? styles.active : ""}`}
          onClick={() => setIsSameAddress(false)}
        />
        <div className={styles.titleWrapper}>
          <span className={styles.value}>
            Other than delivery address
          </span>
        </div>
      </div>
      {!isSameAddress && (
        <ShippingForm
          update={(values) => {
            setBillingAggress({
              address_line1: values.street,
              address_line2: '',
              address_city: values.city,
              address_state: values.state,
              address_zip: values.zip,
              address_country: values.country
            });
          }}
        />
      )}
      <div className={styles.enterWrapper}>
        <button
          className={styles.enter}
          value="enter"
          onClick={() => checkout()}
        >
          Enter
        </button>
      </div>
    </div>
  );

};

CreditCardPayment.propTypes = {
  proceed: PropTypes.func.isRequired
};

export default CreditCardPayment
