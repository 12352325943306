const theme = {
    // color, background-color, border-color
    colors: {
        favoriteColor: '#cccccc',
        // user colors
        userYellow: 'yellow',
        // favorite colors
        yellow: '#FDDE47',
        yellowPortal: '#F7F702',
        green: '#006600',
        blue: '#0000C2',
        orange: '#FFA500',
        brown: '#A52A2A',
        red: '#FF5636',
        purple: '#800080',
        /**  blue */
        bluePrimary: '#3A77FF',
        blueTintDark: '#6C99FF',
        blueTintLight: '#9CBBFF',
        blueDark: '#1D1381',
        blueLight: '#CEDDFF',
        blueBgDark: '#17284E',
        blueBgLight: '#EBF1FF',

        /**  red */
        redPrimary: '#FF5636',
        redTintDark: '#FF8169',
        redTintLight: '#FFAA9A',
        redDark: '#AA133D',
        redLight: '#FFD6C9',
        redBgDark: '#5D142C',
        redBgLight: '#FFEEEA',

        /** teal */
        tealTintLight: '#91F2ED',
        tealTintDark: '#5AECE4',
        tealDark: '#00A49F',
        tealLight: '#C8F8F6',
        tealPrimary: '#23E5DB',
        tealBgDark: '#085C5D',
        tealBgLight: '#E9FCFB',

        /** yellow */
        yellowPrimary: '#FFCE32',
        yellowTintDark: '#FFE48D',
        yellowTintLight: '#FFEDB2',
        yellowDark: '#D2B982',
        yellowLight: '#FFF6D9',
        yellowBgDark: '#71674E',
        yellowBgLight: '#FFFBEF',

        /** grey */
        black: '#000000',
        grey6: '#ECEEEF',
        grey5: '#406367',
        grey4: '#7F9799',
        grey3: '#D8DFE0',
        grey2: '#F2F4F5',
        grey1: '#FAFBFB',

        /** white */
        white: '#FFFFFF',
        shortWhite: '#FFF',

        /** orphan colors */
        error: '#DE1609',

        /** transparent */
        transparent: 'transparent'
    },
    fonts: {
        HelveticaNeue: 'HelveticaNeue'
    },
    fontSizes: {
        11: '11px',
        12: '12px',
        14: '14px',
        16: '16px',
        18: '18px',
        20: '20px',
        22: '22px',
        24: '24px',
        26: '26px',
        28: '28px',
        30: '30px',
        32: '32px',
        34: '34px',
        36: '36px',
        38: '38px',
        40: '40px'
    },
    // margin and padding
    space: {
        0: '0',
        2: '2px',
        4: '4px',
        6: '6px',
        8: '8px',
        10: '10px',
        12: '12px',
        14: '14px',
        16: '16px',
        18: '18px',
        20: '20px',
        22: '22px',
        24: '24px',
        26: '26px',
        28: '28px',
        30: '30px',
        32: '32px',
        36: '36px',
        38: '38px',
        40: '40px',
        48: '48px'
    },
    fontWeights: {
        100: 100,
        200: 200,
        300: 300,
        400: 400,
        500: 500,
        600: 600,
        700: 700,
        800: 800
    },
    lineHeights: {
        1: 1,
        12: '12px',
        14: '14px',
        16: '16px',
        18: '18px',
        20: '20px',
        22: '22px',
        24: '24px',
        26: '26px',
        34: '34px',
        42: '42px'
    },
    letterSpacings: {
        0: '0',
        '0.5': '0.5px',
        '0.8': '0.8px',
        '1': '1px'
    },
    borders: {
        0: 'none',
        grey4200: '2px solid #7f9799',
        grey4500: '5px solid #7f9799',
        grey3100: '1px solid #d8dfe0',
        grey3200: '2px solid #d8dfe0',
        grey3500: '5px solid #d8dfe0',
        charcoal200: '2px solid #002f34',
        charcoal500: '5px solid #002f34'
    },
    borderWidths: {
        0: '0',
        1: '1px',
        2: '2px',
        4: '4px',
        6: '6px',
        8: '8px',
        10: '10px'
    },
    borderStyles: {
        none: 'none',
        solid: 'solid'
    },
    // border radius
    radii: {
        0: '0',
        2: '2px',
        4: '4px',
        12: '12px'
    },
    // text/box shadows
    shadows: {
        4: '0 1px 4px hsla(0, 0%, 0%, 0.16)',
        8: '0 2px 8px hsla(0, 0%, 0%, 0.16)'
    },
    // z-index
    // read more about z-index https://getbootstrap.com/docs/4.4/layout/overview/#z-index
    zIndices: {
        dropdown: 1000,
        sticky: 1020,
        fixed: 1030,
        modalBackdrop: 1040,
        modal: 1050,
        popover: 1060,
        tooltip: 1070
    },
    // 	width, height, min-width, max-width, min-height, max-height
    sizes: {
        0: '0',
        2: '2px',
        4: '4px',
        6: '6px',
        8: '8px',
        10: '10px',
        12: '12px',
        14: '14px',
        16: '16px',
        18: '18px',
        20: '20px',
        22: '22px',
        24: '24px',
        32: '32px',
        40: '40px',
        48: '48px',
        56: '56px',
        64: '64px',
        72: '72px',
        80: '80px',
        96: '96px',
        128: '128px',
        192: '192px'
    },
    breakpoints: ['40em', '52em', '64em'],
    globalPaddingHoriozontal: '16px',
    bottomBarHeight: '48px',
    node_id: ''
} as const;

export default theme;
