import React from 'react';
import { ReactComponent as DivvyIcon } from 'src/assets/portals/divvyland/divvy-icon.svg';
import { ReactComponent as DivvyToken } from 'src/assets/portals/divvyland/divvy-token.svg';
import DivvyBanner from '../assets/portals/divvyland/divvy-banner.png';

import { ReactComponent as PnsIcon } from 'src/assets/portals/playneverstops/pns-icon.svg';
import pnsBanner from 'src/assets/portals/playneverstops/pns-banner.png';
import { ReactComponent as PnsToken } from 'src/assets/portals/playneverstops/pns-token.svg';

import { ReactComponent as IvIcon } from 'src/assets/portals/invest/invest-icon.svg';
import { ReactComponent as IvToken } from 'src/assets/portals/invest/invest-token.svg';
import IvBanner from '../assets/portals/invest/invest-banner.svg';

import { ReactComponent as LabelAssetToken } from 'src/assets/portals/labelasset/labelasset-token.svg';
import LabelAssetBanner from 'src/assets/portals/labelasset/labelasset-banner.svg';
import { ReactComponent as LabelAssetIcon } from 'src/assets/portals/labelasset/labelasset-icon.svg';
import { ReactComponent as LabelAssetBlackIcon } from 'src/assets/portals/labelasset/labelasset-black-icon.svg';

import { ReactComponent as ResourcePortalIcon1 } from 'src/assets/portals/resource-portal-icon-1.svg';
import { ReactComponent as ResourcePortalIcon3 } from 'src/assets/portals/resource-portal-icon-3.svg';
import portalTypes from './portalTypes';

import { ReactComponent as SayBIcon } from 'src/assets/portals/saybrandon/saybrandon-icon.svg';
import { ReactComponent as SayBToken } from 'src/assets/portals/saybrandon/saybrandon-token.svg';
import SayBBanner from '../assets/portals/saybrandon/sayb-banner.svg';

const getFooterIcons = (link1, link2 = '') => {
    return [
        // {
        //     showAlways: true,
        //     icon: <ResourcePortalIcon1 />,
        //     path: link1
        // },
        // {
        //     showAlways: true,
        //     icon: <ResourcePortalIcon3 />,
        //     path: link2
        // }
    ];
};

export const ResourcePortals = [
    {
        key: 'saybrandon',
        name: 'SayBrandOn',
        showWhenLoggedOut: true,
        link: '/saybrandon',
        url: 'https://saybrandon.com',
        base: '/saybrandon',
        mobileLink: '/saybrandon',
        icon: <SayBIcon />,
        footerIcons: getFooterIcons(
            '/saybrandon/info',
            '/saybrandon/communication'
        ),
        type: portalTypes.RESOURCE,
        scroll: {
            background: '#E5E5E5',
            thumb: '#000'
        },
        banner: {
            token: <SayBToken />,
            icon: <SayBIcon />,
            background: '#DC143C',
            color: '#fff',
            image: SayBBanner,
            title: 'SayBrandOn (SAYB)',
            subtitle: 'Recource Portal',
            description: 'POP CULTURE LIBRARY'
        }
    },
    {
        key: 'investvisual',
        name: 'invest visual',
        showWhenLoggedOut: true,
        link: '/investvisual',
        base: '/investvisual',
        url: 'https://investvisual.com',
        mobileLink: '/investvisual',
        icon: <IvIcon />,
        footerIcons: getFooterIcons('/investvisual/info', '/wipp'),
        type: portalTypes.RESOURCE,
        scroll: {
            background: '#E5E5E5',
            thumb: '#000'
        },
        banner: {
            token: <IvToken />,
            icon: <IvIcon />,
            background: '#040403',
            color: '#fff',
            image: IvBanner,
            title: 'InvestVisual (IV)',
            subtitle: 'Recource Portal',
            description: 'ALTERNATIVE ASSETS'
        }
    },
    {
        key: 'divvyland',
        name: 'divvyland',
        showWhenLoggedOut: true,
        link: '/divvyland',
        base: '/divvyland',
        url: 'https://divvyland.com',
        mobileLink: '/divvyland',
        icon: <DivvyIcon />,
        type: portalTypes.RESOURCE,
        footerIcons: getFooterIcons('/divvyland/info', '/wipp'),
        scroll: {
            background: '#E3854C',
            thumb: '#E7E600'
        },
        banner: {
            token: <DivvyToken />,
            icon: <DivvyIcon />,
            background: '#C4C3C4',
            color: '#000',
            image: DivvyBanner,
            title: 'Divvyland (DIVVY)',
            subtitle: 'Recource Portal',
            description: 'A CONSORTIUM OF PURVEYORS ORCHESTRATING THE PRODUCTION OF PHYSICAL AND DIGITAL GOODS'
        }
    },
    {
        key: 'playneverstops',
        name: 'play never stops',
        showWhenLoggedOut: true,
        type: portalTypes.RESOURCE,
        link: '/playneverstops',
        base: '/playneverstops',
        url: 'https://playneverstops.com',
        mobileLink: '/playneverstops',
        icon: <PnsIcon />,
        footerIcons: getFooterIcons('/playneverstops/info', '/wipp'),
        banner: {
            token: <PnsToken />,
            icon: <PnsIcon />,
            background: '#FFA80A',
            color: '#000',
            image: pnsBanner,
            title: 'PlayNeverStops (PNS)',
            subtitle: 'Recource Portal',
            description: 'A CREATIVE AGENCY FOR IMAGINATION CULTURE'
        },
        scroll: {
            background: '#cc8400',
            thumb: '#ffd27f'
        }
    },
    {
        key: 'labelasset',
        name: 'labelasset',
        link: '/labelasset',
        url: 'https://labelasset.com',
        base: '/labelasset',
        mobileLink: '/labelasset',
        showWhenLoggedOut: true,
        icon: <LabelAssetBlackIcon />,
        footerIcons: getFooterIcons('/labelasset/info', '/wipp'),
        withTopBorder: true,
        type: portalTypes.RESOURCE,
        scroll: {
            background: '#4d267f',
            thumb: '#d7b7ff'
        },
        banner: {
            token: <LabelAssetToken />,
            icon: <LabelAssetIcon />,
            background: '#040403',
            color: '#FFFFFF',
            image: LabelAssetBanner,
            title: 'Label Asset (SS)',
            subtitle: 'Recource Portal',
            description: 'DIGITAL AND PHYSICAL ASSET STORAGE AND MARKETPLACE'
        }
    }
];
