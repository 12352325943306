import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { Image } from 'react-bootstrap';

import CheckoutBasket from './CheckoutBasket/CheckoutBasket';
import Step2 from './Steps/Desktop/Step2';
import Step3 from './Steps/Desktop/Step3';
import Step4 from './Steps/Desktop/Step4';
import Step5 from './Steps/Desktop/Step5';
import Step6 from './Steps/Desktop/Step6';

import CheckoutBasketMobile from './CheckoutBasket/CheckoutBasketMobile';
import StepMobile2 from './Steps/Mobile/StepMobile2';
import StepMobile3 from './Steps/Mobile/StepMobile3';
import StepMobile4 from './Steps/Mobile/StepMobile4';
import StepMobile5 from './Steps/Mobile/StepMobile5';
import StepMobile6 from './Steps/Mobile/StepMobile6';

import { useSettings } from 'src/contexts/SettingsContext';
import { updateCart } from 'src/store/actions/app';
import useFetch from 'src/services/useFetch';
import logo from '../../../assets/svg/imagine-council-original.svg';
import { ReactComponent as PoweredByStripe } from 'src/assets/svg/powered_by_stripe.svg';

import { useCheckout } from 'src/contexts/CheckoutContext';

import './checkout.scss';
import StepTitle from './StepTitle';
import { useAuth } from 'src/contexts/AuthContext';
import StepList from './StepList';
import LoaderComponent from 'src/components/common/Loader/LoaderComponent';
import useSnackMessages from 'src/hooks/useSnackMessages';
import { usePrevious } from 'src/utils/hooks';
import { useApp } from 'src/contexts/AppContext';
import { SuccessPurchase } from './Steps/SuccessPurchase';

import styles from "./Checkout.module.scss"

const HAS_COLLECTIBLES = true; // TODO enable when will have collectibles (products)

const { post: buyToken } = useFetch('/api/routes/tokens/buy');
const { get: getProducts } = useFetch('/api/routes/products/getProductsByToken');

const Checkout = ({
  cart,
  user,
  updateCart,
  token: activeToken,
  userProducts
}) => {
  const history = useHistory();
  const [token, setToken] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const buyBtnRef = useRef();
  const { isLogged, userData } = useAuth();
  const { loadUserTokens } = useApp();
  const { isMobile } = useSettings();
  const { msgWarning } = useSnackMessages();
  const {
    showThankYouPage,
    setShowThankYouPage,
    chosenPayment,
    setChosenPayment,
    cardLast4,
    setCardLast4,
    deliveryInfo,
    saveDeliveryInfo,
    deliveryOption,
    saveDeliveryOption,
    goToNextStep,
    currentStep
  } = useCheckout();

  const hasDrrt = useMemo(() => cart?.some(item => item.type === 'DRRT'), [cart]);

  const getProductsByToken = useCallback(async (tokenId) => {
    try {
      const products = await getProducts(`?tokenId=${encodeURIComponent(tokenId)}`);
      setProducts(products?.products?.sort((a, b) => (b.type === 'video' ? 1 : -1)));
    } catch (err) {
      setProducts([]);
    }
  }, []);

  useEffect(() => {
    if ((!cart.length && !showThankYouPage) || !isLogged) {
      history.push('/');
    }
  }, [cart, showThankYouPage, isLogged, history]);

  useEffect(() => {
    if (activeToken?._id && HAS_COLLECTIBLES) {
      getProductsByToken(activeToken?._id)
    }
  }, [activeToken?._id]);

  useEffect(() => {
    return () => {
      setShowThankYouPage(false);
    };
  }, []);

  const buy = useCallback(async () => {
    if (buyBtnRef.current) {
      buyBtnRef.current.setAttribute('disabled', 'disabled');
    }
    try {
      setLoading(true);
      await buyToken({
        userInfo: deliveryInfo,
        delivery: deliveryOption,
        tokens: cart,
        selectedProducts: products.map((product) => ({
          ...product,
          productId: product._id
        }))
      });

      setLoading(false);
      setShowThankYouPage(true);
      updateCart([]);
    } catch (error) {
      setLoading(false);
      msgWarning(error.message)
    }
  }, []);

  const prevShowThankYouPage = usePrevious(showThankYouPage);

  useEffect(() => {
    if (!prevShowThankYouPage && showThankYouPage) {
      loadUserTokens();
    }
  }, [showThankYouPage, prevShowThankYouPage]);

  if (loading) {
    return (
      <div className={classNames(styles.page)}>
        <LoaderComponent />
      </div>
    );
  }

  console.log("currentStep:", currentStep)

  return (
    <div
      className={classNames('', {
        [styles.thanks]: showThankYouPage,
        [styles.page]: !showThankYouPage
      })}
    >
      {isMobile ? (
        <>
          <div className={styles.header}>
            <img
              className={styles.logo}
              src={logo}
              alt="bisney-logo"
              onClick={() => {
                history.push('/landing/promo');
              }}
            />
          </div>
          <div className={styles.content}>
            {!showThankYouPage && (
              <>
                <div className={styles.basketWrapper}>
                  <CheckoutBasketMobile
                    products={products}
                    userProducts={userProducts}
                    cart={cart}
                    updateCart={updateCart}
                  />
                </div>
                <StepTitle hasDrrt={hasDrrt} />
                <StepList />
                <div className={styles.powered}>
                  <PoweredByStripe className={styles.image} />
                </div>
                <div className={styles.stepWrapper}>
                  {currentStep === 0 && (
                    <StepMobile2
                      deliveryInfo={{
                        ...user,
                        ...deliveryInfo
                      }}
                      saveData={(data) => saveDeliveryInfo(data)}
                      goNext={() => goToNextStep(1)}
                    />
                  )}
                  {currentStep === 1 && (
                    <StepMobile3
                      editStep={(index) =>
                        currentStep > index && goToNextStep(index)
                      }
                      goNext={(deliveryOption) => {
                        saveDeliveryOption(deliveryOption);
                        goToNextStep(2);
                      }}
                      deliveryInfo={deliveryInfo}
                      deliveryOption={deliveryOption}
                      hasDrrt={hasDrrt}
                    />
                  )}
                  {currentStep === 2 && (
                    <StepMobile4
                      editStep={(index) =>
                        currentStep > index && goToNextStep(index)
                      }
                      goNext={(paymentOption) => {
                        setChosenPayment(paymentOption);
                        goToNextStep(3);
                      }}
                      deliveryInfo={deliveryInfo}
                      deliveryOption={deliveryOption}
                    />
                  )}
                  {currentStep === 3 && (
                    <StepMobile5
                      chosenPayment={chosenPayment}
                      editStep={(index) =>
                        currentStep > index && goToNextStep(index)
                      }
                      deliveryInfo={deliveryInfo}
                      goNext={(tokenId, last4) => {
                        setCardLast4(last4);
                        setToken(tokenId);
                        goToNextStep(4);
                      }}
                      paymentDone={() => setShowThankYouPage(true)}
                      deliveryOption={deliveryOption}
                    />
                  )}
                  {currentStep === 4 && (
                    <StepMobile6
                      editStep={(index) => {
                        if (index === 3) {
                          setChosenPayment('');
                        }
                        currentStep > index && goToNextStep(index);
                      }}
                      buy={buy}
                      buyBtnRef={buyBtnRef}
                      deliveryInfo={deliveryInfo}
                      deliveryOption={deliveryOption}
                      cardLast4={cardLast4}
                    />
                  )}
                </div>
              </>
            )}
            {showThankYouPage && <SuccessPurchase />}
          </div>
        </>
      ) : (
        <>
          <img
            className="cursor-pointer ml-5 mt-3 bisney-logo-checkout"
            onClick={() => {
              history.push('/landing/promo');
            }}
            src={logo}
            alt="bisney-logo"
          />
          <div className="d-flex w-100 mt-3 justify-content-center">
            <StepTitle hasDrrt={hasDrrt} />
          </div>

          <div
            className={classNames('', {
              [styles.thanks]: showThankYouPage,
              [styles.page]: !showThankYouPage
            })}
          >
            {!showThankYouPage && (
              <>
                <StepTitle hasDrrt={hasDrrt} />
                <StepList />
              </>
            )}
            <div className="h-100 col-12 d-flex justify-content-center flex-row align-items-center mt-5">
              {showThankYouPage && <SuccessPurchase />}
              {!showThankYouPage && (<div className="h-100 col-10 d-flex justify-content-center flex-row">
                  <div className="h-100 col-xl-6 col-lg-6 col-sm-12 col-xs-12 d-flex flex-column no-padding border-right-wrapper-checkout">
                    <div className={styles.powered}>
                      <PoweredByStripe className={styles.image} />
                    </div>
                    {currentStep === 0 && (
                      <Step2
                        deliveryInfo={{
                          ...user,
                          ...deliveryInfo
                        }}
                        saveData={(data) => saveDeliveryInfo(data)}
                        goNext={() => goToNextStep(1)}
                      />
                    )}
                    {currentStep === 1 && (
                      <Step3
                        editStep={(index) =>
                          currentStep > index && goToNextStep(index)
                        }
                        goNext={(deliveryOption) => {
                          saveDeliveryOption(deliveryOption);
                          goToNextStep(2);
                        }}
                        deliveryInfo={deliveryInfo}
                        deliveryOption={deliveryOption}
                        hasDrrt={hasDrrt}
                      />
                    )}
                    {currentStep === 2 && (
                      <Step4
                        editStep={(index) =>
                          currentStep > index && goToNextStep(index)
                        }
                        goNext={(paymentOption) => {
                          setChosenPayment(paymentOption);
                          goToNextStep(3);
                        }}
                        deliveryInfo={deliveryInfo}
                        deliveryOption={deliveryOption}
                      />
                    )}
                    {currentStep === 3 && (
                      <Step5
                        chosenPayment={chosenPayment}
                        editStep={(index) =>
                          currentStep > index && goToNextStep(index)
                        }
                        deliveryInfo={deliveryInfo}
                        goNext={(tokenId, last4) => {
                          setCardLast4(last4);
                          setToken(tokenId);
                          goToNextStep(4);
                        }}
                        paymentDone={() => setShowThankYouPage(true)}
                        deliveryOption={deliveryOption}
                      />
                    )}
                    {currentStep === 4 && (
                      <Step6
                        editStep={(index) => {
                          if (index === 3) {
                            setChosenPayment('');
                          }
                          currentStep > index && goToNextStep(index);
                        }}
                        buy={buy}
                        buyBtnRef={buyBtnRef}
                        deliveryInfo={deliveryInfo}
                        deliveryOption={deliveryOption}
                        cardLast4={cardLast4}
                      />
                    )}
                  </div>
                {!showThankYouPage && (
                  <div className="mb-4 col-xl-6 col-lg-6 col-sm-12 col-xs-12 ml-3 h-100 d-flex flex-column no-padding">
                    <CheckoutBasket
                      userProducts={userProducts}
                      token={activeToken}
                      products={products}
                      cart={cart}
                      updateCart={updateCart}
                    />
                  </div>
                )}
              </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect((state) => ({
  cart: state.app.cart || [],
  user: state.auth.user?.data || {},
}), { updateCart })(Checkout);
