import React, { useMemo } from 'react';
import styled from 'src/theme/styled';
import { connect } from 'react-redux';
import MoneySwitch from './MoneySwitch';
import coreTheme from 'src/theme/CoreTheme/coreTheme';
import { ReactComponent as MoneySvg } from 'src/assets/svg/money.svg';
import { ReactComponent as LightningBolt } from 'src/assets/svg/lightning-Icon.svg';
import { convertToCurrency } from 'src/utils/numbers';
import { useHistory, useLocation } from 'react-router-dom';
import { setReturnToPage } from 'src/utils/localstorage';

const MoneyStyled = styled(MoneySvg)`
    height: 24px;
    width: 24px;
`;
const MoneyContainer = styled.div`
    min-width: 100px;
    display: flex;
    align-items: center;
    & > svg {
        height: 23px;
        width: 23px;
    }
`;
const MoneySwitchContainer = styled.div`
    display: flex;
    align-items: center;
`;
const StyledLink = styled.div`
    margin-left: 12px;
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: none;
    }
`;
const LightningBoltIcon = styled(LightningBolt)`
    path {
        fill: ${"var(--primary-text-color)" };
        stroke: ${"var(--primary-text-color)"};
    }
`

const MoneyGpcDiv = styled.div`
    padding: 8px;
    margin-left: 6px;
    text-decoration: none;
    color: ${(({ isMobile }) => "var(--primary-text-color)")};

    &:hover {
        color: ${({ hoverColor }) => hoverColor};
    }
    
    @media (max-width: 750px) {
        font-size: 11px;
        margin-left: 0px;
    }
`;
export function Money({ showUsd, value, forceMode, favoriteColor, isMobile }) {
    const hoverColor = useMemo(() =>
        favoriteColor && favoriteColor !== coreTheme.colors.white  ? favoriteColor : '#C1C1C1',
    [favoriteColor]);
    return (
        <MoneyContainer isMobile={isMobile}>
            {showUsd && <MoneyStyled />}
            {!showUsd && <LightningBoltIcon isMobile={isMobile}/>}
            <MoneyGpcDiv isMobile={isMobile} hoverColor={hoverColor}>
                {showUsd && (!forceMode || forceMode === 'USD') ? (
                    `${convertToCurrency(value)}`
                ) : (
                    <> {Number(value).toLocaleString()} pts</>
                )}
            </MoneyGpcDiv>
        </MoneyContainer>
    );
}

const mapStateToProps = (state) => ({
    showUsd: state.app.showUsd,
    favoriteColor: state.auth.favoriteColor
});

const MoneyConnected = connect(mapStateToProps)(Money);

const MoneyWithSwitch = ({ value, isMobile, isLogged, color = "" }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    function redirectToPoints() {
        history.push("/points")
        setReturnToPage(pathname);
    }

    return (
        <>
            {isLogged && (
                <MoneySwitchContainer>
                    <MoneySwitch color={color} />
                    {isMobile && (
                        <StyledLink onClick={redirectToPoints}>
                            <MoneyConnected isMobile={isMobile} value={value} />
                        </StyledLink>
                    )}
                    {!isMobile && (
                        <StyledLink onClick={redirectToPoints}>
                            <MoneyConnected isMobile={isMobile} value={value} />
                        </StyledLink>
                    )}
                </MoneySwitchContainer>
            )}
        </>
    );
};

export default MoneyWithSwitch;
