import React, { useEffect, useMemo } from 'react';
import numbro from 'numbro';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import styled from 'src/theme/styled';

import { ReactComponent as CartIcon } from 'src/assets/svg/cart.svg';
import { ReactComponent as GoborbSvg } from 'src/assets/svg/goborb.svg';
import { ReactComponent as MinusSvg } from 'src/assets/svg/delete_trashcan.svg';

import EmptyCart from './EmptyCart';

import { updateCart } from 'src/store/actions/app';
import { useSettings } from 'src/contexts/SettingsContext';
import { useAuth } from 'src/contexts/AuthContext';
import { useApp } from 'src/contexts/AppContext';
import CountPicker from './CountPicker';
import useFetch from "../../services/useFetch";

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
`;

const Section = styled.div`
    border-bottom: 0.25px solid #000000;
    padding: 30px 24px;
    width: 100%;
    text-align: center;
`;

const Button = styled.div`
    cursor: pointer;
    background: #e3854c;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    margin-top: 20px;
    padding: 12px 24px;
`;

const CartItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const InfoBlock = styled.div`
    flex: 2;
    text-align: left;
    margin: 0 12px;
`;

const IconStyled = styled(GoborbSvg)`
    height: 50px;
    width: 50px;
    margin: 12px 0;
`;

const RemoveStyled = styled(MinusSvg)`
    height: 24px;
    width: 24px;
    margin: 12px 0;
    cursor: pointer;
`;

interface IProps {
  cart?: any;
  updateCart?: any;
}

const { get: isTokenRelatedToSomeNotValidGame } = useFetch(`/api/routes/tokens/isTokenRelatedToSomeNotValidGame`);

function Checkout({ cart, updateCart }: IProps) {
  const { setShowLogin, setCartOpen } = useSettings();
  const { isLogged } = useAuth();
  const { userTokens } = useApp();
  const history = useHistory();

  const total = useMemo(() => {
    const value = cart.reduce((sum: number, item: any) => sum + (item.price * (item.count || 1)), 0);
    return `${numbro(value).formatCurrency({
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 2
    })} (USD)`;
  }, [cart]);

  useEffect(() => {
    const hasBoughtTokens = cart.some((c: any) => userTokens.find((bt: any) => bt._id === c._id));
    if (hasBoughtTokens) {
      updateCart([...cart.filter((c: any) => !userTokens.find((bt: any) => bt._id === c._id))])
    }
  }, [userTokens]);


  useEffect(()=> {
    async function check() {
      for (const token of cart) {
        const res = await isTokenRelatedToSomeNotValidGame(token?._id);
        if (res.clear) {
          updateCart([...cart.filter(car => car._id !== token._id)])
        }
      }
    }
    void check();
  }, [])

  function checkImageUrl(url: string) {
    if (url.includes('/api/routes/media/file/')){
      return url;
    } else {
      return `/api/routes/media/file/${url}/image`;
    }
  }

  return !cart.length ? (
    <Wrapper style={{ justifyContent: 'center' }}>
      <EmptyCart />
    </Wrapper>
  ) : (
    <Wrapper>
      <Section>
        <div>
          <CartIcon /> TOTAL - {total}
        </div>
        <Button onClick={() => {
          setCartOpen(false);
          if (isLogged) {
            history.push('/checkout');
          } else {
            setShowLogin(true);
          }
        }}>
          Checkout
        </Button>
      </Section>
      {cart.map((item: any) => (
        <Section key={item._id}>
          <CartItem>
            {item?.icon ? (
              <img
                src={ typeof item.icon === 'string' ? item.icon : checkImageUrl(item.icon?.app?.url || item.icon?.app)}
                height={50}
                width={50}
                alt="token"
              />
            ) : (
              <IconStyled />
            )}
            <InfoBlock>
              <div>
                <b>Name</b>: {item.name}
              </div>
              <div>
                <b>Price</b>:{' '}
                {`${numbro(item.price).formatCurrency({
                  spaceSeparated: true,
                  thousandSeparated: true,
                  mantissa: 2
                })} (USD)`}
              </div>
              {item.type !== "DRRT" ? (
                <div>
                  <b>Count</b>:{' '}
                    <CountPicker
                      item={item}
                      updateCount={(count: number) => {
                        updateCart(cart.map((c: any) => (c._id === item._id) ? ({
                          ...c,
                          count
                        }) : c))
                      }}
                    />
                </div>
              ) : null}
            </InfoBlock>
            <RemoveStyled onClick={() => updateCart(cart.filter((c: any) => c._id !== item._id))} />
          </CartItem>
        </Section>
      ))}
    </Wrapper>
  );
}

export default connect((state: any) => ({
  cart: state.app.cart || []
}), {
  updateCart
})(Checkout);
