import React from 'react';

import {
    ObjectsBox,
    ImageContainer,
    ObjectImg,
    ObjectImgText
  } from '../ChatMessage.styles';

  interface ObjectBoxFieldProps {
    objects: any;
    isDisabled: boolean;
    handleOnClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, object: any) => void;
}

  const ObjectBoxField = (props: ObjectBoxFieldProps) => {
    const throttle = (() => {
        let shouldFire = true;
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, obj: any) => {
          if (shouldFire) {
            props.handleOnClick(event, obj);
            shouldFire = false;
            setTimeout(() => {
              shouldFire = true;
            }, 1000);
          }
        };
    })();


    return (
        <ObjectsBox>
            {props.objects.map((object: any, index: any) => (
                <div key={index} className="d-flex flex-row mb-1">
                    <ImageContainer
                        key={object.name}
                        className="w-auto d-flex flex-column align-items-center"
                        disabled={props.isDisabled}
                        onClick={(e: any) => throttle(e, object)}
                    >
                        <ObjectImg src={object.symbol} />
                        <ObjectImgText key={`object-img-text-${index}`} >
                            {object.name}
                        </ObjectImgText>
                    </ImageContainer>
                </div>
            ))}
        </ObjectsBox>
    )
};

export default ObjectBoxField;
