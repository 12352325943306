export const defaultKey = {
    sections: []
};
const imaginecouncilAll = {
    sections: [
        {
            id: 'stories',
            header: 'Where Reality Meets Fantasy!',
            subheader: null,
            description:
                'Step into a world where imagination knows no bounds. Explore an extraordinary entertainment experience where cartoons, puppets, robots, superheroes, and humans unite, offering a realm of endless possibilities through fun and empowering storytelling!',
            index: 0,
            link: null
        },
        {
            id: 'stories',
            header: 'ROADMAP',
            subheader: "What's in the Works for 2024",
            description: [
                {
                    subtitle: 'Stories',
                    text: 'Bringing on a phenomenal actor for STORY 1.'
                },
                {
                    subtitle: 'Technical',
                    text: 'Integrating voting mechanics for co-creating STORY ONE in promo feed.'
                },
                {
                    subtitle: 'Technical',
                    text: 'Upcoming iOS and Android App.'
                },
                {
                    subtitle: 'Marketing',
                    text: 'Social media campaign with inspiring clips for Story 0, for launching presale collectibles.'
                },
                {
                    subtitle: 'Society Mechanics',
                    text: 'Improving and simplifying the "SOCIETY MECHANICS" page for better understanding.'
                },
                {
                    subtitle: 'Financial',
                    text: 'Introducing our coin, GOBORB, the currency powering our ecosystem.'
                },
                {
                    subtitle: 'Characters',
                    text: 'Creating a "TAKE BACK IMAGINATION" campaign to start the revolution.'
                }
            ],
            footer: 'Stay tuned for updates as we take journey to produced the GLOBE’S IMAGINATION ! 💥🌐',
            index: 1,
            link: null
        },
        {
            id: 'collectibles',
            header: 'Immerse Yourself in Captivating Stories',
            subheader: null,
            description:
                'Dive into a captivating anthology of stories crafted by our vibrant society with the help of our production of characters. From hilarious comedy to meaningful dramas to cultural romances, our wide range of genres will captivate and entertain you in unique and unexpected ways.',
            index: 2,
            link: {
                text: 'Explore',
                goto: 'landing/promo'
            }
        },
        {
            id: 'goborb',
            header: 'Unlock a World of Wonders with Collectibles',
            description:
                'Experience the magic of Imagine Council through our collectibles, granting you access to digital and physical treasures from our stories. Collect and immerse yourself in props, wardrobe, music, set designs, and beloved characters from our stories that bring the imagination to life.',
            index: 3,
            link: {
                text: 'Explore',
                goto: 'landing/promo'
            }
        },
        {
            id: 'imagine',
            header: 'Join Imagine Council Today!',
            description:
                "Join Imagine Council now to unlock the power of your imagination and connect with like-minded individuals. Contribute to the production of the globe's imagination, embracing a journey of storytelling and wonder that will live forever.",
            index: 4,
            link: {
                text: 'Join',
                openModal: 'signup'
            }
        }
    ]
};

export const imaginecouncil = {
    unauthorized: {
        mobile: [
            imaginecouncilAll.sections[0],
            imaginecouncilAll.sections[2],
            imaginecouncilAll.sections[3],
            imaginecouncilAll.sections[4]
        ],
        desktop: [
            imaginecouncilAll.sections[0],
            { ...imaginecouncilAll.sections[2], link: null },
            { ...imaginecouncilAll.sections[3], link: null },
            imaginecouncilAll.sections[4]
        ]
    },
    authorized: {
        mobile: [imaginecouncilAll.sections[1]],
        desktop: [imaginecouncilAll.sections[1]]
    }
};

export const ftc = {
    header: 'DIGITAL ROYALTY RIGHTS TOKENS (DRRT) gives you access to create ideas for stories and stake royalties towards Chapter Tokens (CT) through competitive game play',
    sub_header:
        'DRRTs come with digital and physical collectibles with every purchase that you can redeem right away or hold to the DRRT.',
    sections: [
        {
            subheader:
                'The best ideas get the most royalties and the least favorite ideas get to least amount of royalties based on an algorithmic voting system where all in society are able to vote populating percentages — creating fairness.',
            description: [],
            index: 1
        },
        {
            header: 'HOW IT WORKS?',
            description: [],
            index: 2
        },
        {
            subheader: `Step 1. We create and mint a limited amount  Digital Royalty Right Tokens (DRRT) for purchase on the blockchain.`,
            description: [],
            index: 3
        },
        {
            subheader: `Step 2. DRRT holders access plays from brand portals to come up with ideas for Chapter Tokens (CT).`,
            description: [],
            index: 4
        },
        {
            subheader: `Step 3. Ideas are voted amongst the society staking royalties % for all DRRT holders.`,
            description: [],
            index: 5
        },
        {
            subheader: `Step 4. Once plays and votes are over royalties of DRRT holders turn into minted IT of story’s chapter.`,
            description: [],
            index: 6
        },
        {
            subheader: `Step 5. Once CTs are purchased pays out royalties to all DRRT holders for initial and secondary sales.`,
            description: [],
            index: 7
        }
    ]
};
export const aftayesta = {
    header: 'AFTAYESTA - COLLECTION OF PAST FOR FUTURE',
    sub_header:
        'AftaYesta is a research and product publisher. We find categories and topics we are interested in from the past, present or future and learn as much about it as possible and give the knowledge back to the public and create unique products around our research.'
};
export const airnidas = {
    header: 'AIR NIDAS - ALL THINGS TRANSPO',
    sub_header:
        'AirNidas is a custom design shop for physical and digital transpo goods from sneakers to racing vehicles. We pride ourselves on using the best design methods and technologies to the best materials to achieve awesomeness.'
};
export const supfooku = {
    header: 'SUPFOOKU - EXPERIENTIAL THEATRE',
    sub_header:
        'Supfooku is a contemporary art gallery with a central focus on experiential art in mediums of music, performance, and visual.'
};
export const culvar = {
    header: 'CULVAR - COMICBOOK-NEWSPAPER-MAGAZINE',
    sub_header:
        " CULVAR (Characters United Living Various Augmented Realities) is a physical and digital comic book and magazine series based on satire in the world of culturally relevant issues and more that occurs in the characters' realities and beyond."
};
export const goniaface = {
    header: 'GONIAFACE - LIFESTYLE & ACTIVITIES',
    sub_header:
        'Goniaface is a lifestyle and recreations brand connecting to youth culture through physical and digital clothing, accessories, and activities such as  skateboarding, fishing, basketball, gaming and more.'
};
export const goodmunchie = {
    header: 'GOODMUNCHIE - GOODS AND MUNCHIES GIVING VIBES IN ARTSY PACKAGING',
    sub_header:
        'Good Munchie is a brand portal catered to creating goods and munchies that look and taste good. A convenience store of the future. GET YOUR PACKS!'
};
export const investvisual = {
    header: 'INVEST VISUAL - ALTERNATIVE ASSETS',
    sub_header:
        'Invest Visual is an alternative investment house looking to create and further a new investment class in the modern world with accredited investors.'
};
export const saybrandon = {
    header: 'SAYBRANDON - POP CULTURE LIBRARY',
    sub_header:
        'SayBrandon is an AI inspired ever-growing library based on pop culture of all times with a focus-first of all parts of the Imagine Council Society.'
};
export const divvyland = {
    header: 'DIVVYLAND - A CONSORTIUM OF PURVEYORS ORCHESTRATING THE PRODUCTION OF PHYSICAL AND DIGITAL GOODS',
    sub_header: `Divvyland is an art studio, but not just any art studio. We work with the globe to connect vendors and services to build design specs from films to rag cloth art prints. “Let's stick up the world and split it 50/50, uh-huh”`
};
export const playneverstops = {
    header: 'PLAYNEVERSTOPS - A CREATIVE AGENCY FOR IMAGINATION CULTURE',
    sub_header:
        'PlayNeverStops is a creative agency that helps portals and tokens tell the story the best way possible through imaginative plays.'
};
export const labelasset = {
    header: 'LABELASSET - DIGITAL AND PHYSICAL ASSET STORAGE AND MARKETPLACE',
    sub_header:
        'Label Asset is digital and physical asset storage and marketplace taking markets past and bringing them in the now. Dedicated to be your go to source to barter, swap, tokenize, store, hold, sale, bid, and auction your assets.'
};
export const goborb = {
    header: 'GOBORB - GLOBALLY OPERATING BRANDS ORBITAL ROCKET BOOST',
    sub_header:
        'GOBORB is the currency of the Imagine Council Society and beyond. GOBORB acts as a tangible asset where the commodity and the utility is imagination — harnessed through the power of web3 technologies to track its value.'
};
export const ledger = {
    header: 'NODNARB - ART EXPRESSIONS OF DATA',
    sub_header:
        'Nardnarb is real time data of pathways of transactional movement inside systems. We aim to give a more fun way to look at data to where it can be just more than data, but real time real world art movement.'
};
export const dit = {
    header: 'DATA IDENTITY TOKEN - OWN YOUR DATA',
    sub_header:
        'A data identity token will allow the user to own their data and store it in their wallet for use in supported projects from your favorite color to what you preferred language. With the assistance of the Ethereum blockchain, we will create data points that the user owns and can get paid for if someone wants to access or use them by using stable tokens.'
};
export const points = {
    header: 'POINTS - YOUR VALUE REWARDED',
    sub_header:
        'Points are a collection of money earned by the Imagine Council and based on your % value you are paid in real time and able to cash out or soon convert GOBORB.'
};
