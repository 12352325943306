import React from 'react';
import styled from 'src/theme/styled';
import { useFormik } from 'formik';

import { ReactComponent as IcIcon } from 'src/assets/svg/imagine-council-original.svg';
import { ReactComponent as GoborbIcon } from 'src/assets/svg/goborb.svg';
import { ReactComponent as GobAndOrbIcon } from 'src/assets/svg/gob_orb.svg';
import { ReactComponent as EarlyAccessIcon } from 'src/assets/svg/early_access.svg';
import { ReactComponent as EarlyFlameIcon } from 'src/assets/svg/early_flame.svg';
import { ReactComponent as EarlySubmitIcon } from 'src/assets/svg/early_submit.svg';
import { setWithExpiry } from 'src/utils/localstorage';
import { useSettings } from 'src/contexts/SettingsContext';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FF0000;
`;

const Promo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;

  & > * {
    margin: 0 10px;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 20px;
  max-width: 280px;
  text-align: center;
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  max-width: 280px;
  text-align: center;
`;

const Info = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  max-width: 420px;
  text-align: center;
`;

const Form = styled.form`
  max-width: 440px;
  margin: 50px auto;
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  background: #EFFF00;
  border-radius: 10px;
  padding: 18px 30px;
  margin-right: 30px;

  input {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
    background: none !important;
    border: none !important;
    outline: none !important;
  }
`;

const Hr = styled.hr`
  border: 0.25px solid #000000;
  width: 154px;
  margin-bottom: 45px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
`;

export default function EarlyAccessLogin({
  setUserAuthorized,
}: {
  setUserAuthorized: (value: boolean) => void,
}) {
  const { isMobile } = useSettings();
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: async (values) => {
      if (!values.password || values.password.length === 0) return
      formik.resetForm();
      const request = await fetch('/api/routes/users/getEarlyAccess', {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' }
      });
      const response = await request.json();
      if (response.message === 'Success') {
        setWithExpiry('earlyAccess', true, 24 * 60 * 60 * 1000); // 1 day
        setUserAuthorized(true);
      }
    }
  });

  return (
    <Wrapper>
      <Header>
        <IcIcon
          style={{
            height: 33,
            width: 33,
            flex: 1,
          }}
        />
        {!isMobile && (
          <Promo>
            <div>PUBLIC SYSTEM LAUNCH</div>
            <EarlyAccessIcon height={44} />
            <div>WHEN <span style={{ color: '#000000' }}>BISNEY HELIX</span> PUSHES THE BUTTON</div>
          </Promo>
        )}
        {isMobile && (
          <GobAndOrbIcon height={33} />
        )}
        <div
          style={{
            flex: 1,
            textAlign: 'center',
            display: 'flex',
          }}
        >

          <GoborbIcon
            style={{
              height: 33,
              width: 33,
            }}
          />
          {!isMobile && (
            <GobAndOrbIcon
              style={{
                height: 24,
                marginLeft: 24,
              }}
            />
          )}
        </div>
      </Header>
      <Content>
        <Title>
          Welcome to <b>Imagine Council</b>
        </Title>
        <Title>
          MANUFACTURING THE GLOBE’S IMAGINATION
        </Title>
        <Subtitle>
          A new gamified economic system based on imagination culture -- built on blockchain.
        </Subtitle>
        <Form onSubmit={formik.handleSubmit}>
          <InputWrapper>
            <EarlyFlameIcon style={{ marginRight: 30 }} />
            <input
              type='password'
              name='password'
              placeholder='ENTER PASSWORD'
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </InputWrapper>
          <EarlySubmitIcon
            style={{
              cursor: 'pointer',
            }}
            onClick={formik.submitForm}
          />
        </Form>
        <Hr />
        <Info>
          If you do not have access to system and you should have access -- please email hub@imaginecouncil.com for the password.
        </Info>
      </Content>
      <Footer>
        © {(new Date()).getFullYear()} Imagine Council Society
      </Footer>
    </Wrapper>
  )
}
