import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuth } from './authTypes';
import coreTheme from 'src/theme/CoreTheme/coreTheme';

const savedFavoriteColor = localStorage.getItem('ic-favorite-color');
const defaultColor = 'blue';

const initialState: IAuth = {
    balance: 0,
    selectedColor: savedFavoriteColor || defaultColor,
    favoriteColor: savedFavoriteColor
        ? coreTheme.colors[savedFavoriteColor]
        : coreTheme.colors[defaultColor],
    globalStats: {
        coins_paid: '0',
        games_in_play: '...loading',
        users_engaged: '...loading',
        hub_in_lead: '...loading',
        most_popular_color: '...loading'
    },
    user: {
        email: null,
        isLogged: false,
        data: {
            color: savedFavoriteColor || defaultColor,
            onboarding: undefined,
            gpcAccount: false,
            verified: null,
            onboardingIndex: null
        }
    }
};

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        loginUser(state, action: PayloadAction<any>) {
            state.selectedColor = action.payload.color.toLowerCase();
            state.favoriteColor =
                coreTheme.colors[action.payload.color.toLowerCase()];
            state.user = {
                email: action.payload.email,
                isLogged: true,
                data: { ...action.payload }
            };
        },
        saveUser(state, action: PayloadAction<any>) {
            state.selectedColor = action.payload.userData.color.toLowerCase();
            state.favoriteColor =
                coreTheme.colors[action.payload.userData.color.toLowerCase()];
            state.user.data = action.payload.userData;
        },
        saveUserGpcAccount(state, action: PayloadAction<any>) {
            state.user.data.gpcAccount = true;
        },
        userVerified(state, action: PayloadAction<any>) {
            state.user.data.verified = action.payload;
        },
        userOnboarded(state, action: PayloadAction<any>) {
            state.user.data.onboarding = action.payload;
        },
        saveUserOnboardingIndex(state, action: PayloadAction<any>) {
            state.user.data.onboardingIndex = action.payload;
        },
        logOut(state, action: PayloadAction<any>) {
            localStorage.removeItem('user');
            localStorage.removeItem('showUsd');
            localStorage.removeItem('user-message-counter');
            localStorage.removeItem('games-to-submit');
            localStorage.removeItem('games-to-vote');
            localStorage.removeItem('user-message-counter');
            localStorage.removeItem('ic-favorite-color');
            localStorage.removeItem('cart');

            return initialState;
        }
    }
});

export const {
    loginUser,
    saveUser,
    saveUserGpcAccount,
    userVerified,
    userOnboarded,
    saveUserOnboardingIndex,
    logOut
} = authSlice.actions;
export default authSlice.reducer;
