import React from 'react';
import styled from 'src/theme/styled';
import ICModal from 'src/components/common/Modal';
import { ReactComponent as Chat } from 'src/assets/svg/profile.svg';
import ChatContainer from 'src/pages/Chat/ChatContainer';

const Wrapper = styled.div`
    display: flex;
    padding-top: 50px;
    margin: 16px 10px;
    flex-direction: column;
`;
const StyledICModal = styled(ICModal)`
    height: calc(100vh - 115px);
`;

interface IProps {
    closeModal: () => void;
}

function ChatModal({ closeModal }: IProps) {
    return (
        <StyledICModal
            topClassName="ic-mobile-chat"
            closeModalInner={true}
            closeModal={closeModal}
            CloseSvg={Chat}
        >
            <Wrapper>
                <ChatContainer />
            </Wrapper>
        </StyledICModal>
    );
}

export default ChatModal;
