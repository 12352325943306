import React from 'react';
// import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'src/theme/styled';
import { ReactComponent as Cart } from 'src/assets/svg/cart.svg';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 16px;
    max-width: 80%;
`;

const LogoWrapper = styled.div`
    height: 56px;
    width: 56px;

    & > svg {
        height: 100%;
        width: 100%;
    }
`;

const Title = styled.div`
    margin: 30px 0;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
`;

// const Text = styled.div`
//     text-align: center;
//     margin-bottom: 8px;
// `;

// const LinkStyled = styled(Link)`
//     color: #0000ff;
//     font-weight: 500;
//     font-size: 13px;
//     line-height: 16px;
//     margin-bottom: 40px;
// `;

type PropsFromRedux = ConnectedProps<typeof connector>;

const EmptyCart = (props: PropsFromRedux) => {
    return (
        <Wrapper>
            <LogoWrapper>
                <Cart />
            </LogoWrapper>
            <Title>Bag is empty</Title>
            {/* <Text>Need more token expierences for portals</Text>
            <LinkStyled to="/">Shop Label Asset</LinkStyled> */}
            {/* <Text>Need GOBORB coins to do someting in the sytem</Text>
            <LinkStyled to="/goborb" >
                Get GOBORB
            </LinkStyled> */}
        </Wrapper>
    );
};

const mapDispatch = {};

const connector = connect(null, mapDispatch);

export default connector(EmptyCart);
