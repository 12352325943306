import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { saveUserGpcAccount } from 'src/store/actions/auth';
import DeliveryForm from "../DeliveryForm";

import '../../checkout.scss'

const StepMobile2 = ({
  cart,
  deliveryInfo,
  goNext,
  saveData,
  user
}) => {
  return (
    <div className="col-12 d-flex flex-column justify-content-center">
      <div className="w-100 h-100 d-flex flex-column no-padding">
        <DeliveryForm
          cart={cart}
          user={user}
          saveUserGpcAccount={saveUserGpcAccount}
          deliveryInfo={deliveryInfo}
          goNext={goNext}
          saveData={saveData}
          isMobile
        />
      </div>
    </div>
  );
};

StepMobile2.propTypes = {
  goNext: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  cart: state.app.cart || [],
  user: state.auth.user
});

export default connect(
  mapStateToProps, {
  saveUserGpcAccount,
})(StepMobile2);
