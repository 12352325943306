import React from 'react';
import styled from 'src/theme/styled';
import ChatContainer from 'src/pages/Chat/ChatContainer';
import CloseButton from '../CloseButton';
import { ReactComponent as Chat } from 'src/assets/svg/profile.svg';
import { ReactComponent as LogoOriginal } from 'src/assets/svg/imagine-council-original.svg';
import { ReactComponent as Logout } from 'src/assets/svg/logout.svg';
import { useSettings } from 'src/contexts/SettingsContext';
import { useAuth } from 'src/contexts/AuthContext';

const SideChatDiv = styled.div`
    position: fixed;
    display: block;
    top: 0;
    right: 0px;
    height: 100%;
    z-index: 11112;
    transition: right 3s;
    width: 100%;
`;

const Chatting = styled.div<IProps>`
    ${(props) => props.isMobile ? '' : 'width: 528px;'};
    ${(props) => props.isMobile ? 'left: 73px;' : ''};
    height: 100%;
    overflow-y: scroll;
    // background: #faf0f0;
    position: fixed;
    right: 0;
    z-index: 11115;
`;
const OnboardingView = styled.div`
    background: #EC6144;
    position: fixed;
    left: 0;
    height: 100%;
    width: calc(100% - 528px);
    padding-left: 73px;
`;
const OnboardingChatDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 40px;
`;

const OnboardingText = styled.div`
    font-weight: 900;
    font-size: 37px;
    line-height: 49px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
`;

const Sidebar = styled.div<IProps>`
    position: absolute;
    left: 0;
    z-index: 11111;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    width: 73px;
    border-right: 0.25px solid #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
`;

interface IProps {
    isMobile?: boolean;
    isOpen?: boolean;
    isLogged?: boolean;
    userOnboarded?: boolean;
    closeChatLayout?: () => void;
}

const ChatDiv = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const { logoutUser } = useAuth();
    const { isMobile } = useSettings();
    return (
        <SideChatDiv ref={ref}>
            <Chatting isMobile={isMobile}>
                {props.userOnboarded && (
                    <CloseButton
                        CloseSvg={Chat}
                        close={props?.closeChatLayout}
                    />
                )}
                <ChatContainer />
            </Chatting>
            {!props.userOnboarded && (
                <OnboardingView>
                    <Sidebar>
                        <LogoOriginal
                            className="user-color"
                            style={{
                                height: 32,
                            }}
                        />
                        <Logout
                            onClick={logoutUser}
                            style={{
                                height: 32,
                                cursor: 'pointer',
                                rotate: '180deg',
                                fill: '#fff'
                            }}
                        />
                    </Sidebar>
                    {!isMobile && (
                        <OnboardingChatDiv>
                            <OnboardingText>
                                Character Kawaii is chatting to onboard you in the Imagine Council Society right now.
                            </OnboardingText>
                            <OnboardingText>
                                Chat in progress...
                            </OnboardingText>
                        </OnboardingChatDiv>
                    )}
                </OnboardingView>
            )}
        </SideChatDiv>
    );
});

export default ChatDiv;
