const PATHS = {
    HOME: '/',
    LEARN_MORE: '/learn-more',
    LOGIN: '/login',
    SIGNUP: '/signup',
    POINTS: '/points/:page?',
    GOBORB: '/goborb/:page?',
    CHAT: '/chat',
    FORGOT_PASSWORD: '/forgot-password/:id',
    PROFILE: '/profile',
    ACCOUNT: '/account',
    LEDGER: '/ledger/:page?',
    LEADERBOARD: '/leaderboard',
    RESET_PASSWORD: '/reset-password',
    HELP: '/help',
    PRIVACY: '/privacy',
    TERMS: '/terms',
    FAQ: '/faq',
    CONTACT: '/contact',
    CHECKOUT: '/checkout',
    // TOKENS: '/tokens',
    // TOKEN: '/token/:token',
    SOCIETY_MECHANICS: '/societymechanics/:page?',
    STORY: '/story/:storyName',
    DRRT: '/:story/drrt/:token?',
    GAMES: '/:story/drrt/:token?/games',
    IDEAS: '/:story/drrt/:token?/games/ideas',
    VOTING: '/:story/drrt/:token?/games/voting',
    RANKS: '/:story/drrt/:token?/games/ranks',
    GAME_DETAILS: '/:story/drrt/:token?/games/:id',
    PORTAL: '/:portal(landing|dit)/:drrt(data|promo|society|feed)?/:token?',
    NOT_FOUND: '/404',
    WILDCARD: '*'
};

export default PATHS;
