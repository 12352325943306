import type { DynamicPaths } from "./types"

export function portalsToPathParams(portals: IPortalFull[]) {
    return portals.map(portal => (portal.name.toLowerCase() as any).replaceAll(" ", "")).join("|")
}

export function populatePathWithPortals([key, path], portals: IPortalFull[]) {
    const populatedPath = path.replace("availablePortals", portalsToPathParams(portals))
    return [key, populatedPath]
}

export function getPortalPaths(paths: DynamicPaths, portals: IPortalFull[]) {
    const portalPaths = {}

    Object.entries(paths).forEach(([key, path]) => {
        const entry = populatePathWithPortals([key, path], portals) 
        portalPaths[key] = entry[1] 
    })

    return portalPaths as DynamicPaths
}