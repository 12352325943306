import React from 'react';

import {
    GridWrapperDiv,
    RecievedMessageBox,
    SubmitButton,
    CenteredSpan
  } from '../ChatMessage.styles';

  interface EndMessageProps {
    message: string;
    systemMessage: string;
    color: string;
    handleOnClick: () => void;
    chatGameType: string;
    showAllMessages: boolean;
  }

const MultipleSystemMessages = (props: EndMessageProps) => {
    return (
        <GridWrapperDiv>
            <RecievedMessageBox
                messageLinkSeen={true}
                chatGameType={props.chatGameType}
            >
                {props.message}
            </RecievedMessageBox>
            {props.showAllMessages && 
            <>
                <RecievedMessageBox
                    messageLinkSeen={true}
                    chatGameType={props.chatGameType}
                >
                    {props.systemMessage}
                </RecievedMessageBox>
                <SubmitButton
                    favoriteColor={props.color}
                    onClick={props.handleOnClick}
                >
                    <CenteredSpan>Send again</CenteredSpan>
                </SubmitButton>
            </>
            }
        </GridWrapperDiv>
      )
};

export default MultipleSystemMessages;
