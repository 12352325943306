import React from 'react';
import numbro from 'numbro';
import styled from 'src/theme/styled';
import { PlainText } from './shared';
import { TOKEN_TYPE } from 'src/constants/tokenTypes';
import EthAddress from 'src/components/common/EthAddress';

interface IProps {
    isMobile: boolean;
    token: any;
    goBack?: () => void;
}

const Wrapper = styled.div<{ isMobile: boolean }>`
    // background: #faf0f0;
    height: 100%;
`;

const TokensWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;
const TokensDetails = styled.div`
    display: flex;
    flex-direction: row;
`;

export default function TokenInfo({ token, isMobile, goBack }: IProps) {
    const isDrrt = token?.tokenType === TOKEN_TYPE[0];

    return (
        <Wrapper isMobile={isMobile}>
            <TokensWrapper>
                <TokensDetails>
                    <PlainText>Token Name</PlainText>
                    <PlainText>{token.name}</PlainText>
                </TokensDetails>
                {isDrrt && (
                    <TokensDetails>
                        <PlainText>Royalty</PlainText>
                        {/* haven't it yet */}
                        <PlainText>20%</PlainText>
                    </TokensDetails>
                )}
                <TokensDetails>
                    <PlainText>Token Type</PlainText>
                    <PlainText>{TOKEN_TYPE[token.tokenType]}</PlainText>
                </TokensDetails>
                {isDrrt && (
                    <TokensDetails>
                        <PlainText>Points</PlainText>
                        {/* haven't it yet */}
                        <PlainText>111</PlainText>
                    </TokensDetails>
                )}
                <TokensDetails>
                    <PlainText>Floor Value</PlainText>
                    <PlainText>
                        ${numbro(token.price || 0).format({
                            spaceSeparated: false,
                            average: true,
                            mantissa: 2
                        })}
                    </PlainText>
                </TokensDetails>
                <TokensDetails>
                    <PlainText>Secondary Value</PlainText>
                    {/* haven't it yet */}
                    <PlainText>---</PlainText>
                </TokensDetails>
                <TokensDetails>
                    <PlainText>Collectibles Binded</PlainText>
                    <PlainText>{token.assets || 0}</PlainText>
                </TokensDetails>
                <TokensDetails>
                    <PlainText>Release Date</PlainText>
                    {/* we have it in db but not in smart contract */}
                    <PlainText>---</PlainText>
                </TokensDetails>
                {token.address && (
                    <TokensDetails>
                        <PlainText>Content Address</PlainText>
                        <EthAddress address={token.address} short />
                    </TokensDetails>
                )}
                <TokensDetails>
                    <PlainText>Token ID</PlainText>
                    <PlainText>{token.tokenId}</PlainText>
                </TokensDetails>
                <TokensDetails>
                    <PlainText>Token Standard</PlainText>
                    <PlainText>ERC-721</PlainText>
                </TokensDetails>
                <TokensDetails>
                    <PlainText>Blockchain</PlainText>
                    <PlainText>Ethereum</PlainText>
                </TokensDetails>
            </TokensWrapper>
        </Wrapper>
    );
}
