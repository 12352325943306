import React from 'react'
import { useHistory } from 'react-router'
import CongratsImage from "src/assets/png/congrats_purchase.png"
import Button from 'src/components/common/Button'

import styles from "./SuccessPurchase.module.scss"

export function SuccessPurchase() {
    const history = useHistory();
  const tokenPage = localStorage.getItem('tokenToBuyPage');

    function redirectToToken() {
        if (tokenPage) {
            window.location.href = tokenPage;
        } else {
            history.goBack();
        }
    }

    return (
        <div className={styles.page}>
            <img className={styles.image} src={CongratsImage} alt="congrats" />
            <div className={styles.view}>
                <div className={styles.text}>VIEW YOUR TOKEN(S) SEE UTILITIES</div>
                <Button className={styles.redirect} handleClick={redirectToToken}>
                    GO
                </Button>
            </div>
        </div>
    )
}
