import React, { useState, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Router from './routing';
import CoreThemeProvider from 'src/theme/CoreTheme/CoreThemeProvider';
import { SettingsProvider } from './contexts/SettingsContext';
import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';
import EarlyAccessLogin from 'src/pages/EarlyAccessLogin';
import { getWithExpiry } from './utils/localstorage';
import { SnackbarProvider } from 'notistack';
import 'src/theme/CoreTheme/style.css';
import './index.scss';
import { CheckoutProvider } from './contexts/CheckoutContext';
import { FeedProvider } from './contexts/FeedContext';

const RESTRICTED_ACCESS = process.env.REACT_APP_ENV === 'staging';

function App({ store }) {
  const notistackRef = useRef();
  const hasAuthSession = getWithExpiry('earlyAccess');
  const [userAuthorized, setUserAuthorized] = useState(RESTRICTED_ACCESS ? !!hasAuthSession : true);

  return RESTRICTED_ACCESS && !userAuthorized ? (
    <EarlyAccessLogin setUserAuthorized={setUserAuthorized} />
  ) : (
    <Provider store={store}>
      <CoreThemeProvider>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={1}
          classes={{
            containerRoot: 'custom-snackbar-root',
          }}
          action={(key) => (
            <div
              onClick={() => notistackRef.current.closeSnackbar(key)}
              style={{ padding: 8, cursor: 'pointer' }}
            >
              ✖
            </div>
          )}
        >
          <BrowserRouter>
            <SettingsProvider>
              <AuthProvider>
                <AppProvider>
                  <CheckoutProvider>
                    <FeedProvider>
                      <Router />
                    </FeedProvider>
                  </CheckoutProvider>
                </AppProvider>
              </AuthProvider>
            </SettingsProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </CoreThemeProvider>
    </Provider>
  );
}

export default App;
