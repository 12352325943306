import styled from '@emotion/styled';
import { space, color, typography } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

import { TextProps } from './Text.interface';

export const Text = styled('span', { shouldForwardProp })<TextProps>`
  ${color}
  ${space}
  ${typography}
`;

Text.defaultProps = {
    lineHeight: 16,
    fontSize: 12,
    color: 'black'
};
