import React from 'react';
import useFetch from 'src/services/useFetch';
import { DYNAMIC_PATHS, getDynamicRoutes } from './dynamicPaths';
import { getPortalPaths } from './utils';

import type { DynamicPaths } from './types';

const { get: getPortals } = useFetch('/api/routes/portals/get');

export function useGetDynamicRoutes() {
    const [portalPaths, setPortalPaths] = React.useState<DynamicPaths | null>(null)

    const routes = React.useMemo(() => portalPaths ? getDynamicRoutes(portalPaths) : [], [portalPaths])

    const fetchPortals = React.useCallback(async function () {
        try {
            const { data: portals }: { data: IPortalFull[] } = await getPortals()
            if (portals) {
                const paths = getPortalPaths(DYNAMIC_PATHS as DynamicPaths, portals);
                setPortalPaths(paths)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    React.useEffect(() => {
        fetchPortals()
    }, [])

    return { dynamicRoutes: routes }
}
