import React from 'react';
import styled from 'src/theme/styled';
import { ReactComponent as Close } from 'src/assets/svg/exit-white.svg';

const CloseModalInner = styled(Close)`
    position: absolute;
    top: 14px;
    right: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    z-index: 2;
`;

const CloseDIv = styled.div<ICModalProps>`
    position: fixed;
    height: 55px;
    z-index: 12345;
    width: ${(props) => (props.isMobile ? '100%;' : '528px;')};
    background: white;
`;

const CloseDivInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    height: 100%;
`;

export interface ICModalProps {
    close?: () => void;
    CloseSvg?: any;
    isMobile?: boolean;
}

const CloseButton = ({ close, CloseSvg, isMobile }: ICModalProps) => {
    return (
        <CloseDIv isMobile={isMobile}>
            <CloseDivInner>
                <CloseSvg />
                <CloseModalInner onClick={close} />
            </CloseDivInner>
        </CloseDIv>
    );
};

export default CloseButton;
