import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import LoaderComponent from 'src/components/common/Loader/LoaderComponent';
import useSnackMessages from 'src/hooks/useSnackMessages';
import PATHS from 'src/routing/pathNames';
import useFetch from 'src/services/useFetch';
import { loginUser as logIn, logOut } from 'src/store/actions/auth';

// TODO use instead of redux store
const AuthContext = createContext<any>({
    isLogged: false,
    logoutUser: () => false,
    loginUser: () => false,
    resetPassword: () => false
});

const { get: fetchUserAction }: any = useFetch('/api/routes/users/getUserData');
const { post: logoutUserAction }: any = useFetch(
    '/api/routes/users/logoutUser'
);
const { put: updateUserAction } = useFetch('/api/routes/users/updateUser');

export const AuthProvider = connect(
    (state: any) => ({
        user: state.auth.user,
        cart: state.app.cart
    }),
    {
        logIn,
        logOut
    }
)(({ children, logIn, logOut, user, cart }: any) => {
    const history = useHistory();
    const { msgWarning } = useSnackMessages();
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const init = useCallback(async () => {
        try {
            setIsLoading(true);
            const userData = await fetchUserAction();
            if (!userData.user_data || userData?.user_data?.role !== 'user') {
                throw new Error('Not authenticated');
            }
            setIsLogged(true);
            logIn(userData.user_data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }, []);

    const logoutUser = useCallback(async () => {
        try {
            await logoutUserAction({});
            logOut();
            setIsLogged(false);
        } catch (error) {
            msgWarning('There was an error logging out, please try again!');
        }
    }, []);

    const loginUser = useCallback(async (values) => {
        let data = await fetch('/api/routes/users/loginUser', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(values),
            headers: { 'Content-Type': 'application/json' }
        });
        const response = await data.json();
        if (
            (response.status && response?.status !== 200) ||
            response?.message !== 'Success'
        ) {
            msgWarning('Check your email/password and try again!');
        } else {
            setIsLogged(true);
            logIn(response?.user_data);

            if (cart.length) {
                history.push(PATHS.CHECKOUT);
            } else {
                history.push('/landing/feed');
            }
        }
    }, []);

    const resetPassword = useCallback(async (values) => {
        let data = await fetch('/api/routes/users/resetPassword', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: { 'Content-Type': 'application/json' }
        });
        const response = await data.json();
        if (
            (response.status && response?.status !== 200) ||
            response?.message !== 'Success'
        ) {
            msgWarning(
                'Something went wrong with changing your password, please contact us for assistance!'
            );
        } else {
            window.location.href = '/landing/promo?resetPass=true';
        }
    }, []);

    const updateUser = useCallback(async (values) => {
        try {
            await updateUserAction(values);
        } catch (error) {
            msgWarning('Something went wrong when updating your account!');
        }
    }, []);

    useEffect(() => {
        init();
    }, [isLogged]);

    return (
        <AuthContext.Provider
            value={{
                isLogged,
                logoutUser,
                loginUser,
                resetPassword,
                updateUser,
                userData: user?.data
            }}
        >
            {isLoading ? <LoaderComponent /> : children}
        </AuthContext.Provider>
    );
});

export const useAuth = () => {
    return useContext(AuthContext);
};
