import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import styled from 'src/theme/styled';
import { otherPortals } from 'src/constants/otherPortals';
import { ExperiencePortals } from 'src/constants/experiencePortals';
import { ResourcePortals } from 'src/constants/resourcePortals';
import { UtilityPortals } from 'src/constants/utilityPortals';
import { ReactComponent as PortalIcon } from 'src/assets/portals/mobile-portal-icon.svg';
import { ReactComponent as ExperiencePortalIcon } from 'src/assets/portals/experience-portal-icon.svg';
import { ReactComponent as Profile } from 'src/assets/svg/profile.svg';
import { ReactComponent as ContactIcon } from 'src/assets/svg/contact.svg';
import TokenModal from 'src/components/mobile/TokenModal';
import ChatModal from 'src/components/mobile/ChatModal';
import tokenPath from 'src/assets/portals/token.svg';
import { setReturnToPage } from 'src/utils/localstorage';

const FooterWrap = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: row;
    display: flex;
    height: 56px;
    z-index: 11112;
    justify-content: space-between;
    border-top: 0.25px solid var(--primary-transparent-35);
    background: var(--primary-favorite-color);
    @media (min-width: 1024px) {
        display: none;
    }
`;
const StyledMenuDiv = styled.div`
    flex: 1;
    background: var(--primary-favorite-color);
    display: flex;
    flex: none;
    width: 73px;
    border-right: 0.25px solid var(--primary-transparent-35);
    border-left: 0.25px solid var(--primary-transparent-35);

    &:first-child {
        border-left: unset;
        border-right: 0.25px solid var(--primary-transparent-35);
    }

    &:last-child {
        border-left: 0.25px solid var(--primary-transparent-35);
        border-right: unset;
    }
`;

const StyledMenuItem = styled(NavLink)<{
    favoriteColor: string;
}>`
    flex: 1;
    display: flex;

    svg {
        width: 28px;
    }

    &.active {
        & svg path {
            fill: var(--primary-transparent-35);
            stroke: none;
        }
        & circle {
            fill: var(--primary-transparent-35);
            stroke: none;
        }
    }

    &:last-of-type.active {
        & svg path {
            stroke: #fff;
        }
    }
`;
const Button = styled.button`
    border: none !important;
    outline: none !important;
    flex: 1;
    position: relative;
    background: transparent;
    & > img {
        width: 24px;
        height: 24px;
    }
`;
const NotificationSpan = styled.span<{ favoriteColor: string }>`
    position: absolute;
    font-family: Nunito, sans-serif;
    width: 10px;
    height: 10px;
    background-color: var(--primary-favorite-color);
    color: var(--primary-text-color);
    border-radius: 8px;
    padding: 1px 3px;
`;

const SHOW_TOKEN_LINK = false;

const Footer = ({
    user,
    isLogged,
    favoriteColor,
    hasNewMessage,
    gamesToSubmitOn,
    mostPopularColor,
    gamesToVoteOn,
    isColorActive
}: any) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const AllPortals = [
        ...ExperiencePortals,
        ...ResourcePortals,
        ...UtilityPortals,
        ...otherPortals
    ];
    const [showTokenModal, setShowTokenModal] = useState<boolean>(false);
    const [showChatModal, setShowChatModal] = useState<boolean>(false);
    const [currentPortal, setCurrentPortal]: any = useState({});
    const [activeToken, setActiveToken] = useState<string>('');

    const portalFooterIcons = useMemo(
        () =>
            currentPortal && currentPortal?.footerIcons
                ? currentPortal?.footerIcons
                : undefined,
        [currentPortal]
    );

    useEffect(() => {
        let portal = AllPortals.find((portal) =>
            pathname.includes(portal?.base)
        );
        if (portal) setCurrentPortal(portal);
    }, [pathname, AllPortals]);

    const portalHasExperience = useMemo(() => false, []);

    const userColorToken = useMemo(() => {
        const color = user?.data?.color;
        return color
            ? `https://push-colors.s3.amazonaws.com/push_colors_${color?.toLowerCase()}.svg`
            : '';
    }, [user?.userData]);

    const isActiveLink = (linkPath) => {
        if (pathname === linkPath || pathname.startsWith(linkPath + '/')) {
            return 'active';
        }
        return '';
    };

    return (
        <>
            <FooterWrap>
                {isLogged && (
                    <StyledMenuDiv onClick={() => setShowChatModal(true)}>
                        <Button>
                            <Profile />
                            {hasNewMessage && (
                                <NotificationSpan
                                    favoriteColor={
                                        favoriteColor ? favoriteColor : 'Red'
                                    }
                                />
                            )}
                        </Button>
                    </StyledMenuDiv>
                )}
                {currentPortal && currentPortal.mobileLink && (
                    <StyledMenuItem
                        exact
                        favoriteColor={
                            favoriteColor ? favoriteColor : mostPopularColor
                        }
                        to={currentPortal?.mobileLink}
                    >
                        <Button>
                            {currentPortal?.key === 'landing' ? (
                                <ExperiencePortalIcon />
                            ) : (
                                <PortalIcon />
                            )}
                        </Button>
                    </StyledMenuItem>
                )}
                {portalFooterIcons &&
                    portalFooterIcons[0] &&
                    (portalFooterIcons[0].showAlways ||
                        isLogged === portalFooterIcons[0].showWhenLogged) && (
                        <StyledMenuItem
                            exact
                            favoriteColor={
                                favoriteColor ? favoriteColor : mostPopularColor
                            }
                            to={
                                portalFooterIcons[0]?.path
                                    ? portalFooterIcons[0]?.path(pathname)
                                    : pathname
                            }
                        >
                            <Button>{portalFooterIcons[0].icon}</Button>
                        </StyledMenuItem>
                    )}
                {isLogged &&
                    portalFooterIcons &&
                    portalFooterIcons[1] &&
                    (portalFooterIcons[0].showAlways ||
                        isLogged === !portalFooterIcons[0].showWhenLogged) && (
                        <StyledMenuItem
                            exact
                            id="vote-tab"
                            favoriteColor={
                                favoriteColor ? favoriteColor : mostPopularColor
                            }
                            className={isActiveLink(portalFooterIcons[1]?.path ? portalFooterIcons[1]?.path(pathname) : pathname)}
                            to={
                                portalFooterIcons[1]?.path
                                    ? portalFooterIcons[1]?.path(pathname)
                                    : pathname
                            }

                        >
                            <Button>
                                {portalFooterIcons[1].icon}
                                {currentPortal?.key === 'landing' && (
                                    <>
                                        {isLogged &&
                                            (gamesToVoteOn > 0 ||
                                                gamesToSubmitOn > 0) && (
                                                <NotificationSpan
                                                    favoriteColor={
                                                        favoriteColor
                                                            ? favoriteColor
                                                            : 'black'
                                                    }
                                                />
                                            )}
                                    </>
                                )}
                            </Button>
                        </StyledMenuItem>
                    )}
                {portalFooterIcons && portalFooterIcons[2] && (
                    <StyledMenuItem
                        exact
                        id="rank-tab"
                        favoriteColor={
                            favoriteColor ? favoriteColor : mostPopularColor
                        }
                        className={
                            portalFooterIcons[2]?.path === '/wipp'
                                ? 'portal-disabled'
                                : ''
                        }
                        to={
                            portalFooterIcons[2]?.path
                                ? portalFooterIcons[2]?.path(pathname)
                                : pathname
                        }
                    >
                        <Button>{portalFooterIcons[2].icon}</Button>
                    </StyledMenuItem>
                )}
                <StyledMenuDiv>
                    <Button>
                        <ContactIcon
                            onClick={() => {
                                setReturnToPage(pathname);
                                history.push('/societymechanics');
                            }}
                            style={{ height: 30 }}
                        />
                    </Button>
                </StyledMenuDiv>
                {currentPortal && isLogged && SHOW_TOKEN_LINK && (
                    <StyledMenuDiv onClick={() => setShowTokenModal(true)}>
                        <Button>
                            {isColorActive ? (
                                <img src={userColorToken} height={22} />
                            ) : (
                                <>
                                    <img
                                        src={
                                            activeToken
                                                ? activeToken
                                                : tokenPath
                                        }
                                        alt="token"
                                    />
                                    {portalHasExperience && (
                                        <NotificationSpan
                                            favoriteColor={
                                                favoriteColor
                                                    ? favoriteColor
                                                    : 'black'
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </Button>
                    </StyledMenuDiv>
                )}
            </FooterWrap>
            {showTokenModal && (
                <TokenModal
                    closeModal={() => {
                        setActiveToken('');
                        setShowTokenModal(false);
                    }}
                />
            )}
            {showChatModal && (
                <ChatModal
                    closeModal={() => {
                        setShowChatModal(false);
                    }}
                />
            )}
        </>
    );
};

export default Footer;
