import React from 'react';
import moment from 'moment';

import {
    MessageContainer,
    MessageTimestamp
  } from '../ChatMessage.styles';

  interface TimestampProps {
    isMyOwn: boolean,
    date: Date
  }

  const Timestamp = (props: TimestampProps) => {
    return (
        <MessageContainer isMyOwn={props.isMyOwn}>
          <MessageTimestamp isMyOwn={props.isMyOwn}>
            {moment(props.date).format('h:mm a ')}•
            {moment(props.date).format(' MM/DD/YYYY')}
          </MessageTimestamp>
        </MessageContainer>
      )
};

export default Timestamp;
