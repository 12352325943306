import styled from 'src/theme/styled';

export const Wrapper = styled.div`
    // min-height: 100%;
    // height: 100%;
    // padding: 42px 24px 80px;
    padding-top: 20px;
    width: 100%;
    position: relative;
    text-align: center;
    flex: 1;
`;

export const WrapperNotAlignes = styled.div`
    min-height: 100%;
    height: 100%;
    padding: 42px 24px 80px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

export const WrapperAligned = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.div`
    text-align: center;
    height: 100%;
    align-items: flex-start;
`;

export const Text = styled.span`
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.025em;
`;

export const Button = styled.div`
    padding: 12px 20px;
    max-width: 226px;
    background: #ffffff;
    color: #60341b; // #000000
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.025em;
    text-transform: uppercase;
`;

export const RedButton = styled.button<{
    small?: boolean;
}>`
    border: none;
    background: #ff0022;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.025em;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0px;
    padding: 12px 24px;
`;

export const WhiteButton = styled.button<{
    small?: boolean;
}>`
    border: none;
    background: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.025em;
    text-align: center;
    text-transform: uppercase;
    color: #ff0022;
    margin: 0px;
    padding: 12px 24px;
`;

export const TokenText = styled.div<{ gutterBottom?: boolean }>`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2em;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: ${({ gutterBottom }) => gutterBottom ? '20px;' : '0;'};
    color: #ffffff;
`;

export const FixedButton = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background: #ff0022;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 21px;
    line-height: 16px;
    color: #ffffff;
`;

export const Hr = styled.hr<{ color?: string }>`
    border-top-color: ${({ color }) => color || '#000000'};
    width: 100%;
`;

export const Preview = styled.div<{
    bg?: string;
    color?: string;
}>`
    background: ${({ bg }) => bg || '#000000'};
    color: ${({ color }) => color || '#000000'};
    padding: 24px;
`;

export const TokenImg = styled.img`
    max-height: 350px;
    max-width: 350px;
    margin: 20px auto 60px;
`;

export const TokenVideo = styled.video`
    max-height: 350px;
    max-width: 350px;
    margin: 20px auto 60px;
`;

export const Success = styled.div<{
    color?: string;
}>`
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ color }) => color || '#000000'};
`;

export const TextLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left !important;

    span {
        align-items: flex-start !important;
        text-align: left !important;
    }
`;

export const TextRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end !important;
    text-align: right !important;

    span {
        justify-content: flex-end !important;
        text-align: right !important;
    }
`;

export const PortalText = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.025em;
    color: #000;
`;

export const ScrollWrapper = styled.div`
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: center;
    display: flex;
`;

export const Price = styled.div`
    color: #ffffff;
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 40px;
    margin: 20px 0;
`;

export const CountDownWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
    span {
        &:last-of-type {
            padding-left: 10px;
        }
    }
`;
