import React, { useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useSnackMessages from 'src/hooks/useSnackMessages';
import logoPath from 'src/assets/svg/imagine-council-original.svg';
import ICModal from '../Modal';
import { DateInputFormik } from '../DateInput/DateInputFormik';
import { DateValues } from 'src/constants/dates';
import {
  LoginBox,
  LoginSection,
  MemberstackForm,
  LoginContainer,
  Separator,
  ConnectWalletButton,
  Warning,
  WalletText,
  StyledImg
} from './StyledComponents';

const CONNECT_WALLET_ENABLED = true;

const SignupComponent = ({ isMobile, showLoginModal, closeModal }) => {
  const { msgWarning, msgSuccess } = useSnackMessages();
  const [loading, setLoading] = useState(false);
  const [showConnectWalletText, setShowConnectWalletText] =
    useState('CONNECT WALLET');

  // Sort for DateInputFormik
  const dateSort = {
    day: 'asc',
    month: 'asc',
    year: 'desc'
  }

  const register = async (values) => {
    setLoading(true);
    delete values.confirm;
    values.type = 'pushuser';
    values.email = values.email.toLowerCase();
    values.birthDate = new Date(values.year, values.month - 1, values.day, 12, 0, 0, 0);

    try {
      const response = await fetch('/api/routes/users/signUpUser', {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' }
      });
      const data = await response.json();

      setLoading(false);
      if (data.message === 'Success') {
        msgSuccess('Message with onboarding instructions was sent to your email.', {
          disableClickAway: true
        });
        showLoginModal();
      } else {
        msgWarning(data.message);
      }
    } catch (error) {
      msgWarning(
        `Sorry something went wrong. We are working hard to fix this issue. Please be patient`
      );
    }
  };

  function onConnectWalletClick(event) {
    event.preventDefault()
    setShowConnectWalletText('COMING SOON')
  }

  return (
    <ICModal
      topClassName="ic-modal"
      imgSource={isMobile ? '' : logoPath}
      closeModal={closeModal}
    >
      <LoginBox>
        <LoginSection>
          <LoginContainer>
            {isMobile && <StyledImg src={logoPath} alt="isMobile" />}
            <Formik
              initialValues={{
                day: '',
                month: '',
                year: '',
                username: '',
                email: '',
                phone: '',
                password: '',
                confirm: '',
                code: ''
              }}
              validationSchema={validationSchema}
              onSubmit={register}
            >
              {({ values, errors, submitCount }) => (
                <MemberstackForm className="memberstack-form">
                  <Field
                    type="text"
                    name="username"
                    value={values.username}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="login-input login-w-input"
                        placeholder="username"
                        onChange={(e) => {
                          if (e.target.value.length <= 20) {
                            field.onChange(e);
                          }
                        }}
                      />
                    )}
                  />
                  {errors.username && submitCount > 0 && (
                    <ErrorMessage
                      name="username"
                      component={Warning}
                    />
                  )}
                  <Field
                    name="email"
                    placeholder="email"
                    type="email"
                    className="login-input login-w-input"
                  />
                  {submitCount > 0 && (
                    <ErrorMessage
                      name="email"
                      component={Warning}
                    />
                  )}
                  <Field
                    placeholder="password"
                    name="password"
                    type="password"
                    className="login-input login-w-input"
                  />
                  {submitCount > 0 && (
                    <ErrorMessage
                      name="password"
                      component={Warning}
                    />
                  )}
                  <Field
                    placeholder="confirm password"
                    name="confirm"
                    type="password"
                    className="login-input login-w-input"
                  />
                  {submitCount > 0 && (
                    <ErrorMessage
                      name="confirm"
                      component={Warning}
                    />
                  )}
                  <div className="w-100 d-flex align-items-center justify-content-center login-input-bday-div">
                    <label className="birthday-label">Birthday</label>
                  </div>
                  <DateInputFormik days={DateValues.days} months={DateValues.months} years={DateValues.years} sort={dateSort} selectClasses="login-input login-w-input login-select mb-0" />
                  {submitCount > 0 && (
                    <ErrorMessage
                      name="birthDate"
                      component={Warning}
                    />
                  )}
                  <input
                    type="submit"
                    value={
                      loading ? 'Please wait...' : 'Sign up'
                    }
                    disabled={loading}
                    className="login-button-2 w-button"
                  />
                  <div className="terms-text">
                    <div className="terms-text text-center">
                      By joining, you are agreeing to our{' '}
                      <a
                        href="/terms"
                        className="login-page-link terms"
                      >
                        Terms of Service
                      </a>
                      <a
                        href="/"
                        target="_blank"
                        className="login-page-link"
                      >
                        <span className="text-span-20">
                          {' '}
                        </span>
                      </a>
                      and
                      <a className="login-page-link">
                        <span> </span>
                      </a>
                      <a href="/privacy" className="link-14">
                        Privacy
                      </a>
                    </div>
                  </div>
                  <div className="terms-text">
                    Already have account?
                  </div>
                  <input
                    type="button"
                    value='Log in'
                    className="login-button-3 w-button"
                    onClick={showLoginModal}
                  />
                  <Separator />
                  <ConnectWalletButton
                    disabled={!CONNECT_WALLET_ENABLED}
                    onClick={onConnectWalletClick}
                  >
                    {showConnectWalletText}
                  </ConnectWalletButton>
                  <WalletText>
                    Must have Data Identity Token (DiT)
                    to connect your wallet.{' '}
                  </WalletText>
                </MemberstackForm>
              )}
            </Formik>
          </LoginContainer>
        </LoginSection>
      </LoginBox>
    </ICModal>
  );
};

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Required field!').min(4, '4 symbols minimum').max(15, '15 symbols maximum'),
  email: Yup.string().email('Invalid email!').required('Required field!'),
  password: Yup.string().min(8, 'Too Short!').required('Required field!'),
  day: Yup.string().required('Required field!'),
  month: Yup.string().required('Required field!'),
  year: Yup.string().required('Required field!'),
  confirm: Yup.string().required('Required field!').oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export default SignupComponent;
