import {
    LOG_OUT,
    LOG_IN,
    USER_CREATED_GPC_ACCOUNT,
    SAVE_USER_ONBOARDING_INDEX,
    USER_VERIFIED,
    USER_ONBOARDED,
    USER_SAVED,
} from './types';
import { createAction } from 'redux-actions';

export const logOut = createAction(LOG_OUT);
export const loginUser = createAction(LOG_IN);
export const saveUser = createAction(USER_SAVED);
export const saveUserGpcAccount = createAction(USER_CREATED_GPC_ACCOUNT);
export const saveUserOnboardingIndex = createAction(SAVE_USER_ONBOARDING_INDEX);
export const userVerified = createAction(USER_VERIFIED);
export const userOnboarded = createAction(USER_ONBOARDED);
