import * as authActions from '../actions/auth';
import { handleActions } from 'redux-actions';
import coreTheme from 'src/theme/CoreTheme/coreTheme';

// const colorsMap = {
//   yellow: 'yellow',
//   black: 'black',
//   green: 'green',
//   blue: 'blue',
//   orange: 'orange',
//   brown: 'brown',
//   red: 'red',
//   purple: 'purple'
// };

const savedFavoriteColor = localStorage.getItem('ic-favorite-color');

const defaultColor = 'blue';

const defaultState = {
    balance: 0,
    selectedColor: savedFavoriteColor || defaultColor,
    favoriteColor: savedFavoriteColor
        ? coreTheme.colors[savedFavoriteColor]
        : coreTheme.colors[defaultColor],
    globalStats: {
        coins_paid: '0',
        games_in_play: '...loading',
        users_engaged: '...loading',
        hub_in_lead: '...loading',
        most_popular_color: '...loading'
    },
    user: {
        email: null,
        isLogged: false,
        data: {
            color: savedFavoriteColor || defaultColor,
            onboarding: undefined,
            gpcAccount: false
        }
    }
};

export default handleActions(
    {
        [authActions.loginUser]: (state = defaultState, action) => ({
            ...state,
            selectedColor: action.payload.color.toLowerCase(),
            favoriteColor: coreTheme.colors[action.payload.color.toLowerCase()],
            user: {
                email: action.payload.email,
                isLogged: true,
                data: { ...action.payload }
            }
        }),
        [authActions.saveUser]: (state, action) => ({
            ...state,
            selectedColor: action.payload.userData.color.toLowerCase(),
            favoriteColor:
                coreTheme.colors[action.payload.userData.color.toLowerCase()],
            user: {
                ...state.user,
                data: {
                    ...state.user.data,
                    ...action.payload.userData
                }
            }
        }),
        [authActions.saveUserGpcAccount]: (state, action) => ({
            ...state,
            user: {
                ...state.user,
                data: {
                    ...state.user.data,
                    gpcAccount: true
                }
            }
        }),
        [authActions.userVerified]: (state, action) => ({
            ...state,
            user: {
                ...state.user,
                data: {
                    ...state.user.data,
                    verified: action.payload
                }
            }
        }),
        [authActions.userOnboarded]: (state, action) => ({
            ...state,
            user: {
                ...state.user,
                data: {
                    ...state.user.data,
                    onboarding: action.payload
                }
            }
        }),
        [authActions.saveUserOnboardingIndex]: (state, action) => ({
            ...state,
            user: {
                ...state.user,
                data: {
                    ...state.user.data,
                    onboardingIndex: action.payload
                }
            }
        }),
        [authActions.logOut]: (state) => {
            localStorage.removeItem('user');
            localStorage.removeItem('showUsd');
            localStorage.removeItem('user-message-counter');
            localStorage.removeItem('games-to-submit');
            localStorage.removeItem('games-to-vote');
            localStorage.removeItem('user-message-counter');
            localStorage.removeItem('ic-favorite-color');
            localStorage.removeItem('cart');

            return defaultState;
        }
    },
    defaultState
);
