import React, { FC } from 'react';
import { Text } from './Text';
import { ParagraphProps } from './Text.interface';

export const Header1: FC<ParagraphProps> = props => {
    return <Text fontSize={27} lineHeight={1.1} m={0} as="p" {...props} />;
};

export const Paragraph1: FC<ParagraphProps> = props => {
    return <Text fontSize={18} lineHeight={24} m={0} as="p" {...props} />;
};

export const Paragraph2: FC<ParagraphProps> = props => {
    return <Text fontSize={16} lineHeight={20} m={0} as="p" {...props} />;
};

export const Paragraph3: FC<ParagraphProps> = props => {
    return <Paragraph2 {...props} fontWeight={500} />;
};

export const Paragraph4: FC<ParagraphProps> = props => {
    return <Text fontSize={14} lineHeight={18} m={0} as="p" {...props} />;
};

export const Paragraph5: FC<ParagraphProps> = props => {
    return <Paragraph4 fontWeight={500} {...props} />;
};

export const Paragraph6: FC<ParagraphProps> = props => {
    return <Text fontSize={12} lineHeight={14} m={0} as="p" {...props} />;
};

export const Paragraph7: FC<ParagraphProps> = props => {
    return <Paragraph6 fontWeight={500} {...props} />;
};

export const Paragraph8: FC<ParagraphProps> = props => {
    return <Text fontSize={11} lineHeight={14} m={0} as="p" {...props} />;
};
