import React from 'react';
import loadable from '@loadable/component';
import LoaderComponent from 'src/components/common/Loader/LoaderComponent';
import LazyBoundary from "src/utils/LazyBoundary";

import type { DynamicPaths } from './types';

export const DYNAMIC_PATHS = {
    UTILITY: `/:story/:portal(availablePortals)/:drrt?/utility/:utilityType(3d|video|audio)`,
    UTILITIES: `/:story/:portal(availablePortals)/:drrt?/utilities`,
    COLLECTIBLE_STAGES: `/:story/:portal(availablePortals)/:drrt?/stages`,
    COLLECTIBLE: `/:story/:portal(availablePortals)/:drrt?`
}

const getLoader = () => <LoaderComponent />;
const Utilities = loadable(() => import('src/pages/Utilities'));
const Utility = loadable(() => import('src/pages/Utility'));
const CollectibleToken = loadable(() => import('src/pages/CollectibleToken'));
const CollectibleTokenStages = loadable(() => import('src/pages/CollectibleTokenStages'));

export function getDynamicRoutes(paths: DynamicPaths) {
    const ROUTES = [
        {
            path: paths.COLLECTIBLE,
            exact: true,
            authRequired: true,
            hasNavBar: true,
            hasBottomBar: true,
            component: (props) => {
                return (
                    <LazyBoundary fallback={getLoader}>
                        <CollectibleToken {...props} />
                    </LazyBoundary>
                );
            }
        },
        {
            path: paths.COLLECTIBLE_STAGES,
            exact: true,
            authRequired: true,
            hasNavBar: true,
            hasBottomBar: true,
            component: (props) => {
                return (
                    <LazyBoundary fallback={getLoader}>
                        <CollectibleTokenStages {...props} />
                    </LazyBoundary>
                );
            }
        },
        {
            path: paths.UTILITIES,
            exact: true,
            authRequired: true,
            component: (props) => {
                return (
                    <LazyBoundary fallback={getLoader}>
                        <Utilities {...props} />
                    </LazyBoundary>
                );
            }
        },
        {
            path: paths.UTILITY,
            exact: true,
            authRequired: true,
            component: (props) => {
                return (
                    <LazyBoundary fallback={getLoader}>
                        <Utility {...props} />
                    </LazyBoundary>
                );
            }
        }
    ]
    
    return ROUTES;
} 
