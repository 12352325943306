import React from 'react';

import { ReactComponent as DitIcon } from 'src/assets/portals/dit/dit-icon.svg';
import DitBanner from 'src/assets/portals/dit/dit-banner.svg';
import { ReactComponent as DitToken } from 'src/assets/portals/dit/dit-token.svg';

import { ReactComponent as LedgerToken } from 'src/assets/portals/ledger/ledger-token.svg';
import LedgerBanner from 'src/assets/portals/ledger/ledger-banner.svg';
import { ReactComponent as LedgerIcon } from 'src/assets/portals/ledger/ledger-icon.svg';

import { ReactComponent as GoborbIcon } from 'src/assets/svg/goborb.svg';
import GoborbToken from 'src/assets/portals/goborb/goborb-token.png';
import GoborbBanner from 'src/assets/portals/goborb/goborb-banner.svg';
import { ReactComponent as GoborbBannerIcon } from 'src/assets/portals/goborb/goborb-icon.svg';

import { ReactComponent as UtilityPortalIcon1 } from 'src/assets/portals/utility-icon-1.svg';
import { ReactComponent as UtilityPortalIcon2 } from 'src/assets/portals/utility-icon-2.svg';
import portalTypes from './portalTypes';

const getFooterIcons = (link1, link2 = '') => {
    let icons = [];
    if (link1) {
        icons = [...icons, {
            showAlways: true,
            icon: <UtilityPortalIcon1 />,
            path: () => link1
        }]
    } if (link2) {
        icons = [...icons, {
            showAlways: true,
            icon: <UtilityPortalIcon2 />,
            path: () => link2
        }
        ];
    }
    return icons;
};

export const UtilityPortals = [
    {
        key: 'dit',
        name: 'Digital identity token',
        showWhenLoggedOut: true,
        link: '/dit',
        base: '/dit',
        mobileLink: '/dit',
        icon: <img height={32} src={`https://collectible-media.s3.us-west-2.amazonaws.com/ID_Token.png`} alt="DIT"/>,
        // icon: <DitIcon />,
        type: portalTypes.UTILITY,
        footerIcons: getFooterIcons(),//'/dit/info', '/wipp'
        scroll: {
            background: '#E3854C',
            thumb: '#E7E600'
        },
        banner: {
            token: <DitToken />,
            icon: <DitIcon />,
            background: '#273753',
            color: '#fff',
            image: DitBanner,
            title: 'Data Identity Token (DiT)',
            subtitle: 'Recource Portal',
            description: 'OWN YOUR DATA'
        }
    },
    {
        key: 'goborb',
        name: 'goborb',
        showWhenLoggedOut: true,
        type: portalTypes.UTILITY,
        link: '/goborb',
        base: '/goborb',
        url: 'https://goborb.com',
        mobileLink: '/goborb',
        icon: <GoborbIcon />,
        footerIcons: getFooterIcons(
            '/goborb/info'
            // '/goborb/reserve'
        ),
        banner: {
            token: <img src={GoborbToken} alt="token" />,
            icon: <GoborbBannerIcon />,
            background: '#FFD700',
            color: '#000000',
            image: GoborbBanner,
            title: 'GOBORB (GOBORB)',
            subtitle: 'Recource Portal',
            description: 'GLOBALLY OPERATING BRANDS ORBITAL ROCKET BOOST'
        },
        scroll: {
            background: '#cc8400',
            thumb: '#ffd27f'
        }
    },
    {
        key: 'ledger',
        name: 'ledger',
        link: '/ledger',
        base: '/ledger',
        mobileLink: '/ledger',
        showWhenLoggedOut: true,
        icon: <LedgerIcon />,
        footerIcons: getFooterIcons('/ledger/info', '/wipp'),
        withTopBorder: true,
        type: portalTypes.UTILITY,
        scroll: {
            background: '#4d267f',
            thumb: '#d7b7ff'
        },
        banner: {
            token: <LedgerToken />,
            icon: <LedgerIcon />,
            background: '#9B4DFF',
            color: '#FFFFFF',
            image: LedgerBanner,
            title: 'Nodnarb (NN)',
            subtitle: 'Recource Portal',
            description: 'ART EXPRESSIONS OF DATA'
        }
    }
];
