import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import chatMessageType from '../../../constants/chatTypes';
import fieldType from 'src/constants/fieldType';
import ObjectBoxField from './components/ObjectBoxField';
import gpcCoin from '../../../assets/svg/lightning-Icon.svg';
import TypingIndicator from './components/TypingIndicator';
import UserTextField from './components/UserTextField';
import UserPhoneField from './components/UserPhoneField';
import Timestamp from './components/Timestamp';
import EndMessage from './components/EndMessage';
import MultipleSystemMessages from './components/MultipleSystemMessages';
import UserService from 'src/services/user';

import {
    Avatar,
    RecievedMessageBox,
    SystemMessageContainer,
    UserMessageContainer,
    ReceivedImg,
    SentMessageBox
} from './ChatMessage.styles';
import Default from './components/Default';

const MessageComponent = ({
    colors,
    hubs,
    creativities,
    asnwersSet,
    favoriteColor,
    most_popular_color,
    userVerifiedEmail,
    handleSendAnswer,
    handleLinkSeen,
    handleUserOnboarded,
    onboarded,
    sendVerifyEmail,
    sendVerifyPhone,
    message: chatMessage,
    ...props
}: any) => {
    const history = useHistory();
    const [textValue, setTextValue] = useState('');
    const [inputError, setInputError] = useState('');
    const [showMessageDelay, setShowMessageDelay] = useState(false);
    const [textColor, setTextColor] = useState('#000');

    const {
        _id,
        messageCreationDate,
        link,
        message,
        isMyOwn,
        seen,
        chatGameType,
        field
    } = chatMessage;

    const isSystemMessage = !isMyOwn;
    const messageLinkSeen = link && link.split('linkSeen=')?.[1] === 'true';
    const messageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const typingRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if (!showMessageDelay && !seen) {
            setShowMessageDelay(true);
        } else if (messageRef.current) {
            messageRef.current.scrollIntoView();
        }
    }, [chatGameType]);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (showMessageDelay) {
            if (typingRef.current) {
                typingRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            timeout = setTimeout(() => {
                setShowMessageDelay(false);
                if (messageRef.current) {
                    messageRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 4000);
        }
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [showMessageDelay]);

    useEffect(() => {
        if (favoriteColor) {
            setTextColor(UserService.getColorToUse(favoriteColor));
        } else {
            setTextColor(UserService.getColorToUse(most_popular_color));
        }
    }, [favoriteColor, most_popular_color]);

    const handleOnMessageClick = () => {
        if (chatGameType === chatMessageType.LINK_MESSAGE && !messageLinkSeen) {
            handleLinkSeen(_id);
            history.push(link);
        }
    };

    const renderSystemMessageByFieldType = () => {
        switch (field) {
            case fieldType.MESSAGE:
            case fieldType.IMAGINATION:
            case fieldType.GREETING_USER:
            case fieldType.PHONE:
                return (
                    <RecievedMessageBox
                        messageLinkSeen={messageLinkSeen}
                        chatGameType={chatGameType}
                        onClick={handleOnMessageClick}
                    >
                        {message}
                    </RecievedMessageBox>
                );
            case fieldType.AVATAR:
            case fieldType.POTENTIAL_EARNING:
            case fieldType.SHOW_COIN:
                let source = message;
                if (field === fieldType.SHOW_COIN) {
                    source = gpcCoin;
                }
                return <ReceivedImg src={source} alt="received image" />;
            case fieldType.END:
                return (
                    <EndMessage
                        color={favoriteColor || most_popular_color}
                        message={message}
                        handleSubmit={() => handleUserOnboarded()}
                        chatGameType={chatGameType}
                        onboarded={onboarded}
                    />
                );
            case fieldType.HUB:
            case fieldType.FAVORITE_COLOR:
            case fieldType.CREATIVE:
                let objects;
                let answerKey: 'hub' | 'color' | 'creative' = 'hub';
                if (field === fieldType.HUB) {
                    objects = hubs.filter((hub: any) => !hub.hidden);
                    answerKey = 'hub';
                } else if (field === fieldType.FAVORITE_COLOR) {
                    objects = colors.filter((color: any) => !color.hidden);
                    answerKey = 'color';
                } else if (field === fieldType.CREATIVE) {
                    objects = creativities.filter(
                        (creativity: any) => !creativity.hidden
                    );
                    answerKey = 'creative';
                }
                return (
                    <div>
                        <RecievedMessageBox
                            messageLinkSeen={messageLinkSeen}
                            chatGameType={chatGameType}
                        >
                            {message}
                        </RecievedMessageBox>
                        {!asnwersSet[answerKey] && (
                            <ObjectBoxField
                                objects={objects}
                                isDisabled={asnwersSet[answerKey]}
                                handleOnClick={(
                                    event: React.MouseEvent<
                                        HTMLButtonElement,
                                        MouseEvent
                                    >,
                                    obj: any
                                ) => {
                                    if (!asnwersSet[answerKey]) {
                                        handleSendAnswer({
                                            ...obj,
                                            fieldType: field
                                        });
                                        event.currentTarget.disabled = true;
                                    }
                                }}
                            />
                        )}
                    </div>
                );
            case fieldType.EMAIL_VERIFICATION:
                return (
                    <MultipleSystemMessages
                        color={favoriteColor || most_popular_color}
                        systemMessage="Didn't receive an email?"
                        message={message}
                        chatGameType={chatGameType}
                        handleOnClick={() =>
                            !userVerifiedEmail && sendVerifyEmail()
                        }
                        showAllMessages={!userVerifiedEmail}
                    />
                );
            case fieldType.PHONE_VERIFICATION:
                return (
                    <MultipleSystemMessages
                        color={favoriteColor || most_popular_color}
                        systemMessage="Didn't receive SMS?"
                        message={message}
                        chatGameType={chatGameType}
                        handleOnClick={() =>
                            !asnwersSet['phoneVerified'] && sendVerifyPhone()
                        }
                        showAllMessages={!asnwersSet['phoneVerified']}
                    />
                );
            default:
                return (
                    <Default
                        message={message}
                        chatGameType={chatGameType}
                    />)
        }
    };

    const renderMessagesForUserToInput = () => {
        switch (field) {
            case fieldType.EMAIL_VERIFICATION:
                if (userVerifiedEmail) {
                    break;
                }
                return (
                    <UserTextField
                        color={favoriteColor || most_popular_color}
                        inputName="verifyEmail"
                        inputType="text"
                        error={inputError}
                        handleChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            const value = e.target.value;
                            let regex = new RegExp(`^\\d+`);
                            if (!regex.test(value)) {
                                setInputError('Only numbers allowed');
                            } else {
                                setTextValue(value);
                                setInputError('');
                            }
                        }}
                        onSubmit={(event) => {
                            if (textValue && !inputError) {
                                handleSendAnswer({
                                    name: textValue,
                                    fieldType: field
                                });
                                event.currentTarget.disabled = true;
                            }
                        }}
                    />
                );
            case fieldType.IMAGINATION:
                if (asnwersSet.imagination) {
                    break;
                }
                return (
                    <UserTextField
                        color={favoriteColor || most_popular_color}
                        error={inputError}
                        inputType="number"
                        inputName="imagination"
                        placeholder="Enter only up to 3 digits"
                        handleChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            const value = e.target.value;
                            let regex = new RegExp(`^\\d{0,3}$`);
                            if (!regex.test(value)) {
                                setInputError('Max 3 digits');
                            } else {
                                setTextValue(value);
                                setInputError('');
                            }
                        }}
                        onSubmit={(event) => {
                            if (textValue && !inputError) {
                                handleSendAnswer({
                                    name: textValue,
                                    fieldType: field
                                });
                                event.currentTarget.disabled = true;
                            }
                        }}
                    />
                );
            case fieldType.PHONE:
                if (asnwersSet.phone) {
                    break;
                }
                return (
                    <UserPhoneField
                        error={inputError}
                        color={favoriteColor || most_popular_color}
                        inputType="tel"
                        inputName="phone"
                        placeholder="US phone format"
                        handleChange={(value) => {
                            // let regex = new RegExp(`^\\d{10}`);
                            // if (!regex.test(value)) {
                            //   setInputError("Only US phone number");
                            // } else {
                            //   setTextValue(value);
                            //   setInputError("");
                            // }
                            setTextValue(value);
                        }}
                        onSubmit={() => {
                            if (textValue && !inputError) {
                                handleSendAnswer({
                                    name: textValue,
                                    fieldType: field
                                });
                            }
                        }}
                    />
                );
            case fieldType.PHONE_VERIFICATION:
                if (asnwersSet.phoneVerified) {
                    break;
                }
                return (
                    <UserTextField
                        error={inputError}
                        color={favoriteColor || most_popular_color}
                        inputName="phoneVerification"
                        inputType="text"
                        handleChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            const value = e.target.value;
                            let regex = new RegExp(`^\\d+`);
                            if (!regex.test(value)) {
                                setInputError('Only numbers allowed');
                            } else {
                                setTextValue(value);
                                setInputError('');
                            }
                        }}
                        onSubmit={(event) => {
                            if (textValue && !inputError) {
                                handleSendAnswer({
                                    name: textValue,
                                    fieldType: field
                                });
                                event.currentTarget.disabled = true;
                            }
                        }}
                    />
                );
        }
    };

    return (
        <>
            {showMessageDelay && isSystemMessage && (
                <TypingIndicator ref={typingRef} />
            )}
            {isSystemMessage && (
                <SystemMessageContainer ref={messageRef}>
                    <Avatar
                        messageLinkSeen={messageLinkSeen}
                        chatGameType={chatGameType}
                        image={props.node_id}
                        isMyOwn={isMyOwn}
                    />
                    {!showMessageDelay && renderSystemMessageByFieldType()}
                </SystemMessageContainer>
            )}
            {isSystemMessage &&
                !showMessageDelay &&
                renderMessagesForUserToInput()}
            {isMyOwn && (
                <UserMessageContainer>
                    <SentMessageBox
                        most_popular_color={most_popular_color}
                        favoriteColor={favoriteColor}
                        colorToUse={textColor}
                    >
                        {message}
                    </SentMessageBox>
                    <Avatar
                        messageLinkSeen={true}
                        chatGameType=""
                        image={props.node_id}
                        isMyOwn={isMyOwn}
                    />
                </UserMessageContainer>
            )}
            {!showMessageDelay && (
                <Timestamp date={messageCreationDate} isMyOwn={isMyOwn} />
            )}
        </>
    );
};

const mapStateToProps = (state: any) => ({
    node_id: state.auth.user.data.node_id,
    favoriteColor: state.auth.favoriteColor,
    most_popular_color: state.app.globalStats.most_popular_color,
    creativities: state.app.globalStats.creativities,
    colors: state.app.globalStats.colors,
    hubs: state.app.globalStats.hubs
});

export default connect(mapStateToProps)(MessageComponent);
