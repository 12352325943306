import { combineReducers } from 'redux';
import auth from './auth';
import layout from './layout';
import app from './app';

export default combineReducers({
    app,
    auth,
    layout
});
