import React from 'react';
import { connect } from 'react-redux';
import RSwitch from "react-switch";
import { useAuth } from 'src/contexts/AuthContext';

import { ReactComponent as FeedIcon } from 'src/assets/svg/feed.svg';
import { ReactComponent as SocietyIcon } from 'src/assets/svg/society.svg';

function Switch({
  value,
  valueChanged,
  color,
  size = 'md',
  uncheckedIcon = false,
  checkedIcon = false
}) {
  const { isLogged } = useAuth();
  const fillColor = "var(--primary-text-color)"

  const fill = !isLogged && fillColor;

  const getIcon = (icon) => {
    const iconStyle = {
      position: 'absolute',
      top: 5,
      left: 8,
      fill: fillColor,
      stroke: fillColor
    };

    switch (icon) {
      case 'feed':
        return (
          <FeedIcon style={iconStyle} />
        );
      case 'society':
        return (
          <SocietyIcon style={iconStyle} />
        );
      default:
        return false;
    }
  }

  return (
    <RSwitch
      className={color ? fillColor : ""}
      css={{ background: fillColor}}
      checked={!value}
      onChange={() => valueChanged(!value)}
      checkedIcon={checkedIcon ? getIcon(checkedIcon) : false}
      uncheckedIcon={uncheckedIcon ? getIcon(uncheckedIcon) : false}
      offColor={fill}
      onColor={fill}
      width={size === 'sm' ? 36 : 48}
      height={size === 'sm' ? 18 : 24}
      activeBoxShadow={undefined}
    />
  );
}

const mapStateToProps = state => ({
  favoriteColor: state.auth.favoriteColor,
  most_popular_color: state.app.globalStats.most_popular_color
});

export default connect(
  mapStateToProps
)(Switch);
