import { useSnackbar } from 'notistack';

const useSnackMessages = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showNotistack = (message, type, options, action = null) => {
        enqueueSnackbar(`${message}`, {
            variant: type,
            style: {
                zIndex: 1111113,
            },
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            ClickAwayListenerProps: {
                onClickAway: () => !options?.disableClickAway && closeSnackbar(),
                touchEvent: 'onTouchStart'
            },
            autoHideDuration: options?.hideDuration || 60000,
            action
        });
    };

    return {
        msgSuccess: (msg, options) => showNotistack(msg, `success`, options),
        msgWarning: (msg, options) => showNotistack(msg, `warning`, options),
        msgError: (msg, options) => showNotistack(msg, `error`, options),
        msgInfo: (msg, options) => showNotistack(msg, `info`, options),
        msgWithAction: (msg, options, action) => showNotistack(msg, 'info', options, action),
        closeMsg: () => closeSnackbar()
    };
};

export default useSnackMessages;
