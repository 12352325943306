import React, { useState, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import styled from 'src/theme/styled';
import { ReactComponent as Chat } from 'src/assets/svg/profile.svg';
import { ReactComponent as LogoOriginal } from 'src/assets/svg/imagine-council-original.svg';
import { ReactComponent as Cart } from 'src/assets/svg/cart.svg';
// import { ReactComponent as Back } from 'src/assets/svg/back.svg';
import { ReactComponent as ContactIcon } from 'src/assets/svg/contact.svg';
import { otherPortals } from 'src/constants/otherPortals';
import { ExperiencePortals } from 'src/constants/experiencePortals';
import { ResourcePortals } from 'src/constants/resourcePortals';
import { UtilityPortals } from 'src/constants/utilityPortals';
import MainEnterPoint from 'src/components/common/MainEnterPoint';
import MoneyWithSwitch from '../../Money';
import CartModal from '../../../mobile/CartModal';
import { ReactComponent as Token } from 'src/assets/portals/token.svg';
import * as types from 'src/store/actions/types';
import { useSettings } from 'src/contexts/SettingsContext';
import { useApp } from 'src/contexts/AppContext';
import { setReturnToPage } from 'src/utils/localstorage';

const SHOW_TOKEN_LINK = false;

const ButtonWrap = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 33px;
    justify-content: space-evenly;
    background: transparent;

    svg {
        &:first-of-type {
            margin-left: 0;
        }
    }
`;
const MoneySection = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderWrap = styled.div<{
  isOpen: boolean;
  isMobile: boolean;
}>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 111;
    border-bottom: 0.25px solid
        ${({ isMobile }) => (isMobile ? 'var(--primary-transparent-35)' : 'var(--primary-transparent-35)')};
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 10px;
    align-items: center;
    height: 56px;
    display: flex;
    opacity: ${({ isOpen }) => (isOpen ? '0.6' : '1')};
    background: ${'var(--primary-favorite-color)'};
    
    svg {
      width: 28px;
    }

`;

const StyledMenuDiv = styled.div<{ favoriteColor: string }>`
    flex: 1;
    //background: #fff;
    display: flex;

    &.active:not(:first-of-type) {
        & path {
            fill: ${({ favoriteColor }) => favoriteColor === '#FFFFFF' ? 'black' : `${favoriteColor}`};
            stroke: none;
        }
    }
    &:not(:first-of-type) {
        & path {
            fill: none;
        }
    }

    &:first-of-type {
        flex: none;
        width: 73px;
    }

    &:last-of-type {
        flex: none;
        width: 73px;
    }
`;

// const UnActiveStyledMenuItem = styled(NavLink)`
//     flex: none;
//     background: #fff;
//     display: flex;
//     width: 73px;
// `;

const StyledMenuItem = styled(NavLink) <{ favoriteColor: string, isMobile: boolean }>`
    display: flex;
    //background: #fff;

    &.active {
        & svg path {
          ${'fill: var(--primary-transparent-35)'};
              ${'stroke: var(--contrast-background)'}
        }
        & circle {
          ${'fill: var(--primary-transparent-35)'};
              ${'stroke: var(--contrast-background)'}
        }
    }

    &:last-of-type.active {
      & svg path {
        ${'fill: var(--primary-transparent-35)'};
        ${'stroke: var(--contrast-background)'};
        
      }
    }
`;

const StyledContactIcon = styled(ContactIcon) <{ favoriteColor: string; isSocietyPage: boolean; isMobile: boolean}>`
  path {
    fill: ${({ isSocietyPage, isMobile }) => isSocietyPage ? isMobile ? "var(--primary-text-color)" : "#000" : ""}
    stroke: ${({ isSocietyPage }) => isSocietyPage && 'none'};
  }
`

const CartLink = styled.div<{ isLogged: string }>`
    flex: 1;
    display: flex;
    position: relative;
    margin-right: ${({ isLogged }) => (!isLogged ? '10px' : '')};
`;

const CartLinkFlexEnd = styled.div`
    cursor: pointer;
    flex: 1;
    //background: #fff;
    display: flex;
    position: relative;
    justify-content: flex-end;
    background: transparent;
`;
const CartLinkFlexStart = styled.div`
    cursor: pointer;
    flex: 1;
    //background: #fff;
    display: flex;
    position: relative;
    justify-content: flex-start;
`;

const CartCount = styled.div`
    position: absolute;
    top: -2px;
    right: -6px;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #000000;
    border-radius: 10px;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
    //background: #ffffff;
    font-weight: 900;
    pointer-events: none;
`;

const Button = styled.button`
    border: none !important;
    background: var(--primary-favorite-color);
    outline: none !important;
    flex: 1;
    position: relative;
`;
const NotificationSpan = styled.span<{ favoriteColor: string }>`
    position: absolute;
    font-family: Nunito, sans-serif;
    width: 10px;
    height: 10px;
    background-color: ${'var(--primary-favorite-color)'};
    color: #fff;
    border-radius: 8px;
    padding: 1px 3px;
`;

const CurrentPortalIcon = styled.div`
    & > svg {
        margin-left: 10px !important;
        height: 30px;
        width: 30px;
    }
`;

const UserAvatar = styled.img`
  height: 30px;
  width: 30px;
`

const StyledDesktopRightDiv = styled.div`
    min-width: 310px;
    max-width: 310px;
    justify-content: space-between;
    align-items: center;
`;

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  toggleSidebar?: () => void;
  sidebarClosed?: boolean;
  isMobile?: boolean;
  mostPopularColor: string;
  isColorActive?: boolean;
  user?: any;
};

export const Header = ({
  mostPopularColor,
  isLogged,
  coins,
  favoriteColor,
  gamesToSubmitOn,
  gamesToVoteOn,
  layout,
  setDesktopLayoutRight,
  setChatLayout,
  cart,
  isColorActive,
  user
}: Props) => {
  const { tokens } = useApp();
  const history = useHistory();
  const { pathname } = useLocation();
  const params: any = useParams();
  const [tokenOpen, setTokenOpen] = useState<boolean>(false);
  const [showCartModal, setShowCartModal] = useState<boolean>(false);
  const { cartOpen, setCartOpen, isMobile } = useSettings();
  const isCoinPage = pathname === '/points/info';
  const isRightPartOfDesktopOpen = layout?.right?.token || layout?.right?.cart || layout?.chat;
  const AllPortals = [
    ...ExperiencePortals,
    ...ResourcePortals,
    ...UtilityPortals,
    ...otherPortals
  ];
  const [currentPortal, setCurrentPortal]: any = useState({});

  const cartCount = useMemo(() =>
    cart.reduce((sum: number, item: any) => sum + (item.count || 1), 0),
    [cart]);

  const portalFooterIcons = useMemo(() => currentPortal && currentPortal?.footerIcons
    ? currentPortal?.footerIcons
    : undefined,
    [currentPortal]
  );

  const isActiveLink = (linkPath) => {
    if (pathname === linkPath || pathname.startsWith(linkPath + '/')) {
      return 'active';
    }
    return '';
  };

  useEffect(() => {
    setDesktopLayoutRight(tokenOpen, cartOpen);
  }, [tokenOpen, cartOpen, setDesktopLayoutRight]);

  useEffect(() => {
    if (isMobile) {
      setShowCartModal(cartOpen);
    }
  }, [isMobile, cartOpen])

  useEffect(() => {
    if (!isRightPartOfDesktopOpen) {
      setCartOpen(false);
    }
  }, [isRightPartOfDesktopOpen, setCartOpen]);

  useEffect(() => {
    let portal = AllPortals.find((portal) => pathname.includes(portal?.base));
    if (portal) {
      setCurrentPortal(portal);
    }
  }, [pathname, AllPortals]);

  const isSocietyPage = pathname.includes('societymechanics');

  const portalHasExperience = useMemo(() => false, []);

  const userColorToken = useMemo(() => {
    const color = user?.data?.color || 'black';
    return `https://push-colors.s3.amazonaws.com/push_colors_${color.toLowerCase()}.svg`;
  }, [user]);

  const headerIcon = useMemo(() => {
    const currentToken = tokens.find((t: any) =>
      t.name.toLowerCase() === params?.drrt?.replaceAll("-", " ")?.toLowerCase()
    );
    const isCollectibleToken = currentToken && ['COLLECTIBLE'].indexOf(currentToken?.type) >= 0;

    if (isCollectibleToken) {
      if (currentToken.portalFull?.icon) {
        return (
          <img
            height={32}
            src={currentToken.portalFull?.icon}
            alt="portal icon"
          />
        )
      }
    } else {
      return <LogoOriginal />;
    }

    return currentPortal?.icon;
  }, [isLogged, tokens, params, currentPortal]);

  function redirectToProfile() {
    setReturnToPage(pathname);
    history.push('/dit')
  }

  return (
    <>
      {isMobile && (
        <HeaderWrap
          isMobile={isMobile}
          isOpen={isRightPartOfDesktopOpen}
        >
          <ButtonWrap>
            <CurrentPortalIcon onClick={() => history.push('/')}>
              {headerIcon}
            </CurrentPortalIcon>
          </ButtonWrap>
          <MoneySection>
            {!isCoinPage && (
              <MoneyWithSwitch
                value={coins}
                isMobile={isMobile}
                isLogged={isLogged}
              />
            )}
            {/* {isLogged && <MainToken isMobile={isMobile} />} */}
          </MoneySection>
          <ButtonWrap>
            <CartLink
              isLogged={isLogged}
              onClick={() => setCartOpen(!cartOpen)}
            >
              <Cart />
              {cartCount ? (
                <CartCount>{cartCount}</CartCount>
              ) : null}
            </CartLink>
            {!isLogged && <MainEnterPoint />}
          </ButtonWrap>
          {isLogged &&
            <ButtonWrap>
              <CartLinkFlexEnd>
                <UserAvatar src={user.data.node_id} alt="user" onClick={redirectToProfile} />
              </CartLinkFlexEnd>
            </ButtonWrap>
          }
          {/* {isLogged && (
            <ButtonWrap style={{ cursor: 'pointer' }}>
              <Back onClick={() => logoutUser()} />
            </ButtonWrap>
          )} */}
        </HeaderWrap>
      )}
      {!isMobile && (
        <HeaderWrap
          isMobile={isMobile}
          isOpen={isRightPartOfDesktopOpen}
        >
          <div className="w-100 row no-marings">
            <ButtonWrap>
              <CurrentPortalIcon onClick={() => history.push('/')}>
                {headerIcon}
              </CurrentPortalIcon>
            </ButtonWrap>
            <div
              className="col no-paddings"
              style={{ minWidth: '457px', maxWidth: '457px' }}
            >
              <div className="d-flex flex-row justify-content-around w-100">
                <ButtonWrap>
                  <MoneyWithSwitch
                    value={coins}
                    // color="#000000"
                    isMobile={isMobile}
                    isLogged={isLogged}
                  />
                  {/* {isLogged && <MainToken isMobile={isMobile} />} */}
                </ButtonWrap>
              </div>
            </div>
            <div className="d-flex align-items-center col no-paddings">
              <ButtonWrap className="w-100">
                {!!portalFooterIcons?.length && portalFooterIcons[0] && (portalFooterIcons[0].showAlways || isLogged === portalFooterIcons[0].showWhenLogged) && (
                  <StyledMenuItem
                    isMobile={isMobile}
                    exact
                    favoriteColor={favoriteColor ? favoriteColor : mostPopularColor}
                    to={{
                      pathname: portalFooterIcons[0]?.path ? portalFooterIcons[0]?.path(pathname) : pathname
                    }}
                  >
                    <Button>
                      {portalFooterIcons[0].icon}
                    </Button>
                  </StyledMenuItem>
                )}
                {isLogged && !!portalFooterIcons?.length && portalFooterIcons[1] && (portalFooterIcons[0].showAlways || isLogged === !portalFooterIcons[0].showWhenLogged) && (
                  <StyledMenuItem
                    isMobile={isMobile}
                    exact
                    favoriteColor={favoriteColor ? favoriteColor : mostPopularColor}
                    to={{
                      pathname: portalFooterIcons[1]?.path ? portalFooterIcons[1]?.path(pathname) : pathname
                    }}
                    className={isActiveLink(portalFooterIcons[1]?.path ? portalFooterIcons[1]?.path(pathname) : pathname)}
                  >
                    <Button>
                      {portalFooterIcons[1].icon}
                      {isLogged && (gamesToSubmitOn > 0 || gamesToVoteOn > 0) && (
                        <NotificationSpan
                          favoriteColor={favoriteColor ? favoriteColor : 'black'}
                        />
                      )}
                    </Button>
                  </StyledMenuItem>
                )}
                {!!portalFooterIcons?.length && portalFooterIcons[2] && (
                  <StyledMenuItem
                    isMobile={isMobile}
                    exact
                    favoriteColor={favoriteColor ? favoriteColor : mostPopularColor}
                    to={{
                      pathname: portalFooterIcons[2]?.path ? portalFooterIcons[2]?.path(pathname) : pathname
                    }}
                  >
                    <Button>
                      {portalFooterIcons[2].icon}
                    </Button>
                  </StyledMenuItem>
                )}
              </ButtonWrap>
            </div>
            <StyledDesktopRightDiv
              id="right-drawer-opener"
              className="col d-flex"
            >
              {isLogged && (
                <ButtonWrap>
                  <CartLinkFlexStart>
                    <Chat onClick={() => setChatLayout(true)} />
                  </CartLinkFlexStart>
                </ButtonWrap>
              )}
              {SHOW_TOKEN_LINK && isLogged && (
                <ButtonWrap>
                  {SHOW_TOKEN_LINK && currentPortal && (
                    <StyledMenuDiv
                      id="rank-tab"
                      favoriteColor={favoriteColor ? favoriteColor : 'Green'}
                    >
                      <Button
                        onClick={() => {
                          setCartOpen(false);
                          setTokenOpen(!tokenOpen);
                        }}
                      >
                        {isColorActive ? (
                          <img
                            src={userColorToken}
                            height={22}
                            alt="user color token"
                          />
                        ) : (
                          <>
                            <Token />
                            {portalHasExperience && (
                              <NotificationSpan
                                favoriteColor={favoriteColor ? favoriteColor : 'black'}
                              />
                            )}
                          </>
                        )}
                      </Button>
                    </StyledMenuDiv>
                  )}
                </ButtonWrap>
              )}
              {!isLogged && (
                <MainEnterPoint />
              )}
              <ButtonWrap>
                <StyledContactIcon
                  isMobile={isMobile}
                  isSocietyPage={isSocietyPage}
                  favoriteColor={favoriteColor ? favoriteColor : 'Green'}
                  onClick={() => {
                    setReturnToPage(pathname);
                    history.push('/societymechanics')
                  }}
                  style={{
                    height: 30
                  }}
                />
              </ButtonWrap>
              <ButtonWrap>
                <CartLinkFlexEnd>
                  <Cart
                    onClick={() => {
                      setTokenOpen(false);
                      setCartOpen(!cartOpen);
                    }}
                  />
                  {cartCount ? (
                    <CartCount>{cartCount}</CartCount>
                  ) : null}
                </CartLinkFlexEnd>
              </ButtonWrap>
              {isLogged &&
                <ButtonWrap>
                  <CartLinkFlexEnd>
                    <UserAvatar
                      src={user.data.node_id}
                      alt="user"
                      onClick={redirectToProfile}
                    />
                  </CartLinkFlexEnd>
                </ButtonWrap>
              }
              {/* {isLogged && (
                <ButtonWrap style={{ cursor: 'pointer' }}>
                  <Back onClick={() => logoutUser()} />
                </ButtonWrap>
              )} */}
            </StyledDesktopRightDiv>
          </div>
        </HeaderWrap>
      )}
      {showCartModal && (
        <CartModal
          closeModal={() => {
            setShowCartModal(false);
            setCartOpen(false);
          }}
        />
      )}
    </>
  );
};

const mapState = (state: any) => ({
  isLogged: state.auth.user.isLogged,
  node_id: state.auth.user.data.node_id,
  hasNewMessage: state.app.usersMessagesCounter,
  coins: state.auth.user && state.auth.user.data && state.auth.user.data.coins,
  favoriteColor: state.auth.favoriteColor,
  gamesToSubmitOn: state.app.gamesToSubmit,
  gameOpenedFromVoting: state.app.gameOpenedFromVoting,
  gameOpenedFromSubmission: state.app.gameOpenedFromSubmission,
  gameOpenedFromRank: state.app.gameOpenedFromRanks,
  openedChat: state.app.openedChat,
  gamesToVoteOn: state.app.gamesToVote,
  user: state.auth.user,
  cart: state.app.cart,
  layout: state.layout
});

const mapDispatch = {
  setDesktopLayoutRight: (token: boolean, cart: boolean) => ({
    type: types.SET_DESKTOP_LAYOUT_RIGHT,
    payload: {
      token: token,
      cart: cart
    }
  }),
  setChatLayout: (chatState: boolean) => ({
    type: types.SET_CHAT_LAYOUT,
    payload: chatState
  })
};

const connector = connect(mapState, mapDispatch);

export default connector(Header);
