import React from 'react';

import {
    MessageContainer,
    Input,
    SubmitButton,
    CenteredSpan,
    ErrorMessage
  } from '../ChatMessage.styles';

  interface UserTextFieldProps {
    inputType: string,
    inputName: string,
    maxLen?: number,
    placeholder?: string
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    color: string;
    error: string;
  }

  const UserTextField = (props: UserTextFieldProps) => {
    return (
        <>
        <MessageContainer isMyOwn={true}>
            <Input
              placeholder={props.placeholder}
              name={props.inputName}
              type={props.inputType}
              maxLength={props.maxLen}
              onChange={props.handleChange}
            />
            <SubmitButton
              favoriteColor={props.color}
              onClick={(event) => props.onSubmit(event)}
            >
              <CenteredSpan>Push</CenteredSpan>
            </SubmitButton>
        </MessageContainer>
        {props.error && <ErrorMessage isMyOwn={false}>{props.error}</ErrorMessage>}
        </>
      )
};

export default UserTextField;
