import React from 'react';
import styled from 'src/theme/styled';
import { keyframes } from '@emotion/core';

const LoaderWrapper = styled.div<{
    fitBlock?: boolean;
}>`
    position: absolute;
    width: 100%;
    min-width: 100%;
    height: ${({ fitBlock }) => (fitBlock ? '100%' : '100vh')};
    min-height: ${({ fitBlock }) => (fitBlock ? '100%' : '100vh')};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const spinner6 = keyframes`
    0%,
    100% {
        transform: scale(0.25);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0;
    }
`;

const LoaderSpinner1 = styled.div`
    position: absolute;
    top: calc(50% - 75px / 2);
    left: calc(50% - 75px / 2);
    width: 75px;
    height: 75px;
    background: #bbbb88;
    border-radius: 50%;
    animation: ${spinner6} 1000ms ease-in-out infinite;
`;
const LoaderSpinner2 = styled.div`
    animation-delay: calc(650ms / -2);
    position: absolute;
    top: calc(50% - 75px / 2);
    left: calc(50% - 75px / 2);
    width: 75px;
    height: 75px;
    background: #eeaa88;
    border-radius: 50%;
    animation: ${spinner6} 3000ms ease-in-out infinite;
`;

const LoaderComponent = ({ fitBlock = false }: { fitBlock?: boolean }) => {
    return (
        <LoaderWrapper fitBlock={fitBlock}>
            <LoaderSpinner1></LoaderSpinner1>
            <LoaderSpinner2></LoaderSpinner2>
        </LoaderWrapper>
    );
};

export default LoaderComponent;
