import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import appleSvg from "../../../../assets/svg/apple-pay.svg"
import BounceLoader from "react-spinners/BounceLoader";
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import styles from "./ApplePay.module.scss"

const ApplePay = ({ 
    paymentSecret,
    isMobile,
    proceed
}) => {
  console.log("paymentSecret up front", paymentSecret)
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(true)

  const checkForPayment = async() => {
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Demo total',
        amount: 1099,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    
    // Check the availability of the Payment Request API.
    let canPayment = await pr.canMakePayment()
    console.log('canPayment', canPayment)
    setPaymentRequest(pr);
    setIsLoading(false)
  }

  useEffect(() => {
    if (stripe) {
      checkForPayment()
    }
  }, [stripe]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', async (ev) => {
        console.log('paymentSecret', paymentSecret)
        console.log('ev.paymentMethod', ev.paymentMethod)
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
          paymentSecret,
          {payment_method: ev.paymentMethod.id},
          {handleActions: false}
        );
      
        console.log('confirmError', confirmError)
        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const {error} = await stripe.confirmCardPayment(paymentSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment has succeeded.
            }
          } else {
            // The payment has succeeded.
          }
          proceed()
        }
      });
    }
  }, [paymentRequest])

  

  if (paymentRequest) {
    return (
      <div className={styles.container}>
        <div className={styles.paymentWrapper}>
          <PaymentRequestButtonElement options={{paymentRequest}} />
        </div>
      </div>
    );
  } 

  if (isLoading) {
    return (
      <div className={styles.container}>
        <img className={styles.cardImage} src={appleSvg} alt="credit-card" />
        <div className={styles.cardWrapper}>
          <div className={styles.loaderWrapper}>
            <BounceLoader
              size={25}
              color={"var(--primary-text-color)"}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <img className={styles.cardImage} src={appleSvg} alt="credit-card" />
      <div className={styles.cardWrapper}>
        <p>Sorry something went wrong with apple pay, we are working on solution.</p>
      </div>
    </div>
  );
}

ApplePay.propTypes = {
  proceed: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};

ApplePay.defaultProps = {
    isMobile: false
}

export default ApplePay
