import React, { useState } from "react";
import PropTypes from "prop-types";

import DeliveryInfo from "../DeliveryInfo";

import cc from "../../../../../assets/svg/cc-pay.svg"
import applePay from "../../../../../assets/svg/apple-pay.svg"
import paymentOptions from "../../paymentOptions"

import styles from "./Step4.module.scss"

const Step4 = ({
  deliveryInfo,
  goNext,
  editStep,
  deliveryOption
}) => {
  const [paymentOption, setPaymentOption] = useState("")
  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

  return (
    <div className={styles.step}>
      <div className={styles.container}>
        <div className={styles.deliveryInfo}>
          <DeliveryInfo className={styles.info} deliveryInfo={deliveryInfo} />
          <span
            className={styles.edit}
            onClick={() => editStep(0)}
          >
            edit
          </span>
        </div>
        <div className={styles.deliveryOption}>
          <span className={styles.value}>
            {deliveryOption?.value || deliveryOption}
          </span>
          <span
            className={styles.edit}
            onClick={() => editStep(1)}
          >
            edit
          </span>
        </div>
        <div className={styles.cardWrapper}>
          <div className={styles.card}>
            <span
              className={`${styles.toggle} ${paymentOption === paymentOptions.CC ? styles.active : ""}`}
              onClick={() => setPaymentOption(paymentOptions.CC)}
            />
            <img className={styles.image} src={cc} alt="credit-card" />
          </div>
          {isSafari &&
            <div className={styles.card}>
              <span
                className={`${styles.toggle} ${paymentOption === paymentOptions.APPLE_PAY ? styles.active : ""}`}
                onClick={() => setPaymentOption(paymentOptions.APPLE_PAY)}
              />
              <img className={styles.image} src={applePay} alt="apple-pay" />
            </div>
          }
          <button
            className={styles.enter}
            onClick={() => paymentOption && goNext(paymentOption)}
          >
            enter
          </button>
        </div>
      </div>
    </div>
  );
};

Step4.propTypes = {
  deliveryInfo: PropTypes.object.isRequired,
  deliveryOption: PropTypes.string.isRequired,
  goNext: PropTypes.func.isRequired,
  editStep: PropTypes.func.isRequired
};

export default Step4
