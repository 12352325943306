import React, { Children, useEffect } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import coreTheme from './coreTheme';
import UserService from 'src/services/user';
import { toggleFavoriteColor, toggleThemeMode } from 'src/utils/theme';

type ColorToUse = '#FFF' | '#000';

const CoreThemeProvider = (props: any) => {
    const { children, theme, favoriteColor, selectedColor } = props;

    useEffect(() => {
        theme.colors.favoriteColor = favoriteColor;
        const textColor = UserService.getColorToUse(
            favoriteColor
        ) as ColorToUse;

        toggleThemeMode(textColor === '#FFF' ? 'dark' : 'light');
        toggleFavoriteColor(selectedColor);
    }, [favoriteColor, selectedColor]);

    return (
        <EmotionThemeProvider theme={theme}>
            {Children.only(children)}
        </EmotionThemeProvider>
    );
};

CoreThemeProvider.displayName = 'ThemeProvider';
CoreThemeProvider.defaultProps = {
    theme: coreTheme
};

const mapStateToProps = (state: any) => state.auth;

export default connect(mapStateToProps)(CoreThemeProvider);
