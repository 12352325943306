import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'src/theme/styled';
import { setReturnToPage } from 'src/utils/localstorage';
import { ICButton } from '../ICButton/ICButton';
import { useSettings } from 'src/contexts/SettingsContext';
import { useApp } from 'src/contexts/AppContext';

interface StyledProps {
    isLast?: boolean;
    colorToUse?: string;
}

const MainHeader = styled.span<StyledProps>`
    font-weight: 900;
    font-size: 42px;
    line-height: 49px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: var(--primary-text-color);
`;

const Header = styled.span<StyledProps>`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 34px;
    color: var(--primary-text-color);
    padding-bottom: 20px;
`;

const Text = styled.span<StyledProps>`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: var(--primary-text-color);
`;

const Row = styled.div<{ colorToUse?: string, noMargin?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 18px;
`;
const Column = styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px 0;
    &:after {
        content: '';
        position: absolute;
        width: 115%;
        left: -30px;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid #ffff00;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
`;

const MainSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px 0;
`;

interface PortalDetailsProps<T> {
    color: string;
    header?: string;
    sub_header?: string;
    text?: string;
    sections?: Array<Details<T>>;
    forceColor?: boolean;
    link?: string;
    isMobile: boolean;
    isLogged: boolean;
}

type Details<T> = {
    id?: string;
    header?: string;
    subheader?: string;
    description: Array<TextDetails>;
    footer?: string;
    showLearn?: boolean;
    index: number;
    link?: {
        text?: string;
        goto?: string;
        openModal?: string;
    }
};

type TextDetails = {
    text: string;
    subtitle?: string;
};

const PortalDetails = <T,>(props: PortalDetailsProps<T>) => {
    const history = useHistory();
    const { setShowSignUp } = useSettings();
    const { tokens } = useApp();
    const { color, header, sub_header, text, sections } = props;
    const colorToUse = color;

    useEffect(() => {
        const scrollToSection = () => {
            const { hash } = history.location;
            if (hash) {
                const sectionId = hash.substring(1); // Remove the '#' symbol from the hash
                const sectionElement = document.getElementById(sectionId);
                sectionElement && sectionElement.scrollIntoView({ behavior: 'smooth' });
            }
        };

        if (!!sections?.length) {
            scrollToSection(); // Scroll to the section when the component mounts
        }
    }, [sections, history.location]);

    const lastDrrtToken = useMemo(() =>
        tokens?.filter((t: any) => t.symbol === 'DRRT').sort((a: any, b: any) => b?.storyFull?.number - b?.storyFull?.number)?.[0],
        [tokens]);

    const handleLinkClick = useCallback((pd: Details<T>) => {
        const url: string = pd.link?.goto || ''

        if (pd.link?.goto) {
            setReturnToPage(`/#${pd.id}`)
            history.push(url)
        } else if (pd?.link?.openModal === 'signup') {
            setShowSignUp(true)
        }
    }, [lastDrrtToken]);

    return (
        <Wrapper>
            <MainSectionWrapper>
                {header && <MainHeader colorToUse={colorToUse} style={{ marginBottom: 24 }}>{header}</MainHeader>}
                {sub_header && <Header colorToUse={colorToUse}>{sub_header}</Header>}
                {text && <Text colorToUse={colorToUse}>{text}</Text>}
            </MainSectionWrapper>
            {sections && sections.filter(Boolean).map((pd, i) => (
                <Column id={pd.id} key={pd.index ?? i} isLast={sections.length - 1 === i}>
                    {pd.header && (
                        <MainHeader colorToUse={colorToUse} style={{ marginBottom: 24 }}>
                            {pd.header}
                        </MainHeader>
                    )}
                    {pd.subheader && (
                        <Header colorToUse={colorToUse}>
                            {pd.subheader}
                        </Header>
                    )}
                    {typeof pd.description === 'string' ? (
                        <Row colorToUse={colorToUse} noMargin>
                            <Text colorToUse={colorToUse}>{pd.description}</Text>
                        </Row>
                    ) : pd.description.map((desc, index) => (
                        <Row colorToUse={colorToUse} key={index}>
                            <Text colorToUse={colorToUse}>
                                <b>{desc.subtitle}</b> {desc.text}
                            </Text>
                        </Row>
                    ))}
                    {pd.footer && (
                        <Header colorToUse={colorToUse}>
                            {pd.footer}
                        </Header> 
                    )}
                    {pd.link && !!Object.keys(pd?.link) && (
                        <Row>
                            <ICButton
                                onClick={() => handleLinkClick(pd)}
                                background={colorToUse} color="#FF0000">
                                {pd.link?.text}
                            </ICButton>
                        </Row>
                    )}
                </Column>
            ))}
        </Wrapper>
    );
};

export default PortalDetails;
