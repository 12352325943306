import React, { createContext, useCallback, useContext, useState } from "react";
import { useAuth } from "./AuthContext";

export const steps = [
  { title: 'DELIVERY' },
  { title: 'OPTIONS' },
  { title: 'PAYMENT' },
  { title: 'PAYMENTS' },
  { title: 'REVIEW' },
];

const CheckoutContext = createContext<any>({
  showThankYouPage: false,
  setShowThankYouPage: () => false,
  chosenPayment: '',
  setChosenPayment: () => false,
  cardLast4: '',
  setCardLast4: () => false,
  deliveryInfo: {},
  setDeliveryInfo: () => false,
  deliveryOption: 'hold',
  setDeliveryOption: () => false
});

export const CheckoutProvider = (({ children }: { children: any }) => {
  const { isLogged, userData, updateUser } = useAuth();

  const [currentStep, setCurrentStep] = useState(0);
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [chosenPayment, setChosenPayment] = useState('');
  const [cardLast4, setCardLast4] = useState('');
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [deliveryOption, setDeliveryOption] = useState('hold');

  const saveDeliveryInfo = useCallback(async (deliveryInfo) => {
    if (isLogged) {
      await updateUser({
        email: userData?.email,
        patchData: {
          ...deliveryInfo,
        },
      });
      setDeliveryInfo(deliveryInfo);
    }
  }, []);

  const saveDeliveryOption = useCallback(async (deliveryOption) => {
    if (isLogged) {
      await updateUser({
        email: userData?.email,
        patchData: {
          deliveryOption,
        },
      });
      setDeliveryOption(deliveryOption);
    }
  }, []);

  const goToSpecificStep = useCallback((stepIndex) => {
    if (stepIndex === 3) {
      setChosenPayment('');
    }
    setCurrentStep(stepIndex);
  }, []);

  const goToNextStep = useCallback(async (nextStep) => {
    setCurrentStep(nextStep);
  }, [deliveryInfo, deliveryOption, isLogged, userData?.email]);

  return (
    <CheckoutContext.Provider
      value={{
        showThankYouPage,
        setShowThankYouPage,
        chosenPayment,
        setChosenPayment,
        cardLast4,
        setCardLast4,
        deliveryInfo,
        saveDeliveryInfo,
        deliveryOption,
        saveDeliveryOption,
        goToSpecificStep,
        goToNextStep,
        currentStep,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
});

export const useCheckout = () => useContext(CheckoutContext);
