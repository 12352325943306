import React from 'react';
import { useAuth } from 'src/contexts/AuthContext';
import { useCheckout, steps } from 'src/contexts/CheckoutContext';

interface IProps {
  hasDrrt?: boolean;
}

export default function StepTitle({ hasDrrt }: IProps) {
  const { currentStep } = useCheckout();
  const { userData } = useAuth();

  const showKYC = hasDrrt && currentStep === 0 && !userData?.stripeAccountVerified;
  const displayTitle = showKYC ? "INFO" : steps[currentStep]?.title;

  return (
    <div className="col-12 mt-3 mb-3 d-flex justify-content-center">
      <span className="text-center checkout-step-text" style={{ color: "var(--primary-text-color)"}}>
        {displayTitle}
      </span>
    </div>
  );
}
