import React from 'react';

import {
    GridWrapperDiv,
    RecievedMessageBox
} from '../ChatMessage.styles';

interface Props {
    message: string;
    chatGameType: string;
}

const Default = (props: Props) => {
    return (
        <GridWrapperDiv>
            <RecievedMessageBox
                messageLinkSeen={true}
                chatGameType={props.chatGameType}
            >
                {props.message}
            </RecievedMessageBox>
        </GridWrapperDiv>
    )
};

export default Default;
