import * as appActions from '../actions/app';
import { handleActions } from 'redux-actions';
import coreTheme from 'src/theme/CoreTheme/coreTheme';

const gpcUserStorage = JSON.parse(localStorage.getItem('gpc-user'));
const gameToSubmitCounter = localStorage['games-to-submit'] || 0;
const gameToVoteCounter = localStorage['games-to-vote'] || 0;
const usersMessagesCounter = JSON.parse(localStorage.getItem('user-message-counter')) || false;
const savedCart = localStorage.getItem('cart') || '[]';

export const defaultState = {
  gameOpenedFromSubmission: false,
  gameOpenedFromVoting: false,
  gameOpenedFromRanks: false,
  openedChat: false,
  gamesToSubmit: gameToSubmitCounter,
  gamesToVote: gameToVoteCounter,
  usersMessagesCounter: JSON.parse(usersMessagesCounter) === true,
  showUsd: localStorage.getItem('showUsd') || false,
  gameSubmission: '',
  globalStats: {
    coins_paid: '0',
    games_in_play: '...loading',
    users_engaged: '...loading',
    hub_in_lead: '...loading',
    most_popular_color: '...loading'
  },
  gpcUser: gpcUserStorage,
  balance: 0,
  mainToken: {},
  ethPrice: 0,
  isColorActive: false,
  cart: JSON.parse(savedCart) || [],
  userTokens: [],
};

export default handleActions(
  {
    [appActions.userCashedOut]: (state) => ({
      ...state,
      user: {
        ...state.user,
        data: {
          ...state?.user?.data,
          coins: 0
        }
      }
    }),
    [appActions.userHasNotifications]: (state, action) => {
      localStorage.setItem(
        'games-to-vote',
        JSON.stringify(action.payload.gamesToVoteCounter)
      );
      localStorage.setItem(
        'games-to-submit',
        JSON.stringify(action.payload.gamesToSubmitCounter)
      );
      return {
        ...state,
        gamesToSubmit: action.payload.gamesToSubmitCounter,
        gamesToVote: action.payload.gamesToVoteCounter
      };
    },
    [appActions.userMessageCounter]: (state, action) => {
      localStorage.setItem(
        'user-message-counter',
        JSON.stringify(action.payload.usersNotifications)
      );
      return {
        ...state,
        usersMessagesCounter: action.payload.usersNotifications
      };
    },
    [appActions.setUserTokens]: (state, action) => {
      localStorage.setItem(
        'ic-user-tokens',
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        userTokens: action.payload
      };
    },
    [appActions.gameOpenedFromSubmission]: (state) => {
      localStorage.setItem('game-opned-from-submission', true);
      localStorage.setItem('game-opned-from-ranks', false);
      localStorage.setItem('opned-chat', false);
      localStorage.setItem('game-opned-from-voting', false);
      return {
        ...state,
        gameOpenedFromSubmission: true,
        gameOpenedFromVoting: false,
        gameOpenedFromRanks: false,
        openedChat: false
      };
    },
    [appActions.gameOpenedFromNothing]: (state) => {
      localStorage.setItem('game-opned-from-submission', false);
      localStorage.setItem('game-opned-from-ranks', false);
      localStorage.setItem('opned-chat', false);
      localStorage.setItem('game-opned-from-voting', false);
      return {
        ...state,
        gameOpenedFromSubmission: false,
        gameOpenedFromVoting: false,
        gameOpenedFromRanks: false,
        openedChat: false
      };
    },
    [appActions.gameOpenedFromVoting]: (state) => {
      localStorage.setItem('game-opned-from-submission', false);
      localStorage.setItem('game-opned-from-ranks', false);
      localStorage.setItem('opned-chat', false);
      localStorage.setItem('game-opned-from-voting', true);
      return {
        ...state,
        gameOpenedFromSubmission: false,
        gameOpenedFromVoting: true,
        openedChat: false,
        gameOpenedFromRanks: false
      };
    },
    [appActions.gameOpenedFromRanks]: (state) => {
      localStorage.setItem('game-opned-from-submission', false);
      localStorage.setItem('game-opned-from-voting', false);
      localStorage.setItem('opned-chat', false);
      localStorage.setItem('game-opned-from-ranks', true);
      return {
        ...state,
        gameOpenedFromSubmission: false,
        gameOpenedFromVoting: false,
        openedChat: false,
        gameOpenedFromRanks: true
      };
    },
    [appActions.openedChat]: (state) => {
      localStorage.setItem('game-opned-from-submission', false);
      localStorage.setItem('game-opned-from-voting', false);
      localStorage.setItem('game-opned-from-ranks', false);
      localStorage.setItem('opned-chat', true);
      return {
        ...state,
        gameOpenedFromSubmission: false,
        gameOpenedFromVoting: false,
        gameOpenedFromRanks: false,
        openedChat: true
      };
    },
    [appActions.setShowUsd]: (state) => ({
      ...state,
      showUsd: !state.showUsd
    }),
    [appActions.setGameSubmission]: (state, action) => ({
      ...state,
      gameSubmission: action.payload
    }),
    [appActions.setGlobalStats]: (state, action) => ({
      ...state,
      globalStats: {
        ...state.globalStats,
        ...action.payload,
        most_popular_color:
          coreTheme.colors[
          action.payload.most_popular_color.toLowerCase()
          ]
      }
    }),
    [appActions.setBalance]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        data: {
          ...state.user.data,
          goBorbBalance: action.payload
        }
      }
    }),
    [appActions.setMainTokenInfo]: (state, action) => ({
      ...state,
      mainToken: action.payload
    }),
    [appActions.setEthPrice]: (state, action) => ({
      ...state,
      ethPrice: action.payload.price
    }),
    [appActions.updateCartInStore]: (state, action) => {
      localStorage.setItem('cart', JSON.stringify(action.payload));
      return {
        ...state,
        cart: action.payload
      };
    },
    [appActions.toggeColorActive]: (state, action) => ({
      ...state,
      isColorActive: action.payload
    }),
  },
  defaultState
);
