import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'src/theme/styled';
import { ReactComponent as Close } from 'src/assets/svg/exit-white.svg';
import CloseButton from '../CloseButton';
import { useSettings } from 'src/contexts/SettingsContext';

const CloseModal = styled(Close)`
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    z-index: 2;
`;

const ImgStyled = styled.img`
    width: 80px;
    height: 80px;
    position: absolute;
    left: 40%;
    top: -40px;
    z-index: 2;
    border-radius: 50%;
`;

export interface ICModalProps {
    children: React.ReactNode;
    closeModal: () => void;
    closeModalInner?: boolean;
    imgSource?: string;
    className?: string;
    topClassName?: string;
    CloseSvg?: any;
}

const ICModal = ({
    children,
    closeModalInner = false,
    closeModal,
    imgSource,
    className,
    topClassName = '',
    CloseSvg,
}: ICModalProps) => {
    const { isMobile } = useSettings();
    return (
        <Modal className={topClassName} show centered>
            {imgSource && <ImgStyled src={imgSource} alt="modal-image" />}
            {!closeModalInner ? (
                <CloseModal onClick={closeModal} />
            ) : (
                <CloseButton CloseSvg={CloseSvg} close={closeModal} isMobile={isMobile} />
            )}
            <div className={className}>{children}</div>
        </Modal>
    );
};

export default ICModal;
