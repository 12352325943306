import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { usePlacesWidget, ReactGoogleAutocompleteInputProps } from "react-google-autocomplete";
import { useFormik } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import styled from 'src/theme/styled';
import LoaderComponent from 'src/components/common/Loader/LoaderComponent';
import { states } from 'src/constants/states.js';
import useFetch from 'src/services/useFetch';
import { ReactComponent as StripeLogo } from 'src/assets/svg/stripe-logo.svg';

import styles from "./DeliveryForm.module.scss"

const { Config } = require('../../../../config.js')
const googleKey = Config[process.env.REACT_APP_ENV]['google']

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'var(--primary-favorite-color)'
      }
    },
    MuiSelect: {
      select: {
        border: "2px solid rgba(255,255,255,0.7)"
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'var(--primary-favorite-color)'
      },
      dayDisabled: {
        color: 'var(--primary-favorite-color)'
      },
      current: {
        color: 'var(--primary-favorite-color)'
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: 'var(--primary-favorite-color)'
      }
    }
  }
});

const nameValidations = {
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().required('Last name is required!')
};

const addressValidations = {
  street: Yup.string().required('Street is required!'),
  state: Yup.string().required('State is required!'),
  zip: Yup.string().required('Field is required!')
    .test('Digits only', 'The field should have only digits', value => /^\d+$/.test(value))
    .test('len', 'Must be exactly 5 characters', val => val && val.length === 5)
};

const Warning = styled.p`
    color: var(--secondary-text-color);
    font-size: 14px;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: -10px;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
`;

const Info = styled.div`
  color: var(--primary-text-color);
  margin-bottom: 8px;
`;

const ZipFormatCustom = ({ ...props }) => (
  <MaskedInput
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
    type='tel'
    {...props}
  />
);

const { post: createStripeExpressAccountAction } = useFetch('/api/routes/users/createStripeExpressAccount');

const DeliveryForm = ({
  cart,
  deliveryInfo,
  goNext,
  saveData,
  isMobile,
  user
}) => {
  const [formSubmitting, setFormSubmitting] = useState(false);

  const hasDRRT = useMemo(() => cart?.some(item => item.type === 'DRRT'), [cart]);
  const showKYC = hasDRRT && !user?.data?.stripeAccountVerified;

  const validationSchema = Yup.object().shape(showKYC ? {
    ...nameValidations
  } : {
    ...nameValidations,
    ...addressValidations
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      firstName: deliveryInfo.firstName || "",
      lastName: deliveryInfo.lastName || "",
      country: deliveryInfo.country || "US",
      street: user?.data?.address?.street || deliveryInfo.street || "",
      state: user?.data?.address?.state || deliveryInfo.state || "",
      zip: user?.data?.address?.zip || deliveryInfo.zip || "",
      city: user?.data?.address?.city || deliveryInfo.city || ""
    },
    onSubmit: async (values) => {
      setFormSubmitting(true)
      if (showKYC) {
        await saveData(values);
        createStripeExpressAccount()
      } else {
        saveData(values);
        goNext()
        setFormSubmitting(false)
      }
    }
  });

  const { ref } = usePlacesWidget({
    apiKey: googleKey,
    onPlaceSelected: (place) => {
      const streetNumber = place.address_components.find((p) => p.types.includes("street_number"));
      for (let p of place.address_components) {
        if (p.types.includes("route")) {
          formik.setFieldValue("street", [streetNumber?.short_name, p.short_name].join(' '));
        }
        if (p.types.includes("administrative_area_level_1")) {
          formik.setFieldValue("state", p.short_name);
        }
        if (p.types.includes("postal_code")) {
          formik.setFieldValue("zip", p.short_name);
        }
        if (p.types.includes("locality")) {
          formik.setFieldValue("city", p.short_name);
        }
      }
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" }
    }
  });

  async function createStripeExpressAccount() {
    try {
      const account = await createStripeExpressAccountAction({});
      window.location.href = account?.link?.url;
    } catch (error) {
      console.log(error);
    }
  };

  const setButtonText = () => showKYC ? 'save' : 'enter';

  if (formSubmitting) {
    return <LoaderComponent fitBlock />
  }

  return (
    <ThemeProvider theme={materialTheme}>
      {!showKYC && user.data.gpcAccount ? (
        <p className={styles.title}>
          Delivery address
        </p>
      ) : null}
      <form
        onSubmit={formik.handleSubmit}
        className={styles.form}
      >
        {showKYC && (
          <>
            <Info className="mb-4">
              In Imagine Council Society, purchasing a Digital Royalty Right Token (DRRT) involves a one-time KYC/AML verification process to ensure fairness and prevent wash trading.
            </Info>
            <Info className="mb-4">
              All DRRTs will eventually be tradable outside our system, and we reserve the right to verify your identity to prevent malicious activities.
            </Info>
            <Info className="mb-4">
              When you buy a DRRT, you also earn retroactive points whenever assets, such as collectibles or merchandise, resulting from your ideas are purchased based on royalty you recive during the game plays. These points can be directly paid out to your bank account. 
            </Info>
            <Info className="mb-4">
              To receive payouts, click the button below to create a Stripe account. We've partnered with Stripe for top-notch compliance, ensuring a trusted and reliable transaction process.
            </Info>
            <button
              className={styles.stripeButton}
              onClick={createStripeExpressAccount}
            >
              <StripeLogo className={styles.logo} />
            </button>
          </>
        )}
        {!showKYC && (
          <>
            <div
              className={classNames("d-flex", {
                "flex-row": !isMobile,
                "flex-column": isMobile
              })}
            >
              <TextField
                fullWidth
                color="secondary"
                variant="outlined"
                name="firstName"
                placeholder="First Name"
                className={styles.field}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
            </div>
            {formik?.touched?.firstName && formik.errors.firstName && <Warning>{formik.errors.firstName}</Warning>}
            <div
              className={classNames("d-flex", {
                "flex-row": !isMobile,
                "flex-column": isMobile
              })}
            >
              <TextField
                fullWidth
                color="secondary"
                variant="outlined"
                name="lastName"
                placeholder="Last Name"
                className={styles.field}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik?.touched?.lastName && formik.errors.lastName && <Warning>{formik.errors.lastName}</Warning>}
            <div
              className={styles.fieldWrapper}
            >
              <TextField
                fullWidth
                inputRef={ref}
                color="secondary"
                variant="outlined"
                placeholder="Address"
                name="street"
                className={styles.field}
                onChange={formik.handleChange}
                value={formik.values.street}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik?.touched?.street && formik.errors.street && <Warning>{formik.errors.street}</Warning>}
            <div
              className={styles.fieldWrapper}
            >
              <TextField
                fullWidth
                color="secondary"
                variant="outlined"
                name="city"
                placeholder="City"
                className={styles.field}
                onChange={formik.handleChange}
                value={formik.values.city}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik?.touched?.city && formik.errors.city && <Warning>{formik.errors.city}</Warning>}
            <div
              className={styles.fieldWrapper}
            >
              <TextField
                fullWidth
                color="secondary"
                variant="outlined"
                name="zip"
                placeholder="Postal Code"
                className={styles.field}
                onChange={formik.handleChange}
                value={formik.values.zip}
                onBlur={formik.handleBlur}
                InputProps={{
                  inputMode: 'numeric',
                  inputComponent: ZipFormatCustom
                }}
              />
            </div>
            {formik?.touched?.zip && formik.errors.zip && <Warning>{formik.errors.zip}</Warning>}
            <div
              className={styles.fieldWrapper}
            >
              <TextField
                fullWidth
                select
                color="secondary"
                variant="outlined"
                name="state"
                placeholder="State"
                className={styles.field}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
              >
                {states.map((state) => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {formik?.touched?.state && formik.errors.state && <Warning>{formik.errors.state}</Warning>}
            <div
              className={styles.fieldWrapper}
            >
              <TextField
                disabled
                readOnly
                fullWidth
                variant="outlined"
                name="country"
                placeholder="Country"
                className={styles.field}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
              />
            </div>
            {formik?.touched?.country && formik.errors.country && <Warning>{formik.errors.country}</Warning>}
            <div className="d-flex w-100 mb-3">
              <input
                disabled={formik.isSubmitting || !formik.isValid}
                type="submit"
                value={setButtonText()}
                className={`${styles.enter} no-outline my-auto`}
              />
            </div>
          </>
        )}
      </form>
    </ThemeProvider>
  );
};

DeliveryForm.propTypes = {
  deliveryInfo: PropTypes.object,
  goNext: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  saveData: PropTypes.func.isRequired
};

DeliveryForm.defaultProps = {
  deliveryInfo: {},
  isMobile: false
};

export default DeliveryForm;
