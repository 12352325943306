import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import numbro from 'numbro';
import { ReactComponent as ShopIcon } from "../../../../assets/svg/footer-shop.svg"
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/icon-dropdown.svg"
import '../checkout.scss'
import CountPicker from "../../CountPicker";

import styles from "./CheckoutBasketMobile.module.scss"

const { Config } = require("../../../../config.js");
const picturePrefix = Config[process.env.REACT_APP_ENV]["picturePrefix"]

const CheckoutBasketMobile = ({
  userProducts,
  products,
  cart,
  updateCart
}) => {
  const [showItems, setShowItems] = useState(false)
  const total = useMemo(() => {
    const value = cart.reduce((sum, item) => sum + (item.price * (item.count || 1)), 0);
    return numbro(value).formatCurrency({
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 2
    });
  }, [cart]);

  return (
    <>
      <div className={`${styles.headerWrapper} ${showItems ? "" : styles.showItems}`}>
        <div className={styles.header}>
          <div className={styles.icons}>
            <ShopIcon className={styles.shop} />
            <ArrowIcon
              className={`${styles.toggle} ${showItems ? styles.open : ""}`}
              onClick={() => setShowItems(!showItems)} 
            />
          </div>
          <span className={styles.total}>
            Total -  {total}
          </span>
        </div>
      </div>
      {showItems &&
        <div className={styles.content}>
          {cart.map((cartItem) => (
            <>
              <div className={styles.cartItem}>
                {cartItem?.icon && (
                  <img className={styles.icon} src={cartItem?.icon} alt="token" />
                )}
                <span className={styles.text}>
                  <b>{cartItem?.name}</b>
                  <br />
                  {cartItem?.description}
                  <br />
                  <div className={styles.countWrapper}>
                    <CountPicker
                      item={cartItem}
                      updateCount={(count) =>
                        updateCart(cart.map((c) => (c._id === cartItem._id) ? ({
                          ...c,
                          count
                        }) : c))}
                    />
                    <span className={styles.price}>${cartItem?.price || 0}</span>
                  </div>
                </span>
              </div>
              <div className={styles.divider} />
              {products.length > 0 && (
                <>
                  <div className={styles.collectibles}>
                    COLLECTIBLES
                  </div>
                  <div className={styles.attached}>
                    ATTACHED
                  </div>
                </>
              )}
              {products.map((product) => {
                const productOptions = userProducts.find((userProduct) => userProduct.productId === product._id);
                return (
                  <div className={styles.product} key={product.productId}>
                    <div className={styles.name}>
                      {product.name}
                    </div>
                    <div className={styles.imageWrapper}>
                      <img
                        className={styles.image}
                        src={`${picturePrefix}/${product.images[0]?.url}.png`}
                        alt="product"
                      />
                    </div>
                    {productOptions?.size ? (
                      <span className={styles.size}>
                        size: {productOptions.size}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </>
          ))}
        </div>
      }
    </>
  );
};

CheckoutBasketMobile.propTypes = {
  userProducts: PropTypes.array,
  products: PropTypes.array,
  cart: PropTypes.array
};

CheckoutBasketMobile.defaultProps = {
  userProducts: [],
  products: [],
  cart: []
}

export default CheckoutBasketMobile
