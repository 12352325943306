import React, { createContext, useContext, useState, useEffect } from "react";
import { debounce } from 'lodash';

import TermsModal from '../components/common/TermsModal';
import PrivacyModal from '../components/common/PrivacyModal';
import FaqModal from '../components/common/FaqModal';
import ContactModal from '../components/common/ContactModal';

export type Device = "mobile" | 'tablet' | 'desktop';


interface SettingsContextType {
    device: Device;
    isMobile: boolean;
    showResetPass: boolean;
    setShowResetPass: (show: boolean) => void;
    showLogin: boolean;
    setShowLogin: (show: boolean) => void;
    showSignUp: boolean;
    setShowSignUp: (show: boolean) => void;
    cartOpen: boolean;
    setCartOpen: (open: boolean) => void;
    setShowTerms: (open: boolean) => void;
    setShowPrivacy: (open: boolean) => void;
    setShowFaq: (open: boolean) => void;
    setShowContact: (open: boolean) => void;
    setStory: (story: GameFullStory | null) => void;
    story: GameFullStory | null;
}

const SettingsContext = createContext<SettingsContextType>({
  device: 'desktop',
  isMobile: false,
  showResetPass: false,
  setShowResetPass: (show: boolean) => { },
  showLogin: false,
  setShowLogin: (show: boolean) => { },
  showSignUp: false,
  setShowSignUp: (show: boolean) => { },
  cartOpen: false,
  setCartOpen: (open: boolean) => { },
  setShowTerms: (open: boolean) => { },
  setShowPrivacy: (open: boolean) => { },
  setShowFaq: (open: boolean) => { },
  setShowContact: (open: boolean) => { },
  setStory: (story: GameFullStory) => { },
  story: null,
});

export const DEVICE_SIZES = {
  MOBILE: 375,
  TABLET: 768,
  DESKTOP: 1024
};

export const SettingsProvider = ({ children }: any) => {
  const [device, setDevice] = useState<Device>('mobile');
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showResetPass, setShowResetPass] = useState<boolean>(false);
  const [showSignUp, setShowSignUp] = useState<boolean>(false);
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const [showFaq, setShowFaq] = useState<boolean>(false);
  const [showContact, setShowContact] = useState<boolean>(false);
  const [story, setStory] = useState<GameFullStory | null >(null);



  useEffect(() => {
    const updateWindowDimensions = debounce(() => {
      const width = window.innerWidth;
      let device: Device = 'mobile';
      if (width < DEVICE_SIZES.TABLET) {
        device = 'mobile';
      } else if (width >= DEVICE_SIZES.TABLET && width < DEVICE_SIZES.DESKTOP) {
        device = 'tablet';
      } else if (width >= DEVICE_SIZES.DESKTOP) {
        device = 'desktop';
      }
      setDevice(device);
    }, 50);

    updateWindowDimensions();

    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);


    const handleClose = () => {
        setShowTerms(false);
        setStory(null);
    };

  return (
    <SettingsContext.Provider
      value={{
        device,
        isMobile: device === 'mobile' || device === 'tablet',
        showResetPass,
        setShowResetPass,
        showLogin,
        setShowLogin,
        showSignUp,
        setShowSignUp,
        cartOpen,
        setCartOpen,
        setShowTerms,
        setShowPrivacy,
        setShowFaq,
        setShowContact,
        setStory,
        story,
      }}
    >
      {children}
      {showTerms && (
        <TermsModal close={handleClose}/>
      )}
      {showPrivacy && (
        <PrivacyModal close={() => setShowPrivacy(false)} />
      )}
      {showFaq && (
        <FaqModal close={() => setShowFaq(false)} />
      )}
      {showContact && (
        <ContactModal close={() => setShowContact(false)} />
      )}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
