import React, { useState } from 'react';
import numbro from 'numbro';
import { ReactComponent as TokenBack } from 'src/assets/svg/token-back.svg';
import defaultImage from 'src/assets/characters/default.png';
import styled from 'src/theme/styled';
import { RedButton } from '../PortalPage/shared';
import TokenInfo from './TokenInfo';
import { Tab, Tabs, TokenType, Tools } from './shared';

interface IProps {
    isMobile: boolean;
    token: any;
    setToken: () => void;
    setTokenInfo: () => void;
    tokenType: 'data' | 'chapter' | 'DRRT';
}

const Wrapper = styled.div<{ isMobile: boolean }>`
    // background: #faf0f0;
    height: 100%;
`;

const Header = styled.div<{ isMobile: boolean }>`
    padding: ${(isMobile) => (isMobile ? '16px' : '30px')};
    border-bottom: 0.25px solid #000000;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BackDiv = styled.div`
    position: absolute;
    cursor: pointer;
    left: 20px;
`;
const StyledBack = styled(TokenBack)`
    pointer-events: none;
`;
const TokenDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;
const TokensWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const StyledRedButton = styled(RedButton)`
    padding: 5px 20px;
    margin: 10px 0;
`;
const TokenMoneyInfo = styled.p`
    padding-top: 10px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
`;

const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    & > img {
        object-fit: contain;
        width: 130px;
        height: 130px;
    }
`;
const TokensPartWrapper = styled.div`
    padding: 0 30px;
    display: flex;
    flex-direction: column;
`;

const NoParts = styled.p`
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    align-items: center;
`;

export default function Tokens({
    token,
    isMobile,
    setToken,
    tokenType,
}: IProps) {
    const [activePart, setActivePart] = useState('assets');
    return (
        <Wrapper isMobile={isMobile}>
            <Header isMobile={isMobile}>
                <BackDiv onClick={setToken} id="token-back">
                    <StyledBack />
                </BackDiv>
                <TokenDetailsWrapper className="token-details ">
                    <ImageWrapper>
                        {token?.type ? (
                            <img src={token.icon || token.image || token.symbol || defaultImage} />
                        ) : (
                            <video
                                autoPlay={false}
                                playsInline
                                muted
                                src={token.icon || token.image || defaultImage}
                                style={{ width: 120 }}
                            />
                        )}
                    </ImageWrapper>
                </TokenDetailsWrapper>
                <TokenMoneyInfo>
                    $
                    {numbro(token.price).format({
                        spaceSeparated: false,
                        average: true,
                        mantissa: 2
                    })}{' '}
                    USD
                </TokenMoneyInfo>
                <StyledRedButton>HOLDER</StyledRedButton>
            </Header>
            <TokensWrapper>
                <Tools>
                    <TokenType>{tokenType}</TokenType>
                    <Tabs>
                        <Tab
                            active={activePart === 'assets'}
                            onClick={() => setActivePart('assets')}
                        >
                            collectibles
                        </Tab>
                        <Tab
                            active={activePart === 'info'}
                            onClick={() => setActivePart('info')}
                        >
                            info
                        </Tab>
                    </Tabs>
                </Tools>
                <TokensPartWrapper>
                    {activePart === 'assets' && (
                        <NoParts>no collectibles</NoParts>
                    )}
                    {activePart === 'info' && (
                        <TokenInfo token={token} isMobile={isMobile} />
                    )}
                </TokensPartWrapper>
            </TokensWrapper>
        </Wrapper>
    );
}
