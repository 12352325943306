import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const composeEnhancers =
    process.env.REACT_APP_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

let middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware];
}

export default function configureStore() {
    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middleware))
    );
}
