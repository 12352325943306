/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import { Text } from './Text';
import { HeadingProps } from './Text.interface';

export const Headline1: FC<HeadingProps> = props => {
    return (
        <Text
            fontSize={40}
            lineHeight={42}
            fontWeight={700}
            m={0}
            letterSpacing={0.5}
            as="h1"
            {...props}
        />
    );
};

export const Headline2: FC<HeadingProps> = props => {
    return (
        <Text
            fontSize={32}
            lineHeight={34}
            fontWeight={700}
            m={0}
            as="h2"
            {...props}
        />
    );
};

export const Headline3: FC<HeadingProps> = props => {
    return (
        <Text
            fontSize={24}
            lineHeight={26}
            fontWeight={700}
            m={0}
            as="h3"
            {...props}
        />
    );
};

export const Headline4: FC<HeadingProps> = props => {
    return (
        <Text
            fontSize={24}
            lineHeight={26}
            fontWeight={500}
            m={0}
            as="h4"
            {...props}
        />
    );
};

export const Headline5: FC<HeadingProps> = props => {
    return (
        <Text
            fontSize={20}
            lineHeight={24}
            fontWeight={700}
            m={0}
            as="h5"
            {...props}
        />
    );
};

export const Headline6: FC<HeadingProps> = props => {
    return (
        <Text
            fontSize={20}
            lineHeight={22}
            fontWeight={500}
            m={0}
            as="h6"
            {...props}
        />
    );
};
