import {
    TOGGLE_SHOW_USD,
    SET_GLOBAL_STATS,
    SET_BALANCE
} from './types';
import { createAction } from 'redux-actions';

import useFetch from "../../services/useFetch";

export const setShowUsd = createAction(TOGGLE_SHOW_USD);
export const setGlobalStats = createAction(SET_GLOBAL_STATS);
export const setGameSubmission = createAction('SET_GAME_SUBMISSION');
export const userCashedOut = createAction('USER_CASHED_OUT');
export const userHasNotifications = createAction('USER_HAS_NOTIFICATIONS');
export const userMessageCounter = createAction('USER_MESSAGE_COUNTER');
export const gameOpenedFromSubmission = createAction('GAME_OPENED_FROM_SUBMISSION');
export const gameOpenedFromVoting = createAction('GAME_OPENED_FROM_VOTING');
export const gameOpenedFromRanks = createAction('GAME_OPENED_FROM_RANKS');
export const gameOpenedFromNothing = createAction('GAME_OPENED_FROM_NOTHING');
export const openedChat = createAction('OPENED_CHAT');
export const setBalance = createAction(SET_BALANCE);
export const setMainTokenInfo = createAction('SET_MAIN_TOKEN');
export const setEthPrice = createAction('SET_ETH_PRICE');
export const updateCartInStore = createAction('UPDATE_CART');
export const setUserTokens = createAction('SET_USER_TOKENS');
export const toggeColorActive = createAction('TOGGLE_COLOR_ACTIVE');







//need this code to check if exist token in db for correct adding to cart
const {get: getToken} = useFetch(`/api/routes/tokens`);

const verifyCartItem = async (cartItem) => {
    const item = cartItem[cartItem.length - 1];
    const response = await getToken(item._id)
    if (!response) {
        return false;
    }
    return true;
};


export const updateCart = (cartItem) => {
    return async (dispatch, getState) => {
        try {
            const cart = getState().app.cart;
            if (cart.length > cartItem.length) {
                dispatch(updateCartInStore(cartItem));
            } else {
                const isValid = await verifyCartItem(cartItem);
                if (isValid) {
                    dispatch(updateCartInStore(cartItem));
                } else {
                    console.error("Token is not exist in db");
                }
            }
        } catch (error) {
            console.error("Error with updating cart:", error);
        }
    };
};

