import React from 'react';
import styled from 'src/theme/styled';
import { ReactComponent as ChevronIcon } from 'src/assets/svg/chevron-right.svg';

const ChevronStyled = styled(ChevronIcon) <{ fill: string, disabled: boolean, transformed?: boolean }>`
    height: 14px;
    width: 14px;
    margin: 20px 0;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    transform: ${({ transformed }) => transformed ? 'rotate(-90deg)' : 'rotate(90deg)'};
    filter: drop-shadow(0px 0px 2px #828282);
    
    & > path {
      fill: ${({ fill }) => fill};
      stroke: ${({ fill }) => fill};
    }
`;

export default function CountPicker({ item, updateCount }: any) {
  const tokenCount = item.count || 1;
  return (
    <div className='d-flex align-items-center'>
      <ChevronStyled
        fill='white'
        disabled={tokenCount === 1}
        onClick={() => {
          if (tokenCount > 1) {
            updateCount(tokenCount - 1);
          }
        }}
      />
      <span className='px-3'>{tokenCount}</span>
      <ChevronStyled
        fill='white'
        disabled={tokenCount === 4}
        transformed
        onClick={() => {
          if (tokenCount < 4) {
            updateCount(tokenCount + 1);
          }
        }}
      />
    </div>
  )
}
