import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ChatComponent from './ChatComponent';
import useFetch from 'src/services/useFetch';
import answerType from 'src/constants/chatAnswers';
import { connect } from 'react-redux';
import { userMessageCounter, openedChat } from 'src/store/actions/app';
import {
    saveUser,
    saveUserOnboardingIndex,
    userVerified,
    userOnboarded
} from 'src/store/actions/auth';
import useSnackMessages from 'src/hooks/useSnackMessages';
import LoaderComponent from 'src/components/common/Loader/LoaderComponent';
import { setGlobalStats } from 'src/store/actions/app';
import { useSettings } from 'src/contexts/SettingsContext';
import { useAuth } from 'src/contexts/AuthContext';
import fieldType from 'src/constants/fieldType';
import {useFeed} from "../../contexts/FeedContext";

const { put: nextMessage }: any = useFetch(`/api/routes/users/nextMessage`);
const { put: userOnboardedFinished }: any = useFetch(
    '/api/routes/users/userOnboarded'
);
const { put: answerMessage }: any = useFetch('/api/routes/users/answerMessage');
const { put: linkMessageSeen }: any = useFetch(
    `/api/routes/users/linkMessageSeen`
);
const { put: systemMessageSeen }: any = useFetch(
    '/api/routes/users/messageSeen'
);
const { get: fetchUser }: any = useFetch('/api/routes/users/getUserData');
const { get: getHasNewMessage }: any = useFetch(
    '/api/routes/users/hasNewMessage'
);
const { get: getUserChatHistory }: any = useFetch(
    '/api/routes/users/getUserChatHistory'
);
const { put: verifyEmail }: any = useFetch('/api/routes/users/sendVerifyEmail');
const { put: verifyPhone }: any = useFetch('/api/routes/users/sendVerifyPhone');
const { get: fetchGlobal }: any = useFetch('/api/routes/global/getGlobalStats');

const ChatContainer: any = ({
    user,
    openedChat,
    onboarding,
    userOnboarded,
    verifiedEmail,
    saveUser,
    saveUserOnboardingIndex,
    userMessageCounter,
    userVerified,
    setGlobalStats
}: any) => {
    const { logoutUser } = useAuth();
    const [messages, setMessages] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [noMoreMessages, setNoMoreMessages] = useState<boolean>(false);
    const { isMobile } = useSettings();
    const history = useHistory();
    const { msgSuccess, msgWarning } = useSnackMessages();
    const { loadFeed } = useFeed();

    const timer = (ms: any) => new Promise((res) => setTimeout(res, ms));
    const fetchMessages = useCallback(async (showNewMessage: boolean) => {
        let shouldFetchNewMessage = showNewMessage;
        while (true) {
            const data = await nextMessage({
                newMessage: shouldFetchNewMessage,
                userEmail: user.email
            });
            if (data.message === 'User not authenticated.') {
                logoutUser();
            }
            if (data.chatHistory) {
                saveUserOnboardingIndex(data.chatHistory.length);
                setMessages(data.chatHistory);
            }
            if (data?.message?.fieldName === fieldType.END) {
                setNoMoreMessages(true);
                userVerified(true);
            }
            await timer(4000);
            if (
                data?.message?.fieldName === fieldType.END ||
                data?.message?.answerInputType !== answerType.NONE
            ) {
                break;
            } else if (!shouldFetchNewMessage) {
                shouldFetchNewMessage = true;
            }
        }
        setIsLoading(false);
    }, []);

    const fetchNewSystemMessage = useCallback(async () => {
        setIsLoading(true);
        const data = await getUserChatHistory(
            `?userEmail=${encodeURIComponent(user.email)}`
        );
        let notSeenArray: any = [];
        if (data.message === 'Success') {
            let userChatHistory = data.chatHistory;
            let firstOccurenceIndex = userChatHistory.findIndex(
                (uch: any) => !uch.seen
            );
            if (firstOccurenceIndex > -1) {
                userMessageCounter({ usersNotifications: false });
                let numberOfUnseenElements =
                    userChatHistory.length - firstOccurenceIndex;
                for (let i = 0; i < numberOfUnseenElements; i++) {
                    notSeenArray.push(userChatHistory.pop());
                }
                let index = numberOfUnseenElements - 1;
                while (index >= 0) {
                    if (notSeenArray[index + 1]) {
                        notSeenArray[index + 1].seen = true;
                    }
                    if (notSeenArray[index]) {
                        userChatHistory.push(notSeenArray[index]);
                    }
                    setMessages([]);
                    setMessages(userChatHistory);
                    index--;
                    await timer(3000);
                }
                systemMessageSeen({ userEmail: user.email });
                setIsLoading(false);
            } else {
                setMessages(userChatHistory);
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
            setMessages([]);
        }
        setNoMoreMessages(true);
    }, []);

    const sendVerifyEmail = async () => {
        let response = await verifyEmail({ email: user.email });
        if (response.message === 'Success') {
            msgSuccess('Email is on your way :)');
        }
    };

    const sendVerifyPhone = async () => {
        let response = await verifyPhone({ phone: user.data.phone });
        if (response.status === 200) {
            msgSuccess('SMS is on your way :)');
        } else {
            msgWarning(
                'Something went wrong, please contact us for assistance.'
            );
        }
    };

    const handleLinkSeen = useCallback(async (messageId) => {
        linkMessageSeen({ email: user.email, chatMessageId: messageId });
    }, []);

    const handleUserOnboarded = async () => {
        userOnboarded(true);
        let response = await userOnboardedFinished({ email: user.email });
        if (response.message !== 'Success') {
            msgWarning(
                `Ooops, ${user.email} something went wrong, contact us for more details`
            );
        } else {
            await loadFeed();
            history.push('/');
        }
    };

    const sendAnswer = useCallback(async (answer, fieldType) => {
        const data = await answerMessage({ answer: answer, field: fieldType });
        if (typeof data.message === 'string') {
            msgWarning(data.message);
        } else {
            setMessages((prevMessages: any) => [
                ...prevMessages,
                {
                    isMyOwn: true,
                    message: answer,
                    field: fieldType,
                    _id: 'new_message'
                }
            ]);

            const userData = await fetchUser();
            if (userData.user_data) {
                saveUser({ user: user, userData: userData.user_data });
            }
            await fetchMessages(true);
        }
    }, []);

    useEffect(() => {
        (async () => {
            const response = await fetchGlobal();

            if (response) {
                setGlobalStats(response.stats);
            } else {
                setGlobalStats({
                    coins_paid: '0',
                    games_in_play: '...error',
                    users_engaged: '...error',
                    hub_in_lead: '...error',
                    most_popular_color: '...error'
                });
            }
        })();
        openedChat();

        const checkForMessages = async () => {
            const data = await getHasNewMessage(
                `?userEmail=${encodeURIComponent(user.email)}`
            );
            if (data && data.hasNewMessages) {
                userMessageCounter({ usersNotifications: data.hasNewMessages });
                fetchNewSystemMessage();
            } else {
                // if user.data.onboarding === false means user is in the middle of the onboarding
                // so do not fetch new message until we see if last one required an answer
                fetchMessages(user.data.onboarding);
            }
        };

        checkForMessages();
    }, []);

    if (isLoading) {
        return <LoaderComponent />;
    }

    return (
        <ChatComponent
            onboarding={onboarding}
            isMobile={isMobile}
            handleUserOnboarded={handleUserOnboarded}
            handleLinkSeen={handleLinkSeen}
            sendVerifyEmail={sendVerifyEmail}
            sendVerifyPhone={sendVerifyPhone}
            messages={messages}
            sendAnswer={sendAnswer}
            verifiedEmail={verifiedEmail}
            noMoreMessages={noMoreMessages}
        />
    );
};

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    onboarding: state?.auth?.user?.data?.onboarding,
    verifiedEmail: state?.auth?.user?.data?.verifiedEmail,
    onboardingIndex: state.app.onboardingIndex
});

export default connect(mapStateToProps, {
    saveUser,
    saveUserOnboardingIndex,
    openedChat,
    userMessageCounter,
    userVerified,
    userOnboarded,
    setGlobalStats
})(ChatContainer);
