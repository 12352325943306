import React from 'react';
import styled from 'src/theme/styled';
import ICModal from '../Modal';

interface IProps {
  close: () => void;
}

const Wrapper = styled.div`
  max-height: 500px;
  overflow: auto;
`

// from Neflix
export default function TermsModal({ close }: IProps) {
  // const date = new Date();
  // const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

  return (
    <ICModal closeModal={close} className='terms-modal' topClassName='terms-modal-wrapper'>
      <Wrapper>
        <h2>TERMS OF SERVICE</h2>
        <p>Last Updated: 05/05/2021</p>
        <p>
          PLEASE READ THESE SITE TERMS CAREFULLY. BY ACCESSING OR USING
          OUR WEBSITE, YOU AGREE TO BE BOUND BY THESE SITE TERMS AND ALL
          TERMS AND GUIDELINES INCORPORATED BY REFERENCE. IF YOU DO NOT
          AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE OUR WEBSITE.
        </p>
        <p>
          These Site Terms (&quot;Terms&quot;) apply solely to your access
          to, and use of, any website of Imagine Council (&quot;Imagine
          Council&quot;), including https://imaginecouncil.com
          (&quot;Site&quot;). These Terms do not alter the terms or
          conditions of any other agreement you may have with Imagine
          Council, or its subsidiaries or affiliates, for products,
          services or otherwise. If you are using the Site on behalf of
          any entity, you represent and warrant that you are authorized to
          accept these Terms on such entity&#x27;s behalf and that such
          entity will be responsible to Imagine Council if you violate
          these Terms.
        </p>
        <p>
          Imagine Council reserves the right to change or modify the terms
          and conditions contained in the Terms or any policy or guideline
          of the Site, at any time and in its sole discretion. Any changes
          or modifications will be effective immediately upon posting of
          the revisions to the Site, and you waive any right you may have
          to receive specific notice of such changes or modifications.
          Your continued use of the Site following the posting of changes
          or modifications will confirm your acceptance of such changes or
          modifications; therefore, you should frequently review these
          Terms and all applicable terms and policies to understand the
          terms and conditions that apply to your use. If you do not agree
          to the amended terms, you must stop using the Site. Any use of
          the Site in violation of these Terms may result in, among other
          things, termination or suspension of your right to use the Site.
        </p>
        <p>
          If you have any questions or comments regarding the use of the
          Site, please contact hub@imaginecouncil.com.
        </p>
        <ol role="list">
          <li>
            Terms of Service
            <br />
            Your registration for, and use of, Imagine Council&#x27;s
            products, services or other features, technologies and/or
            functionalities, whether offered through the Site or any
            third party website or application, (&quot;Imagine Council
            Services&quot;) are governed by the{' '}
            <a href="https://www.imaginecouncil.com/terms">
              Terms of Service
            </a>
            . If you do not agree to the Terms of Service, do not use or
            access the Imagine Council Services.
          </li>
          <li>
            Privacy Policy
            <br />
            Please refer to our{' '}
            <a href="https://www.imaginecouncil.com/privacy">
              Privacy Policy
            </a>{' '}
            for information about how Imagine Council collects, uses and
            discloses personally identifiable information from its
            users.
          </li>
          <li>
            Copyright and Limited License
            <br />
            Unless otherwise indicated, the Site and all content and
            other materials on the Site, including, without limitation,
            the Imagine Council logo and all designs, text, graphics,
            pictures, information, data, software, sound files, other
            files and the selection and arrangement thereof
            (collectively, &quot;Site Materials&quot;) are the
            proprietary property of Imagine Council or its licensors or
            users and are protected by U.S. and international copyright
            laws.
            <br />
            You are granted a limited, non-sublicensable license to
            access and use the Site and Site Materials; however, such
            license is subject to these Terms and does not include: (a)
            any resale or commercial use of the Site or Site Materials;
            (b) the distribution, public performance or public display
            of any Site Materials; (c) modifying or otherwise making any
            derivative uses of the Site and the Site Materials, or any
            portion thereof; (d) use of any data mining, robots or
            similar data gathering or extraction methods; (e)
            downloading (other than the page caching) of any portion of
            the Site or the Site Materials or any information contained
            therein, except as expressly permitted on the Site; or (f)
            any use of the Site or the Site Materials other than for
            their intended purposes. Any use of the Site or the Site
            Materials other than as specifically authorized herein,
            without the prior written permission of Imagine Council, is
            strictly prohibited and will terminate the license granted
            herein. Such unauthorized use may also violate applicable
            laws including, without limitation, copyright and trademark
            laws and applicable communications regulations and statutes.
            Unless explicitly stated herein, nothing in these Terms
            shall be construed as conferring any license to intellectual
            property rights, whether by estoppel, implication or
            otherwise. This license is revocable at any time.
          </li>
          <li>
            Trademarks
            <br />
            &quot;Imagine Council ,&quot;
            &quot;imaginecouncil.com,&quot; the Imagine Council logos
            and any other product or service name or slogan contained in
            the Site are trademarks of Imagine Council and its suppliers
            or licensors and may not be copied, imitated or used, in
            whole or in part, without the prior written permission of
            Imagine Council or the applicable trademark holder. You may
            not use any metatags or any other &quot;hidden text&quot;
            utilizing &quot;Imagine Council &quot; or any other name,
            trademark or product or service name of Imagine Council
            without our prior written permission. In addition, the look
            and feel of the Site, including all page headers, custom
            graphics, button icons and scripts, is the service mark,
            trademark and/or trade dress of Imagine Council and may not
            be copied, imitated or used, in whole or in part, without
            our prior written permission. All other trademarks,
            registered trademarks, product names and Imagine Council
            names or logos mentioned in the Site are the property of
            their respective owners. Reference to any products,
            services, processes or other information, by trade name,
            trademark, manufacturer, supplier or otherwise does not
            constitute or imply endorsement, sponsorship or
            recommendation thereof by us.
          </li>
          <li>
            Hyperlinks
            <br />
            You are granted a limited, non-exclusive right to create a
            text hyperlink to the Site for noncommercial purposes,
            provided such link does not portray Imagine Council or any
            of its products and services in a false, misleading,
            derogatory or otherwise defamatory manner and provided
            further that the linking site does not promote illegal
            gambling or contain any obscene, pornographic, sexually
            explicit or illegal material or any material that is
            offensive, harassing or otherwise objectionable. This
            limited right may be revoked at any time. You may not use a
            Imagine Council logo or other proprietary graphic of Imagine
            Council to link to the Site without the express written
            permission of Imagine Council. Further, you may not use,
            frame or utilize framing techniques to enclose any Imagine
            Council trademark, logo or other proprietary information,
            including the images found on the Site, the content of any
            text or the layout/design of any page or form contained on a
            page of the Site without Imagine Council&#x27;s express
            written consent. Except as noted above, you are not conveyed
            any right or license by implication, estoppel or otherwise
            in or under any patent, trademark, copyright or proprietary
            right of Imagine Council or any third party.
            <br />
            Imagine Council makes no claim or representation regarding,
            and accepts no responsibility for, the quality, content,
            nature or reliability of embedded content, third-party
            websites accessible via hyperlink or websites linking to the
            Site. Such sites are not under the control of Imagine
            Council and Imagine Council is not responsible for the
            content of any linked site or any link contained in a linked
            site, or any review, changes or updates to such sites.
            Imagine Council provides these links to you only as a
            convenience, and the inclusion of any link does not imply
            affiliation, endorsement or adoption by Imagine Council of
            any site or any information contained therein. When you
            visit other sites via links or embedded content, you should
            understand that our terms and policies no longer govern and
            that the terms and policies of those third party sites will
            now apply. You should review the applicable terms and
            policies, including privacy and data gathering practices, of
            any site to which you navigate from Imagine Council&#x27;s
            Site.
          </li>
          <li>
            Third Party Content
            <br />
            Imagine Council may provide third party content on the Site
            and may provide links to web pages and content of third
            parties (collectively, &quot;Third Party Content&quot;) as a
            service to those interested in this information. Imagine
            Council does not control, endorse or adopt any Third Party
            Content and makes no representation or warranties of any
            kind regarding the Third Party Content, including without
            limitation regarding its accuracy or completeness. You
            acknowledge and agree that Imagine Council is not
            responsible or liable in any manner for any Third Party
            Content and undertakes no responsibility to update or review
            any Third Party Content. Users use such Third Party Content
            contained therein at their own risk.
          </li>
          <li>
            Submissions
            <br />
            You acknowledge and agree that any questions, comments,
            suggestions, ideas, plans, notes, drawings, original or
            creative materials or other information that you provide to
            Imagine Council regarding Imagine Council, the Site
            (collectively, &quot;Submissions&quot;) are non-confidential
            and shall become the sole property of Imagine Council.
            Imagine Council shall own exclusive rights, including all
            intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of Submissions for any
            purpose, commercial or otherwise, without acknowledgment or
            compensation to you.
          </li>
          <li>
            Indemnification
            <br />
            You agree to defend, indemnify and hold harmless Imagine
            Council , its independent contractors, service providers and
            consultants, and their respective directors, employees and
            agents, from and against any claims, damages, costs,
            liabilities and expenses (including, but not limited to,
            reasonable attorneys&#x27; fees) arising out of or related
            to (a) any actual or alleged breach of these Terms, the{' '}
            <a href="https://www.portalpathway.com/terms">
              Terms of Service
            </a>
            , the{' '}
            <a href="https://www.balancedpayments.com/terms/acceptable_use">
              Acceptable Use Policy
            </a>{' '}
            or any other Imagine Council agreement, policy or guideline
            (b) any actual or alleged violation of applicable laws or
            rules of any payment card association, network or company;
            (c) your wrongful or improper use of the Site; (d) any
            Submission or transaction submitted by you; or (e) your
            violation of the rights of any third party.
          </li>
          <li>
            Disclaimer
            <br />
            EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY
            Imagine Council , THE SITE AND SITE MATERIALS ARE PROVIDED
            ON AN &quot;AS IS&quot; BASIS WITHOUT WARRANTIES OF ANY
            KIND, EITHER EXPRESS OR IMPLIED. Imagine Council DISCLAIMS
            ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
            LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO
            THE SITE AND SITE MATERIALS. Imagine Council DOES NOT
            REPRESENT OR WARRANT THAT MATERIALS IN THE SITE ARE
            ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WHILE
            Imagine Council ATTEMPTS TO MAKE YOUR ACCESS AND USE OF THE
            SITE SAFE, Imagine Council CANNOT AND DOES NOT REPRESENT OR
            WARRANT THAT THE SITE OR ITS SERVER(S) ARE FREE OF VIRUSES
            OR OTHER HARMFUL COMPONENTS; THEREFORE, YOU SHOULD USE
            INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES
            FROM ANY DOWNLOAD.
          </li>
          <li>
            Limitation of Liability
            <br />
            IN NO EVENT SHALL Imagine Council, ITS DIRECTORS, EMPLOYEES
            OR AGENTS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR
            CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND,
            INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR
            LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT
            (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE,
            ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OR
            INABILITY TO USE THE SITE OR THE SITE MATERIALS, INCLUDING
            WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM
            RELIANCE BY USER ON ANY INFORMATION OBTAINED FROM Imagine
            Council , OR THAT RESULT FROM MISTAKES, OMISSIONS,
            INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS,
            VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE
            OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD,
            COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED
            ACCESS TO Imagine Council&#x27;s RECORDS, PROGRAMS OR
            SERVICES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF
            Imagine Council , WHETHER IN CONTRACT, WARRANTY, TORT
            (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED),
            PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING
            OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE
            SITE OR TO THESE TERMS EXCEED ANY COMPENSATION YOU PAY, IF
            ANY, TO Imagine Council FOR ACCESS TO OR USE OF THE SITE.
            <br />
            The laws of certain states or other jurisdictions do not
            allow limitations on implied warranties or the exclusion or
            limitation of certain damages. If these laws apply, some or
            all of the above disclaimers, exclusions or limitations may
            not apply to you.
          </li>
          <li>
            Modifications to the Site
            <br />
            Imagine Council reserves the right to modify or discontinue,
            temporarily or permanently, the Site or any features or
            portions thereof without prior notice. You agree that
            Imagine Council will not be liable for any modification,
            suspension or discontinuance of the Site or any part
            thereof.
          </li>
          <li>
            Applicable Law and Venue
            <br />
            These Terms and your use of the Site shall be governed by
            and construed in accordance with the laws of the State of
            Delaware, without resort to its conflict of law provisions.
            You agree that any action at law or in equity arising out of
            or relating to these Terms shall be filed only in the state
            and federal courts located in New Castle, Delaware and you
            hereby irrevocably and unconditionally consent and submit to
            the exclusive jurisdiction of such courts over any suit,
            action or proceeding arising out of these Terms.
          </li>
          <li>
            Termination
            <br />
            Notwithstanding any of these Terms, Imagine Council reserves
            the right, without notice and in its sole discretion, to
            terminate your license to use the Site and/or to block or
            prevent your future access to, and use of, the Site.
          </li>
          <li>
            Severability
            <br />
            If any provision of these Terms shall be deemed unlawful,
            void or for any reason unenforceable, then that provision
            shall be deemed severable from these Terms and shall not
            affect the validity and enforceability of any remaining
            provisions.
            <br />
          </li>
        </ol>
      </Wrapper>
    </ICModal>
  );
}
