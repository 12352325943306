import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    RouteComponentProps,
    useHistory,
    useLocation,
    useParams
} from 'react-router-dom';
import web3 from 'web3';
import Footer from './Footer';
import Header from './Header';
import LazyBoundary from 'src/utils/LazyBoundary';
import styled from 'src/theme/styled';
import { connect, ConnectedProps } from 'react-redux';
import useFetch from 'src/services/useFetch';
import RenderLayout from '../RenderLayout/RenderLayout';
// import useSnackMessages from 'src/hooks/useSnackMessages';
import { updateCart } from 'src/store/actions/app';
import { useSettings } from 'src/contexts/SettingsContext';

import CloseIcon from 'src/assets/svg/exit-white.svg';
import {getReturnToPage, removeReturnToPage, removeStoryFromSessionStorage} from 'src/utils/localstorage';
import { useApp } from 'src/contexts/AppContext';

interface LayoutProps {
    children: React.ReactElement<any>;
    hasNavBar?: boolean;
    hasBottomBar?: boolean;
    isLogged: any;
    props: any;
}

const SLayout = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
`;
const PushMenu = styled.div<{
    isMobile: boolean;
    hasBottomBar?: boolean;
    hasNavBar?: boolean;
}>`
    height: ${({ isMobile }) => (isMobile ? 'auto;' : '100vh;')}
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    min-width: 320px;
    right: 0;
    flex: 1;
    transition: all 0.3s ease-in;
`;

const ContentContainerDesktop = styled.div<{
    hasNavBar?: boolean;
    hasBottomBar?: boolean;
}>`
    height: 100%;
    display: flex;
    overflow-x: hidden;
    padding-top: ${({ hasNavBar }) => `${hasNavBar ? 56 : 0}px`};
`;

const ContentContainer = styled.div<{
    hasNavBar?: boolean;
    hasBottomBar?: boolean;
}>`
    display: flex;
    height: 100%;
    padding: ${({ hasNavBar, hasBottomBar }) =>
        `${hasNavBar ? 56 : 0}px 0 ${hasBottomBar ? 56 : 0}px`};
`;

const Content = styled.div<{
    isMobile?: boolean;
}>`
    display: flex;
    flex: 1;
    flex-direction: column;
    ${({ isMobile }) => (isMobile ? 'width: 100%; overflow-x: hidden;' : '')};
    // overflow-y: scroll;
`;

const ToggleIcon = styled.img<{
    hasNavBar?: boolean;
    isMobile?: boolean;
}>`
    position: fixed;
    top: ${({ hasNavBar }) => `${hasNavBar ? 72 : 16}px`};
    right: ${({ isMobile }) => `${isMobile ? 12 : 42}px`};
    width: 34px;
    height: 34px;
    z-index: 11113;
    cursor: pointer;
`;

const { get: fetchBalance } = useFetch('/api/routes/blockchain/balance');
const { get: fetchMainToken } = useFetch('/api/routes/blockchain/token');
const { get: getEthPrice } = useFetch('/api/routes/blockchain/eth/price');
const { get: fetchGlobal } = useFetch('/api/routes/global/getGlobalStats');

const routesWithoutHeader = ['/utilities', 'utility'];
const routesShowBack = [
    '/stages',
    '/games',
    '/societymechanics/',
    '/utilities'
];

type PropsFromRedux = ConnectedProps<typeof connector>;

const LayoutComponent: React.FC<
    LayoutProps & RouteComponentProps & PropsFromRedux
> = (props): React.ReactElement => {
    const {
        children,
        hasNavBar,
        hasBottomBar,
        isLogged,
        user,
        favoriteColor,
        gamesToSubmitOn,
        gamesToVoteOn,
        hasNewMessage,
        most_popular_color,
        setBalance,
        setMainTokenInfo,
        setGlobalStats,
        setEthPrice,
        isColorActive
    } = props;

    // const { msgError } = useSnackMessages();
    const { isMobile } = useSettings();
    const location = useLocation();
    const params: any = useParams();
    const history = useHistory();
    const { userTokens } = useApp();
    const [sidebarClosed, setSidebarClosed] = useState<boolean>(
        (isMobile && localStorage.getItem('sidebarClosed') === 'true') || true
    );

    const isFullScreenPage = useMemo(() => {
        return location.pathname === '/checkout';
    }, [location.pathname]);

    const fetchUserBalance = async () => {
        try {
            const balanceResp = await fetchBalance();
            const balance = web3.utils.fromWei(balanceResp.data);
            setBalance(balance);
        } catch (error) {
            //msgError('eth balance not fethced'); not useful to show on each laod
        }
    };

    const init = async () => {
        const response = await fetchGlobal();
        if (response) {
            setGlobalStats(
                response.stats.coins_paid,
                response.stats.games_in_play,
                response.stats.users_engaged,
                response.stats.hub_in_lead,
                response.stats.most_popular_color,
                response.stats.hubs,
                response.stats.colors,
                response.stats.creativities
            );
        } else {
            setGlobalStats(0, 0, 0, '...error', '...error', [], [], []);
        }
        try {
            const mainTokenResp = await fetchMainToken();
            setMainTokenInfo(
                mainTokenResp.data.address,
                mainTokenResp.data.totalSupply,
                mainTokenResp.data.name,
                mainTokenResp.data.price,
                mainTokenResp.data.symbol,
                mainTokenResp.data.totalSupply,
                mainTokenResp.data.availableSupply
            );
            const ethPriceResp = await getEthPrice();
            setEthPrice(ethPriceResp.data);
        } catch (error) {
            // todo: not show error if exists on each render
            //msgError(error);
        }
    };

    useEffect(() => {
        fetchUserBalance();
        init();
    }, []);

    const toggleSidebar = useCallback(() => {
        const newValue = !sidebarClosed;
        setSidebarClosed(!sidebarClosed);
        if (newValue) {
            localStorage.setItem('sidebarClosed', 'true');
        } else {
            localStorage.removeItem('sidebarClosed');
        }
    }, [sidebarClosed]);

    const isTokenOwner = useMemo(() => {
        return !!userTokens.filter(
            (t: any) =>
                t?.name?.toLowerCase() === params?.drrt?.replaceAll("-", " ")?.toLowerCase() ||
                t?.name?.toLowerCase() === params?.token?.toLowerCase()
        ).length;
    }, [userTokens, params?.drrt]);

    const showHeader = useMemo(() => {
        if ((params?.drrt || params?.token) && isTokenOwner) {
            return false;
        }
        return !routesWithoutHeader.some((route) =>
            location.pathname.includes(route)
        );
    }, [params?.drrt, params?.token, isTokenOwner]);

    const showFooter = useMemo(() => {
        if ((params?.drrt || params?.token) && isTokenOwner) {
            return false;
        }
        return location?.pathname === '/' || params?.portal === 'landing';
    }, [params, location, isTokenOwner]);

    const showBackButton = useMemo(() => {
        return routesShowBack.some(
            (path) => location.pathname.indexOf(path) >= 0
        );
    }, [location]);

    return (
        <LazyBoundary fallback={null}>
            <SLayout>
                <PushMenu
                    isMobile={isMobile}
                    hasNavBar={hasNavBar && showHeader}
                    hasBottomBar={hasBottomBar && showFooter}
                >
                    {showHeader && (
                        <Header
                            mostPopularColor={most_popular_color}
                            isMobile={isMobile}
                            toggleSidebar={toggleSidebar}
                            sidebarClosed={sidebarClosed}
                            isColorActive={isColorActive}
                        />
                    )}
                    {isMobile && (
                        <ContentContainer
                            hasNavBar={hasNavBar && showHeader}
                            hasBottomBar={
                                hasBottomBar && sidebarClosed && showFooter
                            }
                        >
                            <Content isMobile>{children}</Content>
                        </ContentContainer>
                    )}
                    {!isMobile && (
                        <ContentContainerDesktop
                            hasNavBar={hasNavBar && showHeader}
                            hasBottomBar={hasBottomBar && showFooter}
                        >
                            <RenderLayout children={children} />
                        </ContentContainerDesktop>
                    )}
                    {isMobile &&
                        sidebarClosed &&
                        !isFullScreenPage &&
                        showFooter && (
                            <Footer
                                user={user}
                                mostPopularColor={most_popular_color}
                                hasNewMessage={hasNewMessage}
                                gamesToSubmitOn={gamesToSubmitOn}
                                gamesToVoteOn={gamesToVoteOn}
                                favoriteColor={favoriteColor}
                                isLogged={isLogged}
                                isColorActive={isColorActive}
                            />
                        )}
                    {!showFooter && !showBackButton && (
                        <ToggleIcon
                            onClick={() => {
                                // We need this to return to the page we were before
                                // go.back() don't work with nested routes or direct links
                                const returnToPage = getReturnToPage();
                                history.push(returnToPage || '/');
                                removeReturnToPage();
                                removeStoryFromSessionStorage();
                            }}
                            src={CloseIcon}
                            hasNavBar={hasNavBar && showHeader}
                            isMobile={isMobile}
                        />
                    )}
                </PushMenu>
            </SLayout>
        </LazyBoundary>
    );
};

const mapState = (state: any) => ({
    user: state.auth?.user,
    most_popular_color: state?.app?.globalStats?.most_popular_color || '#0000c2',
    favoriteColor: state.auth.favoriteColor,
    isLogged: state.auth.user && state.auth.user.isLogged,
    gamesToSubmitOn: state.app.gamesToSubmit,
    gamesToVoteOn: state.app.gamesToVote,
    hasNewMessage: state.app.usersMessagesCounter,
    isColorActive: state.app.isColorActive,
    layout: state.layout
});

const mapDispatch = {
    setMainTokenInfo: (
        address: string,
        maxTotalSupply: string,
        name: string,
        price: string,
        symbol: string,
        totalSupply: string,
        availableSupply: string
    ) => ({
        type: 'SET_MAIN_TOKEN',
        payload: {
            address: address,
            maxTotalSupply: maxTotalSupply,
            name: name,
            price: price,
            symbol: symbol,
            totalSupply: totalSupply,
            availableSupply: availableSupply
        }
    }),
    setEthPrice: (price: string | number) => ({
        type: 'SET_ETH_PRICE',
        payload: {
            price: price
        }
    }),
    setBalance: (balance: string) => ({
        type: 'SET_BALANCE',
        payload: balance
    }),
    updateCart,
    setGlobalStats: (
        coins_paid: number,
        games_in_play: number,
        users_engaged: number,
        hub_in_lead: string,
        most_popular_color: string,
        hubs: any,
        colors: any,
        creativities: any
    ) => ({
        type: 'SET_GLOBAL_STATS',
        payload: {
            coins_paid: coins_paid,
            games_in_play: games_in_play,
            users_engaged: users_engaged,
            hub_in_lead: hub_in_lead,
            most_popular_color: most_popular_color,
            hubs,
            colors,
            creativities
        }
    })
};

const connector = connect(mapState, mapDispatch);

export default connector(LayoutComponent);
