import React from 'react';
import styled from 'src/theme/styled';
import ICModal from 'src/components/common/Modal';
import Checkout from 'src/pages/Checkout';
import { ReactComponent as Cart } from 'src/assets/svg/cart.svg';

const Wrapper = styled.div`
    display: flex;
    margin: 16px 10px;
    flex-direction: column;
    padding-top: 50px;
    height: 100%;
`;
const StyledICModal = styled(ICModal)`
    height: calc(100vh - 115px);
`;

interface IProps {
    closeModal: () => void;
}

function CartModal({ closeModal }: IProps) {
    return (
        <StyledICModal
            topClassName="ic-mobile-cart"
            closeModalInner={true}
            closeModal={closeModal}
            CloseSvg={Cart}
        >
            <Wrapper>
                <Checkout />
            </Wrapper>
        </StyledICModal>
    );
}

export default CartModal;
