import React from "react";
import { Field, ErrorMessage } from "formik";
import styled from 'styled-components';

const Warning = styled.div`
    color: red;
    margin-bottom: 15px;
    margin-top: 0px;
    max-width: 247px;
    font-size: 10px;
`;

export function DateInputFormik({ days, months, years, sort, selectClasses }) {
  return (
    <div className="d-flex flex-row w-100 justify-content-center">
      <div className="d-flex flex-column mr-2">
        <Field
          as="select"
          name="month"
          className={selectClasses}
          required
        >
          <option value="" disabled selected hidden>
            month
          </option>
            {sort && sort.month ?
                (sort.month === 'asc' ?
                months.map((month) => (
                    <option value={month} key={month}>
                        {month}
                    </option>
                )) :
                months.slice(0).reverse().map((month) => (
                    <option value={month} key={month}>
                        {month}
                    </option>
                ))
                ) : months.map((month) => (
                    <option value={month} key={month}>
                        {month}
                    </option>
                ))
            }
        </Field>
        <ErrorMessage name="month" component={Warning} />
      </div>
      <div className="d-flex flex-column mr-2">
        <Field
          as="select"
          name="day"
          className={selectClasses}
          required
        >
          <option value="" disabled selected hidden>
            day
          </option>
            {sort && sort.day ?
                (sort.day === 'asc' ?
                days.map((number) => (
                    <option value={number} key={number}>
                        {number}
                    </option>
                )) :
                days.slice(0).reverse().map((number) => (
                    <option value={number} key={number}>
                        {number}
                    </option>
                ))
                ) : days.map((number) => (
                    <option value={number} key={number}>
                        {number}
                    </option>
                ))
            }
        </Field>
        <ErrorMessage name="day" component={Warning} />
      </div>
      <div className="d-flex flex-column mr-2">
        <Field
          as="select"
          name="year"
          className={selectClasses}
          required
        >
          <option value="" disabled selected hidden>
            year
          </option>
            {sort && sort.year ?
                (sort.year === 'asc' ?
                years.map((number) => (
                    <option value={number} key={number}>
                        {number}
                    </option>
                )) :
                years.slice(0).reverse().map((number) => (
                    <option value={number} key={number}>
                        {number}
                    </option>
                ))
                ) : years.map((number) => (
                    <option value={number} key={number}>
                        {number}
                    </option>
                ))
            }
        </Field>
        <ErrorMessage name="year" component={Warning} />
      </div>
    </div>
  );
}
