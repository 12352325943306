import React, {useState} from 'react';
import {ErrorMessage, Field, Formik} from 'formik';
import * as Yup from 'yup';

import logoPath from 'src/assets/svg/imagine-council-original.svg';
import {ReactComponent as Hide} from 'src/assets/svg/show_hide.svg';
import {ReactComponent as Show} from 'src/assets/svg/show.svg';

import useSnackMessages from 'src/hooks/useSnackMessages';
import UserService from 'src/services/user';
import ICModal from '../Modal';
import {useAuth} from 'src/contexts/AuthContext';

import {
    ConnectWalletButton,
    LoginBox,
    LoginContainer,
    LoginSection,
    MemberstackForm,
    Separator,
    StyledImg,
    WalletText,
    Warning
} from './StyledComponents';

const Login = ({isMobile, showSignUpModal, closeModal}) => {
    const {msgSuccess, msgWarning} = useSnackMessages();
    const {loginUser} = useAuth();
    const [showConnectWalletText, setShowConnectWalletText] =
        useState('CONNECT WALLET');
    const [showPassword, setShowPassword] = useState(false);

    const sendEmail = async (email) => {
        var inputValue = {
            email: email.toLowerCase(),
            type: 'pushuser'
        };

        let data = await fetch('/api/routes/users/forgotPassword', {
            method: 'POST',
            body: JSON.stringify(inputValue),
            headers: {'Content-Type': 'application/json'}
        });
        const response = await data.json();
        if (response?.message !== 'Success') {
            msgWarning('There has been an error! Please try again.');
        } else {
            msgSuccess(
                'If you have an account associated with us then you will see an email from us.'
            );
        }
    };

    const login = async (values) => {
        if (UserService.isEmail(values.username)) {
            values.username = values.username.toLowerCase();
        } else if (/\s+$/.test(values.username)) {
            values.username = values.username.replace(/\s+/g, '');
        }
        values.type = 'pushuser';
        loginUser(values);
    };

    return (

        <ICModal
            topClassName="ic-modal"
            imgSource={isMobile ? '' : logoPath}
            closeModal={closeModal}
        >
            <LoginBox>
                <LoginSection>
                    <LoginContainer ms-login="true">
                        {isMobile && (
                            <StyledImg src={logoPath} alt="isMobile"/>
                        )}
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                                phone: '',
                                code: ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={login}
                        >
                            {({values, errors}) => (
                                <MemberstackForm className="memberstackForm">
                                    <Field
                                        name="username"
                                        type="text"
                                        placeholder="email or username"
                                        className="login-input login-w-input"
                                    />
                                    <ErrorMessage
                                        name="username"
                                        component={Warning}
                                    />

                                    <div className="input-container">
                                        <Field
                                            name="password"
                                            placeholder="password"
                                            type={showPassword ? "text" : "password"}
                                            className="login-input login-w-input password"
                                        />
                                        {!showPassword ? <Show
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="toggle-password-visibility"
                                        /> :
                                            <Hide
                                                type="button"
                                                onClick={() => setShowPassword(!showPassword)}
                                                className="toggle-password-visibility"
                                        />}
                                    </div>

                                    <ErrorMessage
                                        name="password"
                                        component={Warning}
                                    />
                                    <div className="secondary-action">
                                        <a
                                            href="#"
                                            onClick={() => {
                                                if (
                                                    !values.username ||
                                                    (values.username &&
                                                        !values.username.includes(
                                                            '@'
                                                        ))
                                                ) {
                                                    msgWarning(
                                                        `Email is required to send you pin for resetting password`
                                                    );
                                                } else if (!errors.email) {
                                                    sendEmail(values.username);
                                                }
                                            }}
                                            className="login-link"
                                        >
                                            Reset password
                                        </a>
                                    </div>
                                    <input
                                        type="submit"
                                        value="Log In"
                                        data-wait="Please wait..."
                                        className="login-button-3 w-button"
                                    />
                                    <div className="terms-text">
                                        <div className="terms-text">
                                            Don't have account?{' '}
                                            <span
                                                onClick={showSignUpModal}
                                                className="link-14"
                                            >
                                                sign up
                                            </span>
                                        </div>
                                    </div>
                                    <Separator/>
                                    <ConnectWalletButton
                                        onClick={() => setShowConnectWalletText('COMING SOON')}
                                    >
                                        {showConnectWalletText}
                                    </ConnectWalletButton>
                                    <WalletText>
                                        Must have Data Identity Token
                                        (DiT) to connect your wallet.{' '}
                                    </WalletText>
                                </MemberstackForm>
                            )}
                        </Formik>
                    </LoginContainer>
                </LoginSection>
            </LoginBox>
        </ICModal>
    );
};

const validationSchema = Yup.object().shape({
    username: Yup.string().required('Required field!'),
    password: Yup.string().min(8, 'Too Short!').required('Required field!')
});

export default Login;
