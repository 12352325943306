import React from 'react';
import styled from 'src/theme/styled';
import Login from 'src/components/common/AuthModals/Login';
import Signup from 'src/components/common/AuthModals/Signup';
import ResetPassword from 'src/components/common/ResetPassword/ResetPassword';
import { useSettings } from 'src/contexts/SettingsContext';

const LogInButton = styled.button<{ isMobile: boolean }>`
    background: ${(({ isMobile }) => isMobile ? "var(--primary-transparent-35)" : "#000" )}; 
    border-radius: 5px;
    width: auto;
    padding: 8px 18px;
    color: white;
    border: none;
    box-shadow: none;
    cursor: pointer;
    height: 30px;
    align-items: center;
    display: flex;
    font-size: 11px;
`;

function MainEnterPoint() {
    const { 
        isMobile, 
        showLogin, 
        setShowLogin, 
        showSignUp, 
        setShowSignUp,
        showResetPass,
        setShowResetPass 
    } = useSettings();
    return (
        <React.Fragment>
            <LogInButton isMobile={isMobile} onClick={() => setShowSignUp(true)}>JOIN</LogInButton>
            {showLogin && (
                <Login
                    isMobile={isMobile}
                    closeModal={() => setShowLogin(false)}
                    showSignUpModal={() => {
                        setShowLogin(false);
                        setShowSignUp(true);
                    }}
                />
            )}
            {showResetPass && 
                <ResetPassword 
                    isMobile={isMobile}
                    closeModal={() => setShowResetPass(false)}
                />
            }
            {showSignUp && (
                <Signup
                    isMobile={isMobile}
                    closeModal={() => setShowSignUp(false)}
                    showLoginModal={() => {
                        setShowLogin(true);
                        setShowSignUp(false);
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default MainEnterPoint;
