import React from 'react';
import PATHS from 'src/routing/pathNames';
import { ReactComponent as ImagineCouncilIcon } from 'src/assets/svg/imagine-council.svg';
import { ReactComponent as Profile } from 'src/assets/portals/imagine/imagineCouncil.svg';
import { ReactComponent as GoborbBannerIcon } from 'src/assets/portals/goborb/goborb-icon.svg';
import { ReactComponent as ImagineToken } from 'src/assets/portals/imagine/imagine-token.svg';
import { ReactComponent as LightningBolt } from 'src/assets/svg/lightning-Icon.svg';
import PointsBanner from 'src/assets/portals/points/points-banner.png';

import { ReactComponent as PortalIcon1 } from 'src/assets/portals/datastream.svg';
import { ReactComponent as BuildersIcon } from 'src/assets/portals/imagine/imagine-builders-icon.svg';
import { ReactComponent as SocietyIcon } from 'src/assets/portals/imagine/society_icon.svg';
import { ReactComponent as LoggedOutIcon } from 'src/assets/portals/imagine/imagine-icon-logged-out.svg';
import { ReactComponent as GamePlayIcon } from 'src/assets/game/game-play-icon.svg';
// import { ReactComponent as GameIcon } from 'src/assets/game/games-icon.svg';
import { ReactComponent as GameToken } from 'src/assets/game/games-token.svg';
// import { ReactComponent as TokenIcon } from 'src/assets/svg/token-icon.svg';
import GameBanner from 'src/assets/portals/drrt/ftc_token.png';
// import { ReactComponent as Ranks } from 'src/assets/svg/ranks.svg';
// import { ReactComponent as Lamp } from 'src/assets/svg/lamp.svg';
// import { ReactComponent as Inbox } from 'src/assets/svg/inbox.svg';

import ImagineBanner from 'src/assets/portals/imagine/imagine-banner.png';
import ProfileBanner from 'src/assets/svg/account.svg';

import portalTypes from './portalTypes';

// import BisneyBanner from '../assets/portals/bisney/bisney-banner-white.png';

export const otherPortals = [
    {
        key: 'points',
        name: 'points',
        base: '/points',
        showWhenLoggedOut: false,
        link: '/points',
        mobileLink: '/points',
        icon: <LightningBolt />,
        footerIcons: [],
        type: portalTypes.POINTS,
        scroll: {
            background: '#964727',
            thumb: '#FDD6C6'
        },
        banner: {
            icon: <LightningBolt />,
            background: '#E0944E',
            color: '#000',
            image: PointsBanner,
            title: 'Points',
            subtitle: 'Recource Portal',
            description: 'YOUR VALUE REWARDED'
        }
    },
    {
        key: 'ftc',
        name: 'ftc',
        showWhenLoggedOut: false,
        link: '/ftc',
        base: '/ftc',
        mobileLink: '/ftc',
        icon: <GamePlayIcon />,
        footerIcons: [
            // {
            //     showAlways: true,
            //     icon: <TokenIcon />,
            //     path: '/ftc/drrt/chapter1'
            // },
            // {
            //     showAlways: false,
            //     showWhenGame: true,
            //     icon: <Lamp />,
            //     path: PATHS.IDEAS
            // },
            // {
            //     showAlways: false,
            //     showWhenGame: true,
            //     icon: <Inbox />,
            //     path: PATHS.VOTING
            // },
            // {
            //     showAlways: true,
            //     showWhenGame: true,
            //     icon: <Ranks />,
            //     path: PATHS.RANKS
            // }
        ],
        type: portalTypes.GAME,
        scroll: {
            background: '#E5E5E5',
            thumb: '#000'
        },
        banner: {
            icon: <GamePlayIcon />,
            token: <GameToken />,
            background: '#3300FF',
            color: '#fff',
            image: GameBanner,
            title: 'FREE THE CARTOON // CHAPTER 1',
            description: 'DIGITAL ROYALTY RIGHTS TOKEN'
        }
    },
    {
        key: 'landing', //тут
        name: 'imagine council',
        showWhenLoggedOut: true,
        link: '/',
        base: '/',
        mobileLink: '/',
        icon: <ImagineCouncilIcon />,
        footerIcons: [
            {
                showAlways: true,
                icon: <BuildersIcon />,
                path: (pathname) => {
                    return pathname === '/landing/data'
                        ? '/landing/data'
                        : '/landing/promo';
                }
            },
            {
                showAlways: true,
                icon: <LoggedOutIcon />,
                path: () => '/landing/feed'
            },
            {
                showAlways: false,
                showWhenLogged: true,
                icon: <SocietyIcon />,
                path: () => '/landing/society'
            }
        ],
        type: portalTypes.MAIN,
        scroll: {
            background: '#E5E5E5',
            thumb: '#000'
        },
        banner: {
            token: <ImagineToken />,
            icon: <GoborbBannerIcon />,
            background:
                'linear-gradient(180deg, var(--primary-favorite-color) 0%, #FF00B8 100%)',
            color: '#fff',
            image: ImagineBanner,
            title: <b>Imagine Council</b>,
            subtitle: '',
            description: 'PRODUCING THE GLOBE’S IMAGINATION',
            message: 'Message from Gob and Orb'
        }
    },
    {
        key: 'profile',
        name: 'society',
        showWhenLoggedOut: false,
        link: '/profile',
        base: '/profile',
        mobileLink: '/profile',
        icon: <Profile />,
        title: 'Profile',
        type: portalTypes.PROFILE,
        footerIcons: [
            {
                showAlways: true,
                icon: <PortalIcon1 />,
                path: () => PATHS.PROFILE
            }
        ],
        background: '#b298dc',
        color: '#000000',
        image: ProfileBanner,
        scroll: {
            background: '#E3854C',
            thumb: '#E7E600'
        },
        banner: {
            datastream: 'NO DATASTREAMS'
        }
    }
];
