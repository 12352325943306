import React from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

interface IProps {
  children: any;
  className?: string;
  disabled?: boolean;
  color?: 'default' | 'red' | 'yellow';
  handleClick?: any;
}

export default function Button({
  children,
  className,
  disabled,
  color = 'default',
  handleClick,
}: IProps) {
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.red]: color === 'red',
        [styles.yellow]: color === 'yellow',
      })}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}
