import styled from 'src/theme/styled';
import { keyframes } from '@emotion/core';
import storyHead from '../../../assets/images/story_head.png';
import chatMessageType from '../../../constants/chatTypes';
import coreTheme from 'src/theme/CoreTheme/coreTheme';

export const MessageContainer = styled.div<{ isMyOwn: boolean }>`
    display: flex;
    float: ${({ isMyOwn }) => (isMyOwn ? `width: 100%;justify-content: flex-end;` : '')};
`;

export const PhoneMessageContainer = styled(MessageContainer)`
    flex-direction: column;
`

export const SystemMessageContainer = styled.div`
    display: flex;
`;
export const UserMessageContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`

export const Avatar = styled.div<{
  messageLinkSeen: boolean;
  chatGameType: any;
  isMyOwn: boolean;
  image: string;
}>`
    height: 40px;
    min-width: 40px;
    border-radius: 100%;
    background-color: #999;
    ${(props) => props.chatGameType === chatMessageType.LINK_MESSAGE && !props.messageLinkSeen
    ? 'cursor: pointer'
    : 'filter: grayscale(100%); cursor: auto'};
    background: ${({ image, isMyOwn }) => isMyOwn
    ? `url(${image}) center center no-repeat;`
    : `url(${storyHead}) center center no-repeat;`}
        ${({ isMyOwn }) => isMyOwn ? `margin-left: 8px;` : `margin-right: 8px`};
    background-size: cover;
`;

const MessageBox = styled.div`
    border-radius: 8px;
    padding: 10px;
    display: flex;
    border: 1px solid;
    background-color: #fff;
`;

export const ReceivedImg = styled.img`
    width: 40px;
    height: 40px;  
`

export const RecievedMessageBox = styled(MessageBox) <{
  messageLinkSeen: boolean;
  chatGameType: any;
}>`
    color: black;
    &:hover {
        ${(props) => props.chatGameType === chatMessageType.LINK_MESSAGE && !props.messageLinkSeen
    ? 'background: #FDDE47; cursor: pointer'
    : 'cursor: default'};
    }
`;

export const ObjectsBox = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    padding: 10px;
    width: 100%;
`;

export const Input = styled.input`
    width: 100%;
    height: 50px;
    margin-left: 48px;
    min-width: 150px;
    margin-top: 8px;
    margin-bottom: 8px;
    outline: none !important;
    border: 1px solid #000000;
    border-radius: 6px;
    padding: 10px;
`;

export const ObjectImg = styled.img`
    height: 60px;
    width: 60px;
    cursor: pointer;
    margin-top: 10px;
    :nth-of-type(1) {
        margin-left: 0px;
    }
    :nth-of-type(4) {
        margin-left: 0px;
    }
    :nth-of-type(7) {
        margin-left: 0px;
    }
`;

export const SentMessageBox = styled(MessageBox)<any>`
    color: ${(props) => props.colorToUse || 'black'};
    border: none;
    background-color: ${({ favoriteColor, most_popular_color }) => favoriteColor ? favoriteColor : most_popular_color};
`;

export const SentMessageBoxImg = styled(MessageBox) <any>`
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    color: white;
    width: 40px;
    height: 40px;
    display: block;
    padding: 0;
`;

export const SubmitButton = styled.button<{ favoriteColor: string }>`
    background: ${({ favoriteColor }) => favoriteColor && favoriteColor !== coreTheme.colors.white
    ? favoriteColor
    : ''};
    margin-left: 5px !important;
    margin: auto;
    height: 50px;
    border: 1px solid;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: normal;
    display: flex;
    font-size: 18px;
    color: ${({ favoriteColor }) => favoriteColor && favoriteColor !== coreTheme.colors.white && favoriteColor !== coreTheme.colors.yellow
    ? 'white'
    : 'black'};
`;

export const PhoneSubmitButton = styled(SubmitButton)`
    margin-left: auto !important;
    margin-right: 0 !important;
`

export const CenteredSpan = styled.span`
    margin: auto;
`;

const typing = keyframes`
    0% {
        transform: scale(1);
    }
    33% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.4);
    }
    100% {
        transform: scale(1);
    }
    
`;

export const Circle = styled.div`
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #757575;
    margin: 3px;
    animation: ${typing} 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
    :nth-of-type(1) {
        animation-delay: 0ms;
    }
    :nth-of-type(2) {
        animation-delay: 333ms;
    }
    :nth-of-type(3) {
        animation-delay: 666ms;
    }
`;

export const MessageTimestamp = styled.p<{ isMyOwn: boolean }>`
    opacity: 0.5;
    font-size: 10px;
    display: flex;
    align-items: center;
    ${(props) => props.isMyOwn
    ? 'margin-right: 5px; padding: 4px;height: 44px;margin-bottom: 0px;'
    : 'margin-left: 48px;margin-top: 5px;'};
`;

export const ErrorMessage = styled.p<{ isMyOwn: boolean }>`
    opacity: 0.5;
    font-size: 10px;
    color: red;
    display: flex;
    align-items: center;
    ${(props) => props.isMyOwn
    ? 'margin-right: 5px; padding: 4px;height: 44px;margin-bottom: 0px;'
    : 'margin-left: 48px;margin-top: 5px;'};
`;

export const Typing = styled.div`
    display: block;
    width: 60px;
    height: 40px;
    background-color: #bdbdbd;
    margin-left: 20px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageContainer = styled.button`
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1.5em;
    text-align: center;
    flex: 1 0 auto;
    height: auto;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: row;
`;

export const ObjectImgText = styled.p`
    font-family: Nunito, sans-serif;
    font-size: 12px;
    text-align: center;
    color: #000000;
`;

export const GridWrapperDiv = styled.div`
    display: grid;
    grid-gap: 10px;
`