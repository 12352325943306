import React from 'react';
import { useParams } from 'react-router-dom';
import logoPath from 'src/assets/svg/imagine-council-original.svg';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ICModal from '../Modal';
import { useAuth } from 'src/contexts/AuthContext';

const ResetPassBox = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #fff;
    align-items: center;
    border: 1px solid #223b95;
    border-radius: 20px;
`;
const ResetPassSection = styled.div`
    display: flex;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    font-size: 16px;
`;
const MemberstackForm = styled(Form)`
    position: relative;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const ResetPassContainer = styled.div`
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Warning = styled.div`
    color: red;
    margin-bottom: 15px;
    margin-top: 0px;
    max-width: 247px;
    font-size: 10px;
`;

const StyledImg = styled.img`
    height: 70px;
    width: 70px;
`;

const ResetPassword = ({ isMobile, closeModal }) => {
    const { resetPassword } = useAuth();
    const params = useParams();

    const resetPass = async (values) => {
        values.type = 'pushuser';
        values.id = params.id;
        resetPassword(values);
    };

    return (
        <ICModal
            topClassName="ic-modal"
            imgSource={isMobile ? '' : logoPath}
            closeModal={closeModal}
        >
            <ResetPassBox>
                <ResetPassSection>
                    <ResetPassContainer ms-login="true">
                        {isMobile && (
                            <StyledImg src={logoPath} alt="isMobile" />
                        )}
                        <Formik
                            initialValues={{
                                password: '',
                                passwordRepeat: ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={resetPass}
                        >
                            {({ values, errors }) => (
                                <MemberstackForm className="memberstackForm">
                                    <Field
                                        name="password"
                                        type="password"
                                        placeholder="password"
                                        className="login-input login-w-input"
                                    />
                                    <ErrorMessage
                                        name="password"
                                        component={Warning}
                                    />

                                    <Field
                                        name="passwordRepeat"
                                        placeholder="repeat password"
                                        type="password"
                                        className="login-input login-w-input"
                                    />
                                    <ErrorMessage
                                        name="passwordRepeat"
                                        component={Warning}
                                    />
                                    <input
                                        type="submit"
                                        value="Reset password"
                                        data-wait="Please wait..."
                                        className="login-button-3 w-button"
                                    />
                                </MemberstackForm>
                            )}
                        </Formik>
                    </ResetPassContainer>
                </ResetPassSection>
            </ResetPassBox>
        </ICModal>
    );
};

const validationSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Too Short!').required('Required field!'),
    passwordRepeat: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords dont match')
        .required('Password confirm is required')
});

export default ResetPassword;
