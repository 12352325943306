import React from 'react';
import { connect } from 'react-redux';
import Switch from './Switch';
import { setShowUsd } from 'src/store/actions/app';

function MoneySwitch({showUsd, setShowUsd, color }) {
    return (
        <Switch
            size='sm'
            color={color}
            value={showUsd}
            valueChanged={setShowUsd}
        />
    );
}

const mapStateToProps = state => ({
    showUsd: state.app.showUsd,
});

export default connect(
    mapStateToProps,
    { setShowUsd }
)(MoneySwitch);
