import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILayout } from './layoutTypes';

let layout: any = null;
let temp: any = localStorage.getItem('desktop-layout');
if (temp !== null) {
    layout = JSON.parse(temp);
}

if (!layout) {
    localStorage.setItem(
        'desktop-layout',
        JSON.stringify({
            right: {
                tokens: true,
                checkout: false
            },
            chat: false
        })
    );
}
const initialState: ILayout = layout;

const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState,
    reducers: {
        setDesktopLayoutRight(state, action: PayloadAction<any>) {
            let state_layout: any = null;
            let temp: any = localStorage.getItem('desktop-layout');
            if (temp !== null) {
                state_layout = JSON.parse(temp);
            }
            state_layout.right = action.payload;
            localStorage.setItem(
                'desktop-layout',
                JSON.stringify(state_layout)
            );
            return {
                ...state,
                right: action.payload,
                chat: false
            };
        },
        setChatLayout(state, action: PayloadAction<any>) {
            let state_layout: any = null;
            let temp: any = localStorage.getItem('desktop-layout');
            if (temp !== null) {
                state_layout = JSON.parse(temp);
            }
            state_layout.chat = action.payload;
            state_layout.right = {
                tokens: false,
                checkout: false
            };
            localStorage.setItem(
                'desktop-layout',
                JSON.stringify(state_layout)
            );
            return {
                ...state,
                right: {
                    tokens: false,
                    checkout: false
                },
                chat: action.payload
            };
        },
        logOut(state) {
            let state_layout: any = null;
            let state_temp: any = localStorage.getItem('desktop-layout');
            if (state_temp !== null) {
                state_layout = JSON.parse(state_temp);
            }
            const rightSide = {
                token: false,
                cart: false
            };
            state_layout.right = rightSide;
            state_layout.chat = false;
            localStorage.setItem(
                'desktop-layout',
                JSON.stringify(state_layout)
            );
          
        }
        // logOut(state, action: PayloadAction<any>) {
        //     let state_layout: any = null;
        //     let temp: any = localStorage.getItem('desktop-layout');
        //     if (temp !== null) {
        //         state_layout = JSON.parse(temp);
        //     }
        //     const rightSide = {
        //         token: false,
        //         cart: false
        //     };
        //     state_layout.right = rightSide;
        //     state_layout.chat = false;
        //     localStorage.setItem('desktop-layout', JSON.stringify(state_layout));
        //     return {
        //         ...state,
        //         right: rightSide,
        //         chat: false
        //     };
        // }
    }
});

export const { setDesktopLayoutRight } = layoutSlice.actions;
export default layoutSlice.reducer;
