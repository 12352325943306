import styled from 'src/theme/styled';

export const Tools = styled.div`
    padding: 30px;
    display: flex;
    width: 100%;
`;

export const TokenType = styled.div`
    background: #fafc3f;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2px 6px;
    font-size: 14px;
    color: #000000;
    width: fit-content;
    white-space: nowrap;
`;

export const Tabs = styled.div`
    display: flex;
`;

export const Tab = styled.div<{ active?: boolean }>`
    cursor: pointer;
    border-bottom: ${({ active }) => (active ? '2px solid #000000' : 'none')};
    margin-left: 20px;
`;

export const PlainText = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;
