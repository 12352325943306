import React from "react";

const DeliveryInfo = ({ deliveryInfo, className }) => {
  return (
    <div className={className}>
      <div>{deliveryInfo.firstName} {deliveryInfo.lastName}</div>
      <div>{deliveryInfo.streetNumber} {deliveryInfo.street}</div>
      <div>{deliveryInfo.city}, {deliveryInfo.state} {deliveryInfo.zip}</div>
      <div>{deliveryInfo.email}</div>
      <div>{deliveryInfo.phone}</div>
    </div>
  );
};

export default DeliveryInfo;
