import React from 'react';
import styled from 'src/theme/styled';

import { ReactComponent as LinkIcon } from 'src/assets/svg/link.svg';
import { getEtherscanLink } from 'src/utils/url';

interface IProps {
    className?: string;
    address: string;
    color?: string;
    iconColor?: string;
    short?: boolean;
}

export const PlainText = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledLinkIcon = styled(LinkIcon)`
    height: 22px;
    width: 22px;
    margin-left: 8px;
    vertical-align: top;
`;

export default function EthAddress({
    className,
    address,
    color = '#4F4FFF',
    iconColor = '#000000',
    short
}: IProps) {
    const makeShorter = (address: string) => {
        const first = address?.substring(0, 3);
        const last = address?.substring(address.length - 8, address.length - 1);
        return `${first}...${last}`;
    };

    return !address ? <div>{'—'}</div> : (
        <PlainText
            className={className}
            style={{ color: color, cursor: 'pointer' }}
            onClick={() =>
                window.open(
                    `${getEtherscanLink()}/address/${address}`,
                    '_blank'
                )?.focus()
            }
        >
            {short ? makeShorter(address) : address}
            <StyledLinkIcon fill={iconColor} style={{ fill: iconColor }} />
        </PlainText>
    );
}
