import React from 'react';
import { ReactComponent as GoodIcon } from 'src/assets/portals/icons/goodmunchie_symbol.svg';
import { ReactComponent as GoodToken } from 'src/assets/portals/goodmunchie/portal_symbols_good_munchie.svg';
import GoodBanner from '../assets/portals/goodmunchie/goodmunchie-banner.png';

import { ReactComponent as GfIcon } from 'src/assets/portals/icons/goniaface_symbol.svg';
import { ReactComponent as GfToken } from 'src/assets/portals/goniaface/portal_symbol_goniaface.svg';
import GfBanner from '../assets/portals/goniaface/gf-banner.png';

import { ReactComponent as CulvarIcon } from 'src/assets/portals/icons/culvar_symbol.svg';
import { ReactComponent as CulvarToken } from 'src/assets/portals/culvar/portal_symbol_culvar.svg';
import CulvarBanner from '../assets/portals/culvar/culvar_artwork.png';

import { ReactComponent as BisneyIcon } from 'src/assets/portals/icons/aftayesta_symbol.svg';
import { ReactComponent as BisneyToken } from 'src/assets/portals/bisney/portal_symbol_afta_yesta.svg';
import { ReactComponent as BisneyBannerIcon } from 'src/assets/portals/bisney/portal_symbol_afta_yesta.svg';
import BisneyBanner from '../assets/portals/bisney/altayesta_banner.png';

import { ReactComponent as AnIcon } from 'src/assets/portals/icons/airnidas_symbol.svg';
import { ReactComponent as AnToken } from 'src/assets/portals/airnidas/portal_symbol_air_nidas.svg';
import AnBanner from '../assets/portals/airnidas/airnidas_banner.png';

import { ReactComponent as SfIcon } from 'src/assets/portals/icons/supfooku_symbol.svg';
import { ReactComponent as SfToken } from 'src/assets/portals/superfun/portal_symbols_supfooku.svg';
import SfBanner from '../assets/portals/superfun/supfooku_banner.png';

import { ReactComponent as ExperienceIcon1 } from 'src/assets/svg/lamp.svg';
import { ReactComponent as ExperienceIcon2 } from 'src/assets/portals/experience-portal-icon-end.svg';


import aftaYestaChapterToken from 'src/assets/portals/chapter0/aftayesta_token.png';
import airnidasChapterToken from 'src/assets/portals/chapter0/airnidas_token.png';
import culvarChapterToken from 'src/assets/portals/chapter0/culvar_token.png';
import goniafaceChapterToken from 'src/assets/portals/chapter0/goniaface_token.png';
import goodmunchieChapterToken from 'src/assets/portals/chapter0/goodmunchie_token.png';
import supfookuChapterToken from 'src/assets/portals/chapter0/supfooku_token.png';

import portalTypes from './portalTypes';

const getFooterIcons = (link1, link2) => {
    return [
        // {
        //     showAlways: true,
        //     icon: <ExperienceIcon1 />,
        //     path: link1
        // }, {
        //     showAlways: true,
        //     icon: <ExperienceIcon2 />,
        //     path: link2
        // }
    ];
};

export const ExperiencePortals = [
    {
        key: 'airnidas',
        name: 'air nidas',
        link: '/airnidas',
        url: 'https://airnidas.com',
        base: '/airnidas',
        mobileLink: '/airnidas',
        icon: <AnIcon />,
        showWhenLoggedOut: true,
        footerIcons: getFooterIcons(
            '/airnidas/airftc',
            '/airnidas/airftc/starter'
        ),
        type: portalTypes.EXPERIENCE,
        scroll: {
            background: '#0d9917',
            thumb: '#8bff93'
        },
        banner: {
            token: <AnToken />,
            icon: <AnIcon />,
            background: '#C3B091',
            color: '#000',
            image: AnBanner,
            title: 'Air Nidas (Air)',
            subtitle: 'Brand Portal',
            description: 'ALL THINGS TRANSPO'
        },
        tokens: [{
            icon: airnidasChapterToken,
        }]
    },
    {
        key: 'aftayesta',
        name: 'afta yesta',
        link: '/aftayesta',
        url: 'https://aftayesta.com',
        base: '/aftayesta',
        mobileLink: '/aftayesta',
        icon: <BisneyIcon />,
        showWhenLoggedOut: true,
        footerIcons: getFooterIcons(
            '/aftayesta/bisneyftc',
            '/aftayesta/bisneyftc/starter'
        ),
        type: portalTypes.EXPERIENCE,
        scroll: {
            background: '#0d9917',
            thumb: '#8bff93'
        },
        banner: {
            token: <BisneyToken />,
            icon: <BisneyIcon />,
            background: '#800080',
            color: '#FFFFFF',
            image: BisneyBanner,
            title: 'AftaYesta (AY)',
            subtitle: 'Brand Portal',
            description: 'COLLECTION OF PAST FOR FUTURE'
        },
        tokens: [{
            icon: aftaYestaChapterToken,
        }]
    },
    {
        key: 'supfooku',
        name: 'supfooku',
        link: '/supfooku',
        url: 'https://supfooku.com',
        base: '/supfooku',
        mobileLink: '/supfooku',
        icon: <SfIcon />,
        showWhenLoggedOut: true,
        footerIcons: getFooterIcons(
            '/supfooku/supfookuftc',
            '/supfooku/supfookuftc/starter'
        ),
        type: portalTypes.EXPERIENCE,
        scroll: {
            background: '#0d9917',
            thumb: '#8bff93'
        },
        banner: {
            token: <SfToken />,
            icon: <SfIcon />,
            background: '#FFA500',
            color: '#000',
            image: SfBanner,
            title: 'Supfooku (Sup)',
            subtitle: 'Brand Portal',
            description: 'EXPERIENTIAL THEATRE'
        },
        tokens: [{
            icon: supfookuChapterToken,
        }]
    },
    {
        key: 'culvar',
        name: 'culvar',
        link: '/culvar',
        base: '/culvar',
        url: 'https://culvar.com',
        mobileLink: '/culvar',
        icon: <CulvarIcon />,
        showWhenLoggedOut: true,
        footerIcons: getFooterIcons(
            '/culvar/culvarftc',
            '/culvar/culvarftc/starter'
        ),
        type: portalTypes.EXPERIENCE,
        scroll: {
            background: '#0d9917',
            thumb: '#8bff93'
        },
        banner: {
            token: <CulvarToken />,
            icon: <CulvarIcon />,
            background: '#F7F702',
            color: '#000000',
            image: CulvarBanner,
            title: 'Culvar (C)',
            subtitle: 'Brand Portal',
            description: 'COMICBOOK-NEWSPAPER-MAGAZINE'
        },
        tokens: [{
            icon: culvarChapterToken,
        }]
    },
    {
        key: 'goniaface',
        name: 'Goniaface',
        showWhenLoggedOut: true,
        link: '/goniaface',
        base: '/goniaface',
        url: 'https://goniaface.com',
        mobileLink: '/goniaface',
        icon: <GfIcon />,
        footerIcons: getFooterIcons(
            '/goniaface/goniafaceftc',
            '/goniaface/goniafaceftc/starter',
        ),
        type: portalTypes.EXPERIENCE,
        scroll: {
            background: '#E5E5E5',
            thumb: '#000'
        },
        banner: {
            token: <GfToken />,
            icon: <GfIcon />,
            background: 'linear-gradient(180deg, #00848C 44.27%, #0038FF 100%)',
            color: '#fff',
            image: GfBanner,
            title: 'Goniaface (GF)',
            subtitle: 'Brand Portal',
            description: 'LIFESTYLE & ACTIVITIES'
        },
        tokens: [{
            icon: goniafaceChapterToken,
        }]
    },
    {
        key: 'goodmunchie',
        name: 'Goodmunchie',
        showWhenLoggedOut: true,
        link: '/goodmunchie',
        url: 'https://goodmunchie.com',
        base: '/goodmunchie',
        mobileLink: '/goodmunchie',
        icon: <GoodIcon />,
        footerIcons: getFooterIcons(
            '/goodmunchie/goodftc',
            '/goodmunchie/goodftc/starter',
        ),
        type: portalTypes.EXPERIENCE,
        scroll: {
            background: '#E5E5E5',
            thumb: '#000'
        },
        banner: {
            token: <GoodToken />,
            icon: <GoodIcon />,
            background: '#3300FF',
            color: '#fff',
            image: GoodBanner,
            title: 'Goodmunchie (Good)',
            subtitle: 'Brand Portal',
            description: 'GOODS AND MUNCHIES GIVING VIBES IN ARTSY PACKAGING'
        },
        tokens: [{
            icon: goodmunchieChapterToken,
        }]
    }
];
