import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useFetch from 'src/services/useFetch';
import DeliveryInfo from "../DeliveryInfo";

import styles from "./StepMobile3.module.scss"

const { get: fetchClosestNewMoon } = useFetch('/api/routes/imagine/getClosestNewMoon');

const StepMobile3 = ({
  goNext,
  editStep,
  deliveryInfo,
  deliveryOption: deliveryOptionProp,
  hasDrrt
}) => {
  const [deliveryOption, setDeliveryOption] = useState(deliveryOptionProp || "hold")
  const [deliveryDate, setDeliveryDate] = useState('');

  useEffect(() => {
    fetchClosestNewMoon().then((response) => setDeliveryDate(response?.newMoon));
  }, []);

  return (
    <div className={styles.step}>
      <div className={styles.container}>
        <div className={styles.headerWrapper}>
          <span className={styles.info}>
            <DeliveryInfo deliveryInfo={deliveryInfo} />
          </span>
          <span
            onClick={() => editStep(0)}
            className={styles.edit}
          >
            edit
          </span>
        </div>
        <div className={styles.content}>
          <div className={styles.section} onClick={() => setDeliveryOption("hold")}>
            <span
              className={`${styles.toggle} ${deliveryOption === "hold" ? styles.active : ""}`}
            />
            <ul className={styles.list}>
              <b className={styles.subtitle}>Hold Physical Product(s)</b>
              <li className={styles.text}>Preserve token's original value.</li>
              <li className={styles.text}>Redeemable within one year of purchase.</li>
              <li className={styles.text}>Ships and arrives within 2 to 3 business days from the story release date.</li>
              <li className={styles.text}>Release dates happen each quarter.</li>
            </ul>
          </div>
          <div className={styles.section}>
            <span
              onClick={() => setDeliveryOption("Deliver")}
              className={`${styles.toggle} ${deliveryOption === "Deliver" ? styles.active : ""}`}
            />
            <ul className={styles.list}>
              <b className={styles.subtitle}>Ship Physical Product(s).</b>
              <li className={styles.text}>Ships and arrives within 2 to 3 business days from the story release date.</li>
              <li className={styles.text}>Release dates happen each quarter</li>
            </ul>
          </div>
          <button onClick={() => goNext(deliveryOption)} className={styles.enter}>
            enter
          </button>
        </div>
      </div>
    </div>
  );
};

StepMobile3.propTypes = {
  goNext: PropTypes.func.isRequired,
  editStep: PropTypes.func.isRequired,
  deliveryInfo: PropTypes.object.isRequired
};

export default StepMobile3
