import React from 'react';
import { connect } from 'react-redux';
import styled from 'src/theme/styled';
import ChatMessage from 'src/components/common/Chat/ChatMessage';
import { Messages, HistoryMessageType } from './ChatTypes';
import { Paragraph4 } from 'src/components/common/ui/Typography';
import Page from 'src/components/common/Page';
import fieldType from 'src/constants/fieldType';

type Props = {
  colors: any[],
  hubs: any[],
  creativities: any[],
  isMobile: boolean;
  messages: Messages;
  verifiedEmail: boolean;
  onboarding?: boolean;
  handleUserOnboarded: () => void;
  sendVerifyEmail: () => void;
  sendVerifyPhone: () => void;
  handleLinkSeen: (messageId: string) => void;
  sendAnswer: (messageValue: string, node: string) => void;
  noMoreMessages: boolean;
};

const ChatPage = styled(Page)<{ isMobile: boolean }>(({ isMobile }) => ({
  alignItems: 'center',
  paddingLeft: '10px',
  width: '100%',
  minHeight: '100%',
  height: '100%',
  padding: '16px 10px',
  paddingTop: isMobile ? '0px' : '60px'
}));

const SendMessagesDiv = styled.div`
    display: flex;
    flex: 0 1 auto;
    margin: 12px 0;
`;
const MessagesList = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1;
    overflow-x: hidden;
`;

const MessageRow = styled.div<{ isMyOwn: boolean }>`
    display: table-column
    flex: 0 1 auto;
    margin: 8px 0px;
    ${({ isMyOwn }) => (isMyOwn ? 'justify-content: flex-end;' : '')}
`;

let clickTimeout: any = null;

interface SendAnswerProps {
  name: string;
  fieldType: string;
}

const ChatComponent = ({
  isMobile,
  onboarding,
  messages,
  handleUserOnboarded,
  sendVerifyEmail,
  sendVerifyPhone,
  sendAnswer,
  handleLinkSeen,
  verifiedEmail,
  noMoreMessages,
  colors,
  hubs,
  creativities
}: Props) => {
  const handleSendAnswer = (values: SendAnswerProps) => {
    // prevent double click
    clearTimeout(clickTimeout);
    clickTimeout = setTimeout(() => {
      sendAnswer(values.name, values.fieldType);
    }, 500);
  };
  const asnwersSet = {
    hub: messages?.some((m) => hubs.map((h: any) => h.name).indexOf(m.message) >= 0),
    color: messages?.some((m) => colors.map((c: any) => c.name).indexOf(m.message) >= 0),
    creative: messages?.some((m) => creativities.map((c: any) => c.name).indexOf(m.message) >= 0),
    imagination: messages?.some(m => m.isMyOwn && m.field === fieldType.IMAGINATION),
    phone: messages?.some(m => m.isMyOwn && m.field === fieldType.PHONE),
    phoneVerified: messages?.some(m => m.isMyOwn && m.field === fieldType.PHONE_VERIFICATION)
  }

  return (
    <ChatPage isMobile={isMobile}>
      <div className={`d-flex flex-column justify-content-center`}>
        <MessagesList>
          {messages?.map(({
            isMyOwn,
            message,
            _id,
            picture,
            chatGameType,
            field,
            seen,
            messageCreationDate,
            link
          }: HistoryMessageType) => (
            <MessageRow isMyOwn={isMyOwn} key={_id}>
              <ChatMessage
                key={_id}
                handleUserOnboarded={handleUserOnboarded}
                handleLinkSeen={handleLinkSeen}
                asnwersSet={asnwersSet}
                onboarded={onboarding}
                userVerifiedEmail={verifiedEmail}
                sendVerifyEmail={sendVerifyEmail}
                sendVerifyPhone={sendVerifyPhone}
                handleSendAnswer={handleSendAnswer}
                message={{
                  message,
                  picture,
                  isMyOwn,
                  _id,
                  chatGameType,
                  field,
                  seen,
                  messageCreationDate,
                  link
                }}
              />
            </MessageRow>
          ))}
          {onboarding && noMoreMessages && (
            <SendMessagesDiv>
              <Paragraph4 textAlign="center" fontWeight="bold">
                No action available at this moment
              </Paragraph4>
            </SendMessagesDiv>
          )}
        </MessagesList>
      </div>
    </ChatPage>
  );
};

const mapStateToProps = (state: any) => ({
  creativities: state.app.globalStats.creativities,
  colors: state.app.globalStats.colors,
  hubs: state.app.globalStats.hubs
});

export default connect(mapStateToProps)(ChatComponent);
