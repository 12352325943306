import React from 'react';

const useFetch = (endpoint) => {
    const defaultHeader = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const customFetch = (
        url,
        method = 'GET',
        body = false,
        headers = defaultHeader
    ) => {
        const options = {
            method,
            credentials: 'include',
            headers
        };
        if (body) options.body = JSON.stringify(body);

        return fetch(url, options)
            .then((response) => response.json())
            .then((body) => body)
            .catch((err) => {
                console.error(err);
                return false;
            });
    };
    const get = (id) => {
        const url = `${endpoint}${id ? `/${id}` : ``}`;

        return customFetch(url);
    };
    const post = (body = null) => {
        if (!body)
            throw new Error('to make a post you must provide a     body');
        return customFetch(endpoint, 'POST', body);
    };
    const put = (body = null) => {
        return customFetch(endpoint, 'PUT', body);
    };

    return {
        get,
        post,
        put
    };
};
export default useFetch;

export const useFetchHook = (url, options) => {
    const [response, setResponse] = React.useState(null);
    const [error, setError] = React.useState(null);
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetch(url, options);
                const json = await res.json();
                setResponse(json);
            } catch (error) {
                setError(error);
            }
        };
        fetchData();
    }, [options, url]);
    return { response, error };
};
