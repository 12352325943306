import React from 'react';
import styled from 'src/theme/styled';
import defaultImage from 'src/assets/characters/default.png';
import { ReactComponent as TokenArrow } from 'src/assets/svg/token-arrow.svg';
import tokenTypes from 'src/constants/tokenTypes';
import { Tab, Tabs, Tools } from './shared';

interface IProps {
    isMobile: boolean;
    tokens: any[];
    tokenActivated: Array<boolean>;
    setToken: (token: any) => void;
    setActiveTokens: (type: string) => void;
    activeTokens: string;
    activateToken: (index: number, value: boolean) => void;
    portal?: string;
}

interface StyledProps {
    activated: boolean;
    disabled?: boolean;
}

const Wrapper = styled.div<{ isMobile: boolean }>`
    // background: #faf0f0;
    height: 100%;
`;

const ActivatedTokenDiv = styled.div<StyledProps>`
    background: ${({ activated }) => (activated ? '#FFFFFF' : '')};
    border: ${({ activated }) => (activated ? '0.5px solid #000000' : 'none')};
    border-radius: ${({ activated }) => (activated ? '3px' : 'none')};
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const Header = styled.div`
    padding: 30px;
    border-bottom: 0.25px solid #000000;
    width: 100%;
    display: flex;

    & > div {
        flex: 1;
        font-size: 14px;
        line-height: 16px;
    }
`;

const TokensWrapper = styled.div`
    padding: 0 30px;
    display: flex;
    flex-direction: column;
`;

const Token = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    width: 100%;
`;

const Name = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    width: 100%;
    text-align: left;
`;

const NoToken = styled.p`
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    align-items: center;
`;

const TokenDivWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
const TokenIconWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

export default function Tokens({
    isMobile,
    activeTokens,
    tokenActivated,
    setActiveTokens,
    setToken,
    activateToken,
    tokens,
    portal
}: IProps) {

    return (
        <Wrapper isMobile={isMobile}>
            <Header>
                <div className="d-flex flex-row justify-between w-100">
                    <div className="d-flex align-items-center w-100 justify-content-center">
                        TOKENS
                    </div>
                </div>
            </Header>
            <Tools>
                {/* <TokenType>select tokens</TokenType> */}
                <Tabs>
                    <Tab
                        active={activeTokens === tokenTypes.DATA}
                        onClick={() => setActiveTokens(tokenTypes.DATA)}
                    >
                        data
                    </Tab>
                    <Tab
                        active={activeTokens === tokenTypes.CHAPTER}
                        onClick={() => setActiveTokens(tokenTypes.CHAPTER)}
                    >
                        chapter
                    </Tab>
                    <Tab
                        active={activeTokens === tokenTypes.DRRT}
                        onClick={() => setActiveTokens(tokenTypes.DRRT)}
                    >
                        DRRT
                    </Tab>
                </Tabs>
            </Tools>
            <TokensWrapper>
                {!!tokens?.length ? (
                    tokens?.map((token: any, i: number) => (
                        <Token
                            id={`token-${i}`}
                            key={token.address || token.name}
                        >
                            <ActivatedTokenDiv
                                activated={tokenActivated[i]}
                                // onClick={() => activateToken(i, !tokenActivated[i])}
                                disabled
                            >
                                <TokenIconWrapper>
                                    {token?.type ? (
                                        <img
                                            src={token.icon || token.image || token.symbol || defaultImage}
                                            id={`token-icon-${i}`}
                                            style={{
                                                width: 40,
                                                marginRight: 12,
                                            }}
                                        />
                                    ) : (
                                        <video
                                            id={`token-icon-${i}`}
                                            autoPlay={false}
                                            playsInline
                                            muted
                                            src={token.icon || token.image || defaultImage}
                                            style={{
                                                width: 40,
                                                marginRight: 12,
                                            }}
                                        />
                                    )}
                                </TokenIconWrapper>
                                <Name id={`token-name-${i}`}>{token.name}</Name>
                            </ActivatedTokenDiv>
                            <TokenDivWrapper onClick={() => setToken(token)}>
                                <TokenArrow />
                            </TokenDivWrapper>
                        </Token>
                    ))
                ) : (
                    <NoToken>NO TOKENS</NoToken>
                )}
            </TokensWrapper>
        </Wrapper>
    );
}
