import React from 'react';
import { Redirect } from 'react-router-dom';

import PATHS from './pathNames';
import LazyBoundary from 'src/utils/LazyBoundary';
import loadable from '@loadable/component';
import LoaderComponent from 'src/components/common/Loader/LoaderComponent';
import Checkout from 'src/pages/Checkout/Full';

const getLoader = () => <LoaderComponent />;
const LearnMoreComponent = loadable(() => import('src/pages/LearnMore'));
const GamesContainer = loadable(() => import('src/pages/Games'));
const GameContainer = loadable(() => import('src/pages/Games/Game/GameContainer'));
const NotFoundComponent = loadable(() => import('src/pages/NotFound'));
const ChatContainer = loadable(() => import('src/pages/Chat'));
const Profile = loadable(() => import('src/pages/Profile'));
const BankInfoContainer = loadable(() => import('src/pages/BankInfo'));
const HelpComponent = loadable(() => import('src/pages/Help'));
const Privacy = loadable(() => import('src/pages/Privacy'));
const Terms = loadable(() => import('src/pages/Terms'));
const StoryTerms = loadable(() => import('src/pages/StoryTerms'));
const Faq = loadable(() => import('src/pages/Faq'));
const Contact = loadable(() => import('src/pages/Contact'));
const PortalPage = loadable(() => import('src/pages/PortalPage'));
const GoborbPage = loadable(() => import('src/pages/PortalPage/Goborb'));
const DrrtToken = loadable(() => import('src/pages/DrrtToken'));
const Story = loadable(() => import('src/pages/Story'));
const Ledger = loadable(() => import('src/pages/Ledger'));
const Leaderboard = loadable(() => import('src/pages/Leaderboard'));
const Tokens = loadable(() => import('src/pages/Tokens'));
const Token = loadable(() => import('src/pages/Token'));
const SocietyMechanics = loadable(() => import('src/pages/SocietyMechanics'));

export const routes = [
    {
        path: PATHS.HOME,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <PortalPage {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.LOGIN,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <PortalPage {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.SIGNUP,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <PortalPage {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.LEARN_MORE,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <LearnMoreComponent {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.FORGOT_PASSWORD,
        authRequired: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <PortalPage {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.CHAT,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        exact: true,
        isOnBoardingView: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <ChatContainer {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.POINTS,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        exact: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <BankInfoContainer {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.GOBORB,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: true,
        exact: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <GoborbPage {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.LEDGER,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        exact: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Ledger {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.LEADERBOARD,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        exact: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Leaderboard {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.GAMES,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        exact: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <GamesContainer {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.IDEAS,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        exact: true,
        gameType: 'IDEAS',
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <GamesContainer {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.VOTING,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        exact: true,
        gameType: 'VOTING',
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <GamesContainer {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.RANKS,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        exact: true,
        gameType: 'RANKS',
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <GamesContainer {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.GAME_DETAILS,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <GameContainer {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.PROFILE,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Profile {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.HELP,
        exact: true,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <HelpComponent {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.PRIVACY,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Privacy {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.TERMS,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Terms {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: '/:storyName/terms',
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <StoryTerms {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.FAQ,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Faq {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.CONTACT,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Contact {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.CHECKOUT,
        exact: true,
        authRequired: true,
        hasNavBar: false,
        hasBottomBar: false,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Checkout {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.SOCIETY_MECHANICS,
        exact: true,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <SocietyMechanics {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.DRRT,
        exact: true,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <DrrtToken {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.STORY,
        exact: true,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <Story {...props} />
                </LazyBoundary>
            );
        }
    },
    {
        path: PATHS.PORTAL,
        exact: true,
        authRequired: true,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <LazyBoundary fallback={getLoader}>
                    <PortalPage {...props} />
                </LazyBoundary>
            );
        }
    },
    // {
    //     path: PATHS.TOKENS,
    //     exact: true,
    //     authRequired: true,
    //     hasNavBar: true,
    //     hasBottomBar: true,
    //     component: (props) => {
    //         return (
    //             <LazyBoundary fallback={getLoader}>
    //                 <Tokens {...props} />
    //             </LazyBoundary>
    //         );
    //     }
    // },
    // {
    //     path: PATHS.TOKEN,
    //     exact: true,
    //     authRequired: true,
    //     hasNavBar: true,
    //     hasBottomBar: true,
    //     component: (props) => {
    //         return (
    //             <LazyBoundary fallback={getLoader}>
    //                 <Token {...props} />
    //             </LazyBoundary>
    //         );
    //     }
    // },
    {
        path: PATHS.NOT_FOUND,
        authRequired: false,
        hasNavBar: true,
        hasBottomBar: true,
        component: (props) => {
            return (
                <Redirect
                    to={{
                        pathname: PATHS.HOME,
                        state: { from: props.location }
                    }}
                />
            );
            // return (
            //     <LazyBoundary fallback={getLoader}>
            //         <NotFoundComponent {...props} />
            //     </LazyBoundary>
            // );
        }
    },
    {
        path: PATHS.WILDCARD,
        authRequired: false,
        component: (props) => {
            return (
                <Redirect
                    to={{
                        pathname: PATHS.HOME,
                        state: { from: props.location }
                    }}
                />
            );
        }
    }
];

export default routes;
