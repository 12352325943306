import React, { useState } from 'react';

import PhoneInput from '../../PhoneInput/PhoneInput';
import {
  PhoneMessageContainer,
  PhoneSubmitButton,
  CenteredSpan,
  ErrorMessage
} from '../ChatMessage.styles';

interface IProps {
  inputType: string,
  inputName: string,
  maxLen?: number,
  placeholder?: string
  handleChange: (value: string) => void;
  onSubmit: () => void;
  color: string;
  error: string;
}

export default function UserPhoneField(props: IProps) {
  const [phone, setPhone] = useState<string>('');
  return (
    <>
      <PhoneMessageContainer isMyOwn={true}>
        <PhoneInput
          name='phone'
          hanldeChange={(value: string) => {
            setPhone(value);
            props.handleChange(value);
          }}
          value={phone}
        />
        <PhoneSubmitButton
          favoriteColor={props.color}
          onClick={() => props.onSubmit()}
        >
          <CenteredSpan>Push</CenteredSpan>
        </PhoneSubmitButton>
      </PhoneMessageContainer>
      {props.error && <ErrorMessage isMyOwn={false}>{props.error}</ErrorMessage>}
    </>
  )
};

