import React from 'react';
import styled from 'src/theme/styled';
import ICModal from '../Modal';

interface IProps {
  close: () => void;
}

const Wrapper = styled.div`
  max-height: 500px;
  overflow: auto;
`

export default function PrivacyModal({ close }: IProps) {
  return (
    <ICModal closeModal={close} className='terms-modal' topClassName='terms-modal-wrapper'>
      <Wrapper>
        <h2>PRIVACY POLICY</h2>
        <p>Last Updated: 05/05/2021</p>
        <p>
          This Privacy Policy (&quot;Policy&quot;) explains how your
          personal information is collected, used and disclosed by
          Imagine Council and its subsidiaries and affiliated
          companies (&quot;Imagine Council&quot;). This Policy applies
          to the websites of Imagine Council, including
          https://imaginecouncil.com (&quot;Sites&quot;) and Imagine
          Council&#x27;s other products, services or any other
          features, technologies or functionalities offered by us on
          our Sites or through any other means (collectively,
          &quot;Services&quot;). This Policy does not apply to
          information you provide directly to third party websites or
          third party applications that use Imagine Council&#x27;s
          Services. We do not control the activities of such third
          parties and advise you to consult their privacy policies
          before you share your personal information.
        </p>
        <p>PERSONAL INFORMATION COLLECTED</p>
        <p>
          We collect information about you in various ways when you
          use our Sites and Services. For example, we collect
          information you provide to us when you register for a
          Imagine Council account or sign up through third party
          applications to initiate or accept a payment, request
          customer service or respond to a Imagine Council survey.
          Such personal information may include your name, address,
          email address, credit card information and bank account and
          routing numbers. Before you use the Services, we may also
          require you to provide additional information that we can
          use to verify your identity, such as your date of birth,
          Social Security number or other information. Finally, we may
          obtain information about you from other sources, including
          third party websites or applications that offer the Imagine
          Council Services, and combine that with information we
          collect on our Sites and through our Services.
        </p>
        <p>
          When you visit our Sites or use our Services, some
          information is automatically collected. For example, when
          you visit our Sites, your computer&#x27;s operating system,
          Internet Protocol (&quot;IP&quot;) address, access times,
          browser type and language and the website you visited before
          our Sites are logged automatically. We also collect
          information about your usage and activity on our Sites and
          Services.
        </p>
        <p>
          Cookies. We automatically collect information using
          &quot;cookies.&quot; Cookies are small data files stored on
          your hard drive by a website. Among other things, cookies
          help us improve our Sites, Services and your experience. We
          use browser cookies to see which areas and features are
          popular and to count visits to our Sites. We also use flash
          cookies for fraud prevention purposes. You can usually
          choose to set your browser to remove and/or reject cookies.
          For instructions on removing or rejecting flash cookies,
          please see Adobe Flash Player and Microsoft Silverlight. If
          you choose to remove or reject cookies, this could affect
          certain features or services of our Sites or Services.
          Please note that you may also encounter cookies from third
          parties when using the Imagine Council Services on websites
          that we do not control.
        </p>
        <p>
          Web Beacons. We may also collect information using Web
          beacons. Web beacons are electronic images that may be used
          on our Sites, in our Services or in our emails. We use Web
          beacons to deliver cookies, count visits, understand usage
          and campaign effectiveness and to tell if an email has been
          opened and acted upon.
        </p>
        <p>USE OF PERSONAL INFORMATION WE COLLECT</p>
        <p>
          We use personal information collected through our Sites and
          Services for purposes described in this Policy or otherwise
          disclosed to you. For example, we may use personal
          information to:
        </p>
        <ul role="list">
          <li>operate and improve our Sites and Services;</li>
          <li>
            respond to your comments and questions and provide
            customer service;
          </li>
          <li>
            process transactions and send you related information,
            including confirmations, receipts, invoices, technical
            notices, updates, security alerts and support and
            administrative messages;
          </li>
          <li>
            verify your identity, prevent fraud and comply with
            money transmission laws;
          </li>
          <li>
            resolve disputes, collect fees and troubleshoot
            problems;
          </li>
          <li>
            communicate with you about new contests, promotions,
            rewards, upcoming events and other news about Imagine
            Council and our selected partners; and
          </li>
          <li>
            link or combine with other personal information we get
            from third parties to help understand your needs and
            provide you with better service.
          </li>
        </ul>
        <p>
          Imagine Council stores and processes personal information in
          the United States.
        </p>
        <p>Third Party Payment Processors</p>
        <p>
          Imagine Council uses third party payment processors to
          assist it in processing your personally identifiable payment
          information securely. Such third party processors’ use of
          your personal information is governed by their respective
          privacy policies which may or may not contain privacy
          protections as protective as this privacy policy. Payments
          are currently processed and managed using the third party
          vendors below. We will inform you which payment processors
          are used when processing your payments.
        </p>
        <ul role="list">
          <li>
            Stripe -{' '}
            <a href="https://stripe.com/us/privacy">
              privacy policy
            </a>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>SHARING OF PERSONAL INFORMATION</p>
        <p>
          We may share your personal information with the person or
          company that you are paying, or that is transferring money
          to you, for purposes of processing the transaction. We may
          also share your personal information with payment
          processors, banks and other entities in the financial system
          to process your transactions and maintain your account. We
          may also share your unique user ID with third parties that
          integrate the Imagine Council services into their sites and
          applications; however, we will not share any personal
          information with these third parties.
        </p>
        <p>
          We may also share your personal information with third party
          vendors, consultants and other service providers who work on
          our behalf (e.g., fraud and identity theft prevention and
          security auditing services). Furthermore, to verify your
          identity, we may share your personal information with
          third-party identity-verification services such as
          Inflection and/or Lexus Nexus.
        </p>
        <p>Finally, we may also share your information as follows:</p>
        <ul role="list">
          <li>
            with credit bureaus to report account information, as
            permitted by law;
          </li>
          <li>
            to comply with laws or to respond to lawful requests and
            legal process; to protect the rights and property of
            Imagine Council, our agents, users and others, including
            to enforce our terms, policies and guidelines; or in an
            emergency to protect the personal safety of Imagine
            Council, our users or any other person;
          </li>
          <li>
            in connection with any merger, sale of company collectibles,
            financing or acquisition of all or a portion of our
            business to another company; and
          </li>
          <li>with your consent or direction to do so.</li>
        </ul>
        <p>
          We may also share aggregated or anonymized information that
          does not directly identify you.
        </p>
        <p>SECURITY OF YOUR PERSONAL INFORMATION</p>
        <p>
          Imagine Council takes measures to help protect your personal
          information in an effort to prevent loss, misuse and
          unauthorized access, disclosure, alteration and destruction.
        </p>
        <p>YOUR INFORMATION CHOICES AND CHANGES</p>
        <p>
          You may opt out of receiving promotional emails from Imagine
          Council by following the instructions in those emails or by
          emailing us at . If you opt out, we may still send you
          non-promotional emails, such as emails about your accounts
          or our ongoing business relations. In addition, you can
          review and edit your personal information at any time by
          logging into your account.
        </p>
        <p>CHANGES TO THIS POLICY</p>
        <p>
          If we decide to change our privacy policy, we will post
          those changes to this Privacy Policy, the homepage, and
          other places we deem appropriate so that you are aware of
          what information we collect, how we use it, and under what
          circumstances, if any, we disclose it. We will not
          materially change our policies and practices to make them
          less protective of customer information collected in the
          past without the consent of affected customers.
        </p>
        <p>
          We reserve the right to modify this Privacy Policy at any
          time, so please review it frequently. If we make material
          changes to this Privacy Policy, we will notify you here, by
          email, or by means of a notice within your Imagine Council
          Website Manager interface prior to the change becoming
          effective.
        </p>
        <p>Canadian Visitors to the Site</p>
        <p>
          Canadian citizens, except under circumstances defined by
          law, are entitled to access their own personal information
          collected by Imagine Council by writing to: 459 Broadway,
          5th Floor, New York, NY, 10013. Imagine Council may ask you
          to pay an administrative fee for accessing or copying your
          personal information, but will inform you of the anticipated
          charges and confirm that you want us to proceed before
          processing your request. If you believe that the personal
          information about you that we have collected is incomplete
          or inaccurate, we will correct the information upon
          verification of the omission or error and of the identity of
          the person requesting the change. If you wish additional
          information about our personal information management, to
          access, correct or have us investigate any matters in
          relation to your personal information, please contact us.
        </p>
        <p>California Residents</p>
        <p>
          California Civil Code Section 1798.83 permits users who are
          California residents and who have provided with
          &quot;personal information&quot; (as that term is defined in
          Section 1798.83) to request certain information about the
          disclosure of that information to third parties for their
          direct marketing purposes. If you are a California resident
          with questions regarding this, or how the Imagine Council
          reviews and processes any request from you to amend your
          personal information, please contact us here at
          hub@imaginecouncil.com
        </p>
        <p>
          Notice for Visitors to the Website Outside of the United
          States
        </p>
        <p>
          You should be aware that the United States and other
          countries have not harmonized their privacy regulations.
          Because Imagine Council and its servers are located in the
          United States, Imagine Council has written its Privacy
          Policy to satisfy United States regulations. By using
          Imaginecouncil.com, you agree to the level of privacy
          protection set out in our Privacy Policy.
        </p>
        <p>CHILDREN UNDER 13 </p>
        <p>
          Imagine Council is not directed to children under 13 years
          of age and we do not knowingly collect personally
          identifiable information from children under 13. If we
          become aware that a child under 13 has provided us with
          personally identifiable information, we will delete such
          information from our files.
        </p>
        <p>QUESTIONS</p>
        <p>
          If you have any questions about this Policy, please contact
          us at hub@imaginecouncil.com.
          <br />
        </p>
      </Wrapper>
    </ICModal>
  );
}
