import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CreditCardPayment from "../../payment/CreditCardPayment"
import ApplePay from "../../payment/ApplePay"
import DeliveryInfo from "../DeliveryInfo";
import paymentOptions from "../../paymentOptions"
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import styles from "./Step5.module.scss"

const { Config } = require('../../../../../config.js')
let stripeKey = Config[process.env.REACT_APP_ENV]['stripeKey']
const stripePromise = loadStripe(stripeKey);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Inter"
    }
  ],
  height: "50px",
  background: "transparent"
};
const Step5 = ({
  chosenPayment,
  paymentDone,
  goNext,
  deliveryInfo,
  editStep,
  deliveryOption
}) => {
  const [paymentSecret, setPaymentSecret] = useState("")

  const fetchPaymentIntent = async () => {
    const response = await fetch(`/api/routes/users/getPaymentIntent?amount=10`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    const data = await response.json();
    console.log('data', data)
    if (data?.success) {
      setPaymentSecret(data.secret)
    }
  }

  useEffect(() => {
    if (chosenPayment === paymentOptions.APPLE_PAY) {
      fetchPaymentIntent()
    }
  }, [chosenPayment])

  return (
    <div className={styles.step}>
      <div className={styles.container}>
        <div className={styles.deliveryInfo}>
          <DeliveryInfo className={styles.info} deliveryInfo={deliveryInfo} />
          <span className={styles.edit} onClick={() => editStep(0)}>
            edit
          </span>
        </div>
        <div className={styles.deliveryOption}>
          <span className={styles.value}>
            {deliveryOption?.value || deliveryOption}
          </span>
          <span className={styles.edit} onClick={() => editStep(1)}>
            edit
          </span>
        </div>
        {chosenPayment === paymentOptions.CC &&
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <CreditCardPayment
              proceed={goNext}
              deliveryInfo={deliveryInfo}
            />
          </Elements>
        }
        {chosenPayment === paymentOptions.APPLE_PAY &&
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <ApplePay
              isMobile={false}
              paymentSecret={paymentSecret}
              proceed={paymentDone}
            />
          </Elements>
        }
      </div>
    </div>
  );
};

Step5.propTypes = {
  chosenPayment: PropTypes.string.isRequired,
  goNext: PropTypes.func.isRequired,
  deliveryInfo: PropTypes.object.isRequired
};

export default Step5
