import React from 'react';
import styled from 'src/theme/styled';

const PageComponent = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    padding: 16px 0;
    flex-flow: column;
`;

const Page = (props: any) => {
    return (
        <PageComponent className={props.className}>
            {props.children}
        </PageComponent>
    );
};

export default Page;
