// import rootReducer from './reducers';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appSlice from './features/app/appSlice';
import authSlice from './features/auth/authSlice';
import layoutSlice from './features/layout/layoutSlice';

export const rootReducer = combineReducers({
    app: appSlice,
    auth: authSlice,
    layout: layoutSlice
})

const store = configureStore({
    reducer: rootReducer,
    devTools: true
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// "@types/react-redux": "^7.1.9",
