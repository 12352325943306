import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import HttpsRedirect from 'react-https-redirect';
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from './serviceWorker';
import initStore from "./store/store"; //redux-store
import new_initStore from './store/new_store' //rtk-store

const store = initStore();

ReactDOM.render(
    <HttpsRedirect>
        <App store={store}/>
    </HttpsRedirect>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
