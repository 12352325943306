import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { saveUserGpcAccount } from 'src/store/actions/auth';

import DeliveryForm from "../DeliveryForm";

import '../../checkout.scss'

const Step2 = ({
  cart,
  deliveryInfo,
  goNext,
  saveData,
  user,
  saveUserGpcAccount
}) => {
  return (
    <>
      <DeliveryForm
        cart={cart}
        saveUserGpcAccount={saveUserGpcAccount}
        user={user}
        deliveryInfo={deliveryInfo}
        goNext={goNext}
        saveData={saveData}
      />
    </>
  );
};

Step2.propTypes = {
  deliveryInfo: PropTypes.object,
  goNext: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired
};

Step2.defaultProps = {
  deliveryInfo: {}
};

const mapStateToProps = (state) => ({
  cart: state.app.cart || [],
  user: state.auth.user
});

export default connect(
  mapStateToProps, {
  saveUserGpcAccount
})(Step2);
