import React from 'react';
import styled from 'src/theme/styled';
import ICModal from '../Modal';

interface IProps {
  close: () => void;
}

const Wrapper = styled.div`
  max-height: 500px;
  overflow: auto;
`

export default function ContactModal({ close }: IProps) {
  return (
    <ICModal closeModal={close} className='terms-modal' topClassName='terms-modal-wrapper'>
      <Wrapper>
        Contact
      </Wrapper>
    </ICModal>
  );
}
