import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'src/theme/styled';
import Portal from 'src/pages/Portal';
import Tokens from 'src/pages/Tokens';
import Chat from 'src/pages/Chat';
import Checkout from 'src/pages/Checkout';
import UserService from 'src/services/user';
import { otherPortals } from 'src/constants/otherPortals';
import { ExperiencePortals } from 'src/constants/experiencePortals';
import { ResourcePortals } from 'src/constants/resourcePortals';
import { UtilityPortals } from 'src/constants/utilityPortals';
import { setDesktopLayoutRight } from 'src/store/actions/layout';
import { ReactComponent as Token } from 'src/assets/portals/token.svg';
import { ReactComponent as Cart } from 'src/assets/svg/cart.svg';
import { ReactComponent as ChatIcon } from 'src/assets/svg/profile.svg';
import { ReactComponent as Close } from 'src/assets/svg/exit-white.svg';

const DesktopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
// prettier-ignore
// ${(props) => (props.thumbColor ? `background: ${props.thumbColor};` : 'background: #fce44a;')};
// ${(props) => (props.scrollColor ? `background: ${props.scrollColor};` : 'background: #e05500;')};
const WrapperLeft = styled.div`
    max-width: 530px;
    min-width: 530px;
    position: inherit;
    z-index: 1002;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
        background: #e05500;
        
    }
    &::-webkit-scrollbar-thumb {
        background: #fce44a;
        border-radius: 5px;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        left: 530px;
        bottom: 65px;
        border-right: 0.25px solid #000000;
    }
    
    ${(props) => (props.isOpen ? 'opacity: 0.6;' : '')};

`;
// prettier-ignore

// ${(props) => (props.scrollColor ? `background: ${props.scrollColor};` : 'background: #e05500;')};
// ${(props) => (props.thumbColor ? `background: ${props.thumbColor};` : 'background: #fce44a;')};
const WrapperMiddleBigger = styled.div`
    // display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 30px;
        background: #e05500;
    }
    &::-webkit-scrollbar-thumb {
        background: #fce44a;
        border-radius: 5px;
    }
    ${(props) => (props.isOpen ? 'opacity: 0.6;' : '')};
    ${(props) => (props.color ? `background: ${props.color};` : '#FFF')};
    ${(props) => (props.textColor ? `color: ${props.textColor};` : '#000')};

`;
const WrapperRight = styled.div`
    position: fixed;
    display: none;
    top: 0;
    right: -530px;
    background: #fff;
    height: 100%;
    z-index: 11114;
    transition: right 3s;
    max-width: 530px;
    min-width: 530px;
    padding-top: 40px;
    border-left: 2px solid blue;
    ${(props) =>
        props.isOpen
            ? 'display: block;transform: translate(-530px);transition-duration: .45s;'
            : ''};
`;

const StyledTokenIcon = styled(Token)`
    position: absolute;
    top: 10px;
    left: 18px;
    cursor: pointer;
    z-index: 1;
`;
const StyledCartIcon = styled(Cart)`
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 1;
`;
const StyledChatIcon = styled(ChatIcon)`
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 1;
`;

const StyledCloseIcon = styled(Close)`
    display: none;
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
    z-index: 2;
    ${(props) => (props.show ? 'display: block' : '')};
`;

const RenderLayout = ({ user, children, layout, setDesktopLayoutRight }) => {
    const { pathname } = useLocation();
    const AllPortals = [
        ...ExperiencePortals,
        ...ResourcePortals,
        ...UtilityPortals,
        ...otherPortals
    ];
    const isOpen = layout?.right?.token || layout?.right?.cart || layout?.chat;
    const backgroundColor = user.data?.isLogged
        ? 'var(--primary-favorite-color)'
        : '#FFF';
    const textColor = UserService.getColorToUse(backgroundColor);

    const portal = useMemo(() => {
        const linkPortal = AllPortals.find(
            (p) => p.key !== 'landing' && pathname.indexOf(p.link) !== -1
        );
        if (!linkPortal && pathname.indexOf('/games/') >= 0) {
            return otherPortals.find((p) => p.key === 'ftc');
        }
        if (!linkPortal && pathname.indexOf('/data/') >= 0) {
            return otherPortals.find((p) => p.key === 'ftc');
        }
        if (!linkPortal && pathname.indexOf('/landing/') >= 0) {
            return otherPortals.find((p) => p.key === 'landing');
        }

        return linkPortal;
    }, [pathname, AllPortals]);

    const renderLeftPart = () =>
        portal?.key === 'landing' ? (
            <WrapperLeft
                isOpen={isOpen}
                className="col no-paddings"
                thumbColor={portal?.scroll?.thumb}
                scrollColor={portal?.scroll?.background}
            >
                <Portal portalPassed={portal} />
            </WrapperLeft>
        ) : null;

    const closeRightPart = () => {
        setDesktopLayoutRight({ token: false, cart: false });
    };

    const checkToCloseDrawer = (e) => {
        if (
            !e.target.id.includes('token-') &&
            !document.getElementById('right-drawer').contains(e.target) &&
            !document
                .getElementById('right-drawer-opener')
                .contains(e.target) &&
            isOpen
        ) {
            // closeRightPart();
        }
    };

    useEffect(() => {
        const isDrawerOpen = layout?.right?.token || layout?.right?.cart;
        if (isDrawerOpen) {
            document.addEventListener('click', checkToCloseDrawer);
        }
        return () => {
            document.removeEventListener('click', checkToCloseDrawer);
        };
    }, [layout]);

    return (
        <DesktopWrapper className="w-100 flex flex-row">
            {renderLeftPart()}
            <WrapperMiddleBigger
                id="middle-drawer"
                textColor={textColor}
                color={pathname.includes('society') ? backgroundColor : '#FFF'}
                isOpen={isOpen}
                thumbColor={portal?.scroll?.thumb}
                scrollColor={portal?.scroll?.background}
            >
                {children}
            </WrapperMiddleBigger>

            <WrapperRight
                className="drawer-container"
                id="right-drawer"
                isOpen={isOpen}
            >
                <StyledCloseIcon
                    show={isOpen}
                    onClick={() => closeRightPart()}
                />
                {layout?.right?.token && (
                    <>
                        <StyledTokenIcon />
                        <Tokens />
                    </>
                )}
                {layout?.right?.cart && (
                    <>
                        <StyledCartIcon />
                        <Checkout />
                    </>
                )}
                {layout?.chat && (
                    <>
                        <StyledChatIcon />
                        <Chat userOnboarded={user?.data?.onboarding} />
                    </>
                )}
            </WrapperRight>
        </DesktopWrapper>
    );
};

const mapStateToProps = (state) => ({
    layout: state.layout,
    user: state.auth.user
});

export default connect(mapStateToProps, { setDesktopLayoutRight })(
    RenderLayout
);
