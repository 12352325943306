import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import useFetch from 'src/services/useFetch';
import ChatDiv from 'src/components/common/Chat/ChatDiv';
import {
  userMessageCounter,
  userHasNotifications
} from 'src/store/actions/app';
import { useAuth } from 'src/contexts/AuthContext';

const AuthRoute = ({
  user,
  isOnboarded,
  isLogged,
  userMessageCounter,
  userHasNotifications,
  component,
  ...rest
}) => {
  const { get: hasNewUserActivity } = useFetch('/api/routes/users/hasNewUserActivity');
  const Component = component;
  const { logoutUser } = useAuth();

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const data = await hasNewUserActivity(
          `?userEmail=${encodeURIComponent(user.data.email)}`
        );
        if (data && data.counters) {
          userHasNotifications({
            gamesToVoteCounter: data.counters.gamesToVoteCounter,
            gamesToSubmitCounter:
              data.counters.gamesToSubmissionCounter
          });
        }
        if (data && data.hasNewMessages) {
          userMessageCounter({
            usersNotifications: data.hasNewMessages
          });
        }
      } catch (error) {
        logoutUser();
      }
    };
    if (isLogged) {
      getNotifications();
    }
  }, []);

  return (
    <Route
    {...rest}
    render={(props) => {
      if (isLogged) {
        if (isOnboarded || isOnboarded === undefined) {
          return <Component {...props} />
        } else {
          return <ChatDiv userOnboarded={isOnboarded} />
        }
      } else {
        return <Component {...props} />
      }
    }}
  />
  )
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isOnboarded: state.auth.user.data.onboarding,
  isLogged: state.auth.user.isLogged
});

export default connect(mapStateToProps, {
  userMessageCounter,
  userHasNotifications
})(AuthRoute);
