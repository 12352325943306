var Config = {
    "development": {
        "url": "http://localhost:8081/",
        "google": "AIzaSyCCKy9c6WtNzp6AxKDTtH1kf4CeZEeYLIw",
        "stripeKey": "pk_test_lMonnP6dbqgvJzVwSsBMbgLf00YyqB4cv8",
        "infuraId": "ebdc71606f984a8a849e89162dd3baea",
        "ethNetwork": "goerli",
        "picturePrefix": "https://uppyupload.s3.amazonaws.com",
        "fileStackPrefix": "https://ic-filestack.s3.us-west-1.amazonaws.com",
        "s3": {
            "ledgerUrl": "https://ledger-objects.s3.amazonaws.com"
        }
    },
    "staging": {
        "url": "https://icssdk-staging.herokuapp.com/",
        "google": "AIzaSyCCKy9c6WtNzp6AxKDTtH1kf4CeZEeYLIw",
        "stripeKey": "pk_test_lMonnP6dbqgvJzVwSsBMbgLf00YyqB4cv8",
        "infuraId": "ebdc71606f984a8a849e89162dd3baea",
        "ethNetwork": "goerli",
        "picturePrefix": "https://uppyupload.s3.amazonaws.com",
        "fileStackPrefix": "https://ic-filestack.s3.us-west-1.amazonaws.com",
        "s3": {
            "ledgerUrl": "https://ledger-objects.s3.amazonaws.com"
        }
    },
    "production": {
        "url": "https://icssdk-prod.herokuapp.com/",
        "google": "AIzaSyCCKy9c6WtNzp6AxKDTtH1kf4CeZEeYLIw",
        "stripeKey": "pk_live_ICqSuNAeadKONFdvjFHtGVlJ00QlE5OR1t",
        "ethNetwork": "mainnet",
        "picturePrefix": "https://uppyupload.s3.amazonaws.com",
        "fileStackPrefix": "https://ic-filestack.s3.us-west-1.amazonaws.com",
        "s3": {
            "ledgerUrl": "https://ledger-objects.s3.amazonaws.com"
        }
    },
};

module.exports = { Config }
