import React, { ReactNode, HTMLAttributes } from 'react';
import styled from 'src/theme/styled';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    background?: string;
    color?: string;
    disabled?: boolean;
}

const StyledButton = styled.button`
    background: ${(props: any) => props.background};
    color: ${(props: any) => props.color};
    letter-spacing: 0.2em;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    border-radius: 5px;
    width: auto;
    padding: 8px 18px;
    border: none;
    box-shadow: none;
    height: 30px;
    align-items: center;
    display: flex;
    ${(props: any) => (props.disabled ? 'opacity: 0.5' : '')};
    ${(props: any) => (props.disabled ? 'cursor: default' : 'cursor: pointer')};
`;

export function ICButton({
    children,
    onClick,
    disabled = false,
    color = 'black',
    background
}: ButtonProps) {
    return (
        <StyledButton
            disabled={disabled}
            background={background}
            color={color}
            onClick={onClick}
        >
            {children}
        </StyledButton>
    );
}
