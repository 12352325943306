export function toggleThemeMode(value: "light" | "dark") {
  const rootElement = document.getElementsByTagName('body')[0];

  if (!rootElement) {
    return;
  }

  if (value === 'light') {
    rootElement.classList.add('light-mode');
    rootElement.classList.remove('dark-mode');
  } else {
    rootElement.classList.add('dark-mode');
    rootElement.classList.remove('light-mode');
  }
};

export function toggleFavoriteColor(color: string) {
  const rootElement = document.getElementsByTagName('body')[0];

  if (rootElement) {    
    rootElement.className = rootElement.className
      .split(" ")
      .filter((item) => !item.includes("favorite-color--"))
      .join(" ")
      
    rootElement.classList.add(`favorite-color--${color}`);
  }
}

export const getHexFavoriteColor = () => {
  const root = document.querySelector('#root')!
  return getComputedStyle(root).getPropertyValue('--primary-favorite-color');
} 

