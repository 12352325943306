import React from "react";
import PropTypes from "prop-types";
import DeliveryInfo from "../DeliveryInfo";
import cc from "../../../../../assets/svg/cc-pay.svg"
import styles from "./StepMobile6.module.scss"

const StepMobile6 = ({
  buy,
  deliveryInfo,
  editStep,
  deliveryOption,
  cardLast4,
  buyBtnRef
}) => {

  return (
    <div className={styles.step}>
      <div className={styles.container}>
        <div className={styles.deliveryInfo}>
          <DeliveryInfo className={styles.info} deliveryInfo={deliveryInfo} />
          <span className={styles.edit} onClick={() => editStep(0)}>edit</span>
        </div>
        <div className={styles.deliveryOption}>
          <span className={styles.value}>{deliveryOption}</span>
          <span className={styles.edit} onClick={() => editStep(1)}>edit</span>
        </div>
        <div className={styles.paymentDetails}>
          <div className={styles.card}>
            <img className={styles.image} src={cc} alt="cc" />
            <span className={styles.number}>card ending in {cardLast4}</span>
          </div>
          <span className={styles.edit} onClick={() => editStep(2)}>edit</span>
        </div>

        <button
          ref={buyBtnRef}
          className={styles.buyButton}
          onClick={() => buy()}
        >
          review and buy
        </button>
      </div>
    </div>
  );
};

StepMobile6.propTypes = {
  buy: PropTypes.func.isRequired,
  cardLast4: PropTypes.string.isRequired,
  deliveryInfo: PropTypes.object.isRequired
};

export default StepMobile6
