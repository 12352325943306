import React from 'react';
import styled from 'src/theme/styled';
import ChatContainer from './ChatContainer';
import { useSettings } from 'src/contexts/SettingsContext';

const Chatting = styled.div <{ isMobile: boolean }>`
    height: 100%;
    width: 100%;
    padding: 16px;
    overflow-y: scroll;
    background: #ffffff;
`;
const OnboardingView = styled.div`
    background: #ffffff;
    position: fixed;
    left: 0;
    height: 100%;
    width: calc(100% - 528px);
    padding-left: 73px;
`;
const OnboardingChatDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 40px;
`;

const OnboardingText = styled.div`
    font-weight: 900;
    font-size: 37px;
    line-height: 49px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
`;

interface IProps {
  userOnboarded?: boolean;
}

const Chat = (props: IProps) => {
  const { isMobile } = useSettings();
  return (
    <>
      {props.userOnboarded && (
        <Chatting isMobile={isMobile} >
          <ChatContainer />
        </Chatting>
      )}
      {!props.userOnboarded && (
        <OnboardingView>
          {!isMobile && (
            <OnboardingChatDiv>
              <OnboardingText>
                Character Kawaii is chatting to onboard you in the Imagine Council Society right now.
              </OnboardingText>
              <OnboardingText>
                Chat in progress...
              </OnboardingText>
            </OnboardingChatDiv>
          )}
        </OnboardingView>
      )}
    </>
  );
};

export default Chat;
