import React, { useMemo } from "react";
import PropTypes from "prop-types";
import numbro from 'numbro';
import CountPicker from "../../CountPicker";

import styles from "./CheckoutBasket.module.scss"

const { Config } = require("../../../../config.js");
const picturePrefix = Config[process.env.REACT_APP_ENV]["picturePrefix"]

const CheckoutBasket = ({ products, userProducts, cart, updateCart }) => {
  const total = useMemo(() => {
    const value = cart.reduce((sum, item) => sum + (item.price * (item.count || 1)), 0);
    return numbro(value).formatCurrency({
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 2
    });
  }, [cart]);

  function checkImageUrl(url) {
    if (url.includes('/api/routes/media/file/')){
      return url;
    } else {
      return `/api/routes/media/file/${url}/image`;
    }
  }

  return (
    <>
      <div className={styles.header}>
        <span className={styles.total}>
          TOTAL {total}
        </span>
      </div>
      {cart.map((cartItem) => (
        <>
          <div className={styles.cartItem}>
            {cartItem?.icon && (
              <img className={styles.icon} src={cartItem?.icon?.app?.url ? checkImageUrl(cartItem?.icon?.app?.url): cartItem?.icon} alt="token" />
            )}
            <div className={styles.content}>
              <span className={styles.name}>{cartItem?.name}</span>
              <p className={styles.description}>
                {cartItem?.description}
              </p>
              <div className={styles.countWrapper}>
                {cartItem.type !== 'DRRT' ? (
                  <CountPicker
                    item={cartItem}
                    updateCount={(count) =>
                      updateCart(cart.map((c) => (c._id === cartItem._id) ? ({
                        ...c,
                        count
                      }) : c))}
                  />
                ) : null}
                <span className={styles.price}>${cartItem?.price || 0}</span>
              </div>
            </div>
          </div>
          <div className={styles.products}>
            {products.map((product) => {
              const productOptions = userProducts.find((userProduct) => userProduct.productId === product._id);
              return (
                <div className={styles.product} key={product.productId}>
                  <img
                    className={styles.image}
                    src={`${picturePrefix}/${product.images[0]?.url}.png`}
                    alt="product"
                  />
                  <div className={styles.info}>
                    <span className={styles.text}>
                      {product.name}
                    </span>
                    {productOptions?.size ? (
                      <span className={styles.text}>
                        size: {productOptions.size}
                      </span>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ))}
    </>
  );
};

CheckoutBasket.propTypes = {
  userProducts: PropTypes.array,
  products: PropTypes.array
};

CheckoutBasket.defaultProps = {
  userProducts: [],
  products: []
}

export default CheckoutBasket
