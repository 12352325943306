import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoaderComponent from 'src/components/common/Loader/LoaderComponent';
import Tokens from './Tokens';
import TokenInfo from './TokenInfo';
import TokenDetails from './TokenDetails';
import { gameOpenedFromNothing, setUserTokens, toggeColorActive } from 'src/store/actions/app';
import tokenTypes from 'src/constants/tokenTypes';
import { useSettings } from 'src/contexts/SettingsContext';
import { useAuth } from 'src/contexts/AuthContext';

const TokensContainer = ({
    setUserTokens,
    gameOpenedFromNothing,
    userTokens,
    user,
    toggeColorActive,
    isColorActive,
}: any) => {
    const { portal } = useParams<any>();
    const { logoutUser } = useAuth();
    const [activeTokenInfo, setActiveTokenInfo] = useState(false);
    const [tokenActivated, setTokenActivated] = useState<Array<boolean>>([
        isColorActive,
        false,
        false
    ]);
    const [activeTokens, setActiveTokens] = useState<string>(tokenTypes.DATA);
    const [token, setToken] = useState({});
    const [activeUserTokens, setActiveUserTokens] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        toggeColorActive(tokenActivated[0]);
    }, [tokenActivated])

    const { isMobile } = useSettings();

    const getTokens = () => fetch(`/api/routes/users/tokens?email=${encodeURIComponent(user.data.email)}`).then((res) => res.json());

    useEffect(() => {
        gameOpenedFromNothing();
        if (
            !userTokens ||
            !userTokens?.imagination?.length ||
            !userTokens?.royalty?.length
        ) {
            setIsLoading(true);
            getTokens().then((response) => {
                if (response?.message === 'User not authenticated.') {
                    logoutUser();
                } else if (response?.message === 'Success') {
                    setUserTokens(response.data);
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        setActiveUserTokens(userTokens[activeTokens]);
    }, [activeTokens, userTokens]);

    const tokenType: "data" | "chapter" | 'DRRT' = useMemo(() => {
        if (activeTokens === 'imagination') {
            return 'chapter';
        } else if (activeTokens === 'royalty') {
            return 'DRRT';
        }
        return 'data';
    }, [activeTokens]);

    if (activeTokenInfo) {
        return (
            <TokenInfo
                token={token}
                isMobile={isMobile}
                goBack={() => setActiveTokenInfo(false)}
            />
        );
    }

    if (isLoading) {
        return <LoaderComponent />;
    } else if (Object.keys(token).length && tokenType) {
        return (
            <TokenDetails
                setTokenInfo={() => setActiveTokenInfo(true)}
                isMobile={isMobile}
                token={token}
                setToken={() => setToken({})}
                tokenType={tokenType}
            />
        );
    } else {
        return (
            <Tokens
                tokenActivated={tokenActivated}
                setToken={(tokenSent) => setToken(tokenSent)}
                activeTokens={activeTokens}
                activateToken={(index: number, value: boolean) =>
                    setTokenActivated((prevState) =>
                        prevState?.map((item, idx) =>
                            idx === index ? value : false
                        )
                    )
                }
                setActiveTokens={setActiveTokens}
                isMobile={isMobile}
                tokens={activeUserTokens}
                portal={portal}
            />
        );
    }
};

export default connect(
    (state: any) => ({
        user: state.auth?.user,
        userTokens: state.app?.userTokens,
        isColorActive: state.app?.isColorActive,
    }),
    { gameOpenedFromNothing, setUserTokens, toggeColorActive }
)(TokensContainer);
