import React from 'react';
import classNames from 'classnames';
import { useCheckout, steps } from 'src/contexts/CheckoutContext';

export default function StepList() {
  const { goToSpecificStep, currentStep } = useCheckout();
  return (
    <div className="col-12 d-flex justify-content-center">
      {steps.map((step: any, index: number) => (
        <span
          onClick={() =>
            currentStep > index && goToSpecificStep(index)
          }
          key={index}
          className={classNames('w-100 text-center cursor-pointer checkout-step')}
          style={{ color: currentStep >= index ? "var(--secondary-text-color)" : "var(--primary-text-color)"}}
        >
          {index + 1}
        </span>
      ))}
    </div>
  );
}
