import React from 'react';
import styled from 'src/theme/styled';
import ICModal from 'src/components/common/Modal';
import { ReactComponent as Token } from 'src/assets/portals/token.svg';
import TokensContainer from 'src/pages/Tokens';

const StyledICModal = styled(ICModal)`
    height: calc(100% - 56px);
    border-radius: 2px !important;
    padding-top: 56px;
`;

function TokenModal({
    closeModal,
}: {
    closeModal: () => void;
}) {
    return (
        <StyledICModal
            topClassName="ic-mobile-token"
            closeModalInner={true}
            closeModal={closeModal}
            CloseSvg={Token}
        >
            <TokensContainer />
        </StyledICModal>
    );
}

export default TokenModal;
