import React, { useEffect, useMemo, useState } from 'react';
import styled from 'src/theme/styled';
import { ReactComponent as PlyButton } from 'src/assets/svg/play-btn.svg';
import { ReactComponent as PauseButton } from 'src/assets/svg/pause-btn.svg';
import { ReactComponent as ProgressBar } from 'src/assets/svg/progress-bar.svg';
import { ReactComponent as ProgressPoint } from 'src/assets/svg/progress-point.svg';
import { ReactComponent as ScrollIcon } from 'src/assets/svg/scroll-icon.svg';
import { useLocation } from 'react-router';

interface IWrapper {
    color?: string;
    isMobile?: boolean;
}

const PlayButtonStyled = styled(PlyButton)`
    cursor: pointer;
`;

const ProgressBarStyled = styled(ProgressBar)`
    cursor: pointer;
`;

const PauseButtonStyled = styled(PauseButton)`
    cursor: pointer;
    width: 26px;
    height: 28px;
`;

const PortalInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
`;

const Block = styled.div<IWrapper>`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 14px 0;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-text-color);
    & > div {
        margin-bottom: 8px;
    }
`;

const PortalInfoText = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin: 8px 0;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--primary-text-color);
`;
const PortalInfoTitle = styled.p`
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--primary-text-color);
`;
const ImagineImage = styled.div<IWrapper>`
    width: 100%;
    position: relative;
    margin-top: 50px;
    overflow: hidden;

    & > img {
        width: 100%;
        object-fit: contain;
        animation-name: slideInUp;
        animation-duration: 1.35s;
        animation-delay: 1.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: both;
        /* Selection */
        pointer-events: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @keyframes slideInUp {
            0% {
                transform: translateY(100%);
                visibility: visible;
            }
            100% {
                transform: translateY(0);
            }
        } 
    }

    &:before {
        content: '';
        bottom: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        border-bottom: 1px solid #ffff00;
    }
`;

const PlayPauseDiv = styled.div<IWrapper>`
    display: flex;
    width: 26px;
    height: 28px;
    margin-right: 10px;
    height: 30px;
    & > svg {
        fill: var(--primary-text-color);
        & path {
            fill: var(--primary-text-color);
        }
    }
`;

const AudioDiv = styled.div`
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ProgressBarDiv = styled.div<IWrapper>`
    position: relative;
    & > svg {
        & ellipse {
            fill: var(--primary-text-color);
        }
        & line {
            stroke: var(--primary-text-color);
        }
    }
`;

const ProgressPointStyled = styled(ProgressPoint)`
    position: absolute;
    left: 0;
`;

const UserInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 30px;
    p {
        margin: 0;
        padding-left: 10px;
    }
    img {
        width: 50px;
        height: 50px;
    }
`;

const ScrollWrapper = styled.div`
    width: 100%;
    height: 20px;
    padding-top: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
`;

let audio = new Audio();

export default function ImaginePortal({
    portal,
    isLogged,
    user,
    isMobile,
    textColor
}: any) {
    const { pathname } = useLocation();
    const [playing, setPlaying] = useState(false);

    const color = isLogged && user?.data?.color ? user.data.color.toLowerCase() : 'landing';

    let progressBarThumb = React.useRef<SVGSVGElement>(null);
    let progressBar = React.useRef<HTMLDivElement>(null);

    const audioPlay = () => {
        setPlaying(true);
    };

    const audioPausing = () => {
        setPlaying(false);
    };

    const audioTimeUpdate = () => {
        const progressPin = audio.currentTime / audio.duration;
        progressBarThumb.current?.style.setProperty(
            'left',
            `${progressPin * 100}%`
        );
    };

    function seekTime(this: HTMLElement, e: any) {
        let percent = e.offsetX / this.offsetWidth;
        audio.currentTime = percent * audio.duration;
    }

    useEffect(() => {
        audio.src = `/api/routes/media/audio/${color}`;
    }, [color]);

    useEffect(() => {
        progressBar?.current?.addEventListener('click', seekTime);
        audio.addEventListener('play', audioPlay);
        audio.addEventListener('pause', audioPausing);
        audio.addEventListener('timeupdate', audioTimeUpdate);

        return () => {
            audio.removeEventListener('timeupdate', () => audioTimeUpdate);
            audio.removeEventListener('play', () => audioPlay);
            audio.removeEventListener('pause', () => audioPausing);
            progressBar?.current?.removeEventListener('click', () => seekTime);
            setPlaying(false);
            audio.pause();
        };
    }, [audio.src]);

    const hideGobOrbMessage: boolean = useMemo(() => {
        return isLogged && pathname.indexOf('/datastream') >= 0;
    }, [isLogged, pathname]);

    return (
        <>
            <Block color={textColor}>
                <PortalInfoWrapper>
                    {!isLogged ? (
                        <>
                            <PortalInfoTitle color={textColor} className="mt-2">
                                {portal?.banner?.title}
                            </PortalInfoTitle>
                            <PortalInfoText color={textColor} style={{ marginBottom: 12 }}>
                                {portal?.banner?.description}
                            </PortalInfoText>
                            <PortalInfoText color={textColor} style={{ fontSize: 12 }}>
                                presents
                            </PortalInfoText>
                            <PortalInfoText color='#ffff00'>
                                MESSAGE FROM GOB AND ORB
                            </PortalInfoText>
                        </>
                    ) : (
                        <UserInfoWrapper>
                            <img src={user.data.node_id} alt="user" />
                            <PortalInfoTitle color={textColor} className="mt-2">
                                Hi {user.data.username}
                            </PortalInfoTitle>
                        </UserInfoWrapper>
                    )}
                </PortalInfoWrapper>

                <AudioDiv>
                    <PlayPauseDiv color={textColor}>
                        {!playing ? (
                            <PlayButtonStyled onClick={() => audio.play()} />
                        ) : (
                            <PauseButtonStyled onClick={() => audio.pause()} />
                        )}
                    </PlayPauseDiv>
                    <ProgressBarDiv color={textColor} ref={progressBar}>
                        <ProgressBarStyled />
                        <ProgressPointStyled ref={progressBarThumb} />
                    </ProgressBarDiv>
                </AudioDiv>
                {!hideGobOrbMessage && (
                    <PortalInfoText color={textColor} style={{ fontSize: 12 }}>
                        {isLogged ? (
                            <>
                                <span style={{ display: "block", margin: 0 }}>Personal audio message</span>
                                <span style={{ display: "block", margin: 0 }}>from Gob and Orb</span>
                            </>
                        ) : 'Music produced by Bisney Helix'}
                    </PortalInfoText>
                )}
                <ImagineImage isMobile={isMobile}>
                    <img src={portal?.banner?.image} alt="banner" onContextMenu={(e) => e.preventDefault()} />
                </ImagineImage>
                {!isMobile && (
                    <ScrollWrapper>
                        <ScrollIcon />
                    </ScrollWrapper>
                )}
            </Block>
        </>
    );
}