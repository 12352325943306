import React, { useEffect } from "react";
import classNames from "classnames";
import { usePlacesWidget } from "react-google-autocomplete";
import { useFormik } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import styled from 'src/theme/styled';
import { useSettings } from "src/contexts/SettingsContext";
import { states } from 'src/constants/states.js';

import styles from "./ShippingForm.module.scss"

const { Config } = require('../../../../config.js')
const googleKey = Config[process.env.REACT_APP_ENV]['google']

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'var(--primary-favorite-color)'
      }
    },
    MuiSelect: {
      select: {
        border: "2px solid rgba(255,255,255,0.7)"
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'var(--primary-favorite-color)'
      },
      dayDisabled: {
        color: 'var(--primary-favorite-color)'
      },
      current: {
        color: 'var(--primary-favorite-color)'
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: 'var(--primary-favorite-color)'
      }
    }
  }
});

const Warning = styled.p`
    color: var(--secondary-text-color);
    font-size: 14px;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: -10px;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
`;

const ZipFormatCustom = ({ ...props }) => (
  <MaskedInput
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
    type='tel'
    {...props}
  />
);

const validationSchema = Yup.object().shape({
  street: Yup.string().required('Street is required!'),
  state: Yup.string().required('State is required!'),
  zip: Yup.string().required('Field is required!')
    .test('Digits only', 'The field should have only digits', value => /^\d+$/.test(value))
    .test('len', 'Must be exactly 5 characters', val => val && val.length === 5)
});

const ShippingForm = ({ update }) => {
  const { isMobile } = useSettings();
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      country: "US",
      street: "",
      state: "",
      zip: "",
      city: ""
    },
    onSubmit: (values) => update(values)
  });

  const { ref } = usePlacesWidget({
    apiKey: googleKey,
    onPlaceSelected: (place) => {
      const streetNumber = place.address_components.find((p) => p.types.includes("street_number"));
      for (let p of place.address_components) {
        if (p.types.includes("route")) {
          formik.setFieldValue("street", [streetNumber?.short_name, p.short_name].join(' '));
        }
        if (p.types.includes("administrative_area_level_1")) {
          formik.setFieldValue("state", p.short_name);
        }
        if (p.types.includes("postal_code")) {
          formik.setFieldValue("zip", p.short_name);
        }
        if (p.types.includes("locality")) {
          formik.setFieldValue("city", p.short_name);
        }
      }
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" }
    }
  });

  useEffect(() => {
    if (formik.dirty) {
      formik.handleSubmit();
    }
  }, [formik.values, formik.dirty]);

  return (
    <ThemeProvider theme={materialTheme}>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.fieldWrapper}>
          <TextField
            fullWidth
            inputRef={ref}
            color="secondary"
            variant="outlined"
            placeholder="Address"
            name="street"
            className={styles.field}
            onChange={formik.handleChange}
            value={formik.values.street}
            onBlur={formik.handleBlur}
          />
        </div>
        {formik?.touched?.street && formik.errors.street && <Warning>{formik.errors.street}</Warning>}
        <div className={styles.fieldWrapper}>
          <TextField
            fullWidth
            color="secondary"
            variant="outlined"
            name="city"
            placeholder="City"
            className={styles.field}
            onChange={formik.handleChange}
            value={formik.values.city}
            onBlur={formik.handleBlur}
          />
        </div>
        {formik?.touched?.city && formik.errors.city && <Warning>{formik.errors.city}</Warning>}
        <div className={styles.fieldWrapper}>
          <TextField
            fullWidth
            color="secondary"
            variant="outlined"
            name="zip"
            placeholder="Postal Code"
            className={styles.field}
            onChange={formik.handleChange}
            value={formik.values.zip}
            onBlur={formik.handleBlur}
            InputProps={{
              inputMode: 'numeric',
              inputComponent: ZipFormatCustom
            }}
          />
        </div>
        {formik?.touched?.zip && formik.errors.zip && <Warning>{formik.errors.zip}</Warning>}
        <div className={styles.fieldWrapper}>
          <TextField
            fullWidth
            select
            color="secondary"
            variant="outlined"
            name="state"
            placeholder="State"
            className={styles.field}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
          >
            {states.map((state) => (
              <MenuItem key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {formik?.touched?.state && formik.errors.state && <Warning>{formik.errors.state}</Warning>}
        <div className={styles.fieldWrapper}>
          <TextField
            disabled
            readOnly
            fullWidth
            variant="outlined"
            name="country"
            placeholder="Country"
            className={styles.field}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.country}
          />
        </div>
        {formik?.touched?.country && formik.errors.country && <Warning>{formik.errors.country}</Warning>}
      </form>
    </ThemeProvider>
  );
};

export default ShippingForm;
