import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import useFetch from 'src/services/useFetch';
import { usePrevious } from "src/utils/hooks";
import { useAuth } from "./AuthContext";
import { sortTokensByNumber, sortTokensByType } from "src/utils/sort-tokens";

const AppContext = createContext<any>({
  tokens: [],
  userTokens: [],
  loadUserTokens: () => false
});

const { get: getUserTokens } = useFetch('/api/routes/users/tokens');
const { get: getTokens } = useFetch('/api/routes/tokens/get');

export const AppProvider = (({ children }: { children: any }) => {
  const [tokensLoaded, setTokensLoaded] = useState(false);
  const { isLogged, userData } = useAuth();
  const [tokens, setTokens] = useState<any[]>([]);
  const [userTokens, setUserTokens] = useState<any[]>([]);
  const prevIsLogged = usePrevious(isLogged);

  const loadTokens = useCallback(async () => {
    try {
      const response = await getTokens();
      if (response?.data) {
        const sortedTokens = sortTokensByNumber(sortTokensByType(response?.data));
        setTokens(sortedTokens);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const loadUserTokens = useCallback(async () => {
    try {
      const response: any = await getUserTokens(`?email=${encodeURIComponent(userData?.email)}`);
      if (response?.data) {
        setUserTokens([
          ...(response?.data?.data || []),
          ...(response?.data?.royalty || []),
          ...(response?.data?.imagination || []),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userData?.email]);

  useEffect(() => {
    if (!tokensLoaded && tokens.length === 0) {
      loadTokens();
      setTokensLoaded(true);
    }
  }, [tokens]);

  useEffect(() => {
    if (!prevIsLogged && isLogged && userTokens.length === 0) {
      loadUserTokens();
    } else if (!isLogged && userTokens.length) {
      setUserTokens([]);
    }
  }, [isLogged, userTokens]);

  return (
    <AppContext.Provider
      value={{
        tokens,
        userTokens,
        loadUserTokens,
      }}
    >
      {children}
    </AppContext.Provider>
  );
});

export const useApp = () => useContext(AppContext);
