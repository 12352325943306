import React from 'react';
import RPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface IProps {
  hanldeChange?: (phone: string) => void;
  name?: string;
  required?: boolean;
  value?: string;
  target?: "chat" | "checkout";
}

// TODO: refactor styles
const styles: any = {
  chat: {
    container: {
      width: 'unset',
      height: 50,
      marginLeft: 48,
      minWidth: 150,
      marginTop: 8,
      marginBottom: 8,
      outline: 'none',
      border: '1px solid #000000',
      borderRadius: 6,
      padding: 0
    },
    input: {
      height: '100%',
      width: '100%',
      outline: 'none',
      border: 'none',
      fontSize: 16
    },
    button: {
      height: '100%',
      background: 'none',
      borderRadius: 6,
      border: 'none'
    }
  },
  checkout: {
    container: {
      width: '100%',
      outline: 'none',
      border: '1px solid #ffffff',
      borderRadius: 4,
      marginBottom: '1rem',
      backgroundColor: 'none'
    },
    input: {
      height: '100%',
      width: 'calc(100% - 32px)',
      marginLeft: 32,
      padding: '18.5px 14px',
      outline: 'none',
      border: 'none',
      background: 'none',
      color: '#ffffff',
      fontSize: 16
    },
    button: {
      height: '100%',
      background: 'none',
      borderRadius: 6,
      border: 'none'
    }
  }
};

export default function PhoneInput({
  hanldeChange = () => { },
  name,
  required = false,
  target = "chat",
  value
}: IProps) {
  return (
      <>
        { process.env.REACT_APP_ENV !== 'production' ?
            <RPhoneInput
                country={'us'}
                countryCodeEditable={false}
                // disableDropdown={true}
                inputProps={{
                  name: name,
                  required: required,
                  autoFocus: false
                }}
                onChange={hanldeChange}
                onlyCountries={['us', 'ua']} // TODO: add more countries
                containerStyle={styles[target].container}
                inputStyle={styles[target].input}
                buttonStyle={styles[target].button}
                value={value}
            /> :
            <RPhoneInput
                country={'us'}
                countryCodeEditable={false}
                // disableDropdown={true}
                inputProps={{
                  name: name,
                  required: required,
                  autoFocus: false
                }}
                onChange={hanldeChange}
                onlyCountries={['us']} // TODO: add more countries
                containerStyle={styles[target].container}
                inputStyle={styles[target].input}
                buttonStyle={styles[target].button}
                value={value}
            />
        }
      </>
  );
}
