import * as layoutActions from '../actions/layout';
import { logOut } from '../actions/auth';
import { handleActions } from 'redux-actions';

let layout = JSON.parse(localStorage.getItem('desktop-layout'));

if (!layout) {
    localStorage.setItem(
        'desktop-layout',
        JSON.stringify({
            right: {
                tokens: true,
                checkout: false
            },
            chat: false
        })
    );
}

const defaultState = layout;

export default handleActions(
    {
        [layoutActions.setDesktopLayoutRight]: (
            state = defaultState,
            action
        ) => {
            let layout = JSON.parse(localStorage.getItem('desktop-layout'));
            layout.right = action.payload;
            localStorage.setItem('desktop-layout', JSON.stringify(layout));
            return {
                ...state,
                right: action.payload,
                chat: false
            };
        },
        [layoutActions.setChatLayout]: (state = defaultState, action) => {
            let layout = JSON.parse(localStorage.getItem('desktop-layout'));
            layout.chat = action.payload;
            layout.right = {
                tokens: false,
                checkout: false
            };
            localStorage.setItem('desktop-layout', JSON.stringify(layout));
            return {
                ...state,
                right: {
                    tokens: false,
                    checkout: false
                },
                chat: action.payload
            };
        },
        [logOut]: (state) => {
            let layout = JSON.parse(localStorage.getItem('desktop-layout'));
            const rightSide = {
                token: false,
                cart: false
            };
            layout.right = rightSide;
            layout.chat = false;
            localStorage.setItem('desktop-layout', JSON.stringify(layout));
            return {
                ...state,
                right: rightSide,
                chat: false
            };
        }
    },
    defaultState
);
