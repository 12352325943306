import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import useFetch from 'src/services/useFetch';
import io from 'socket.io-client';
import {Config} from 'src/config';

interface IFeedContext {
    feed: any[];
    loading: boolean;
    loadFeed: () => void,
    sendMessage: (message: string, feedId: string) => void;
    deleteMessage: (message: any) => void;
    deleteResponse: (response: any) => void;
    toggleLike: (feedId: string, messageId: string, emoji: string) => void;
    toggleResponseLike: (messageId: string, emoji: string, responseId: string) => void;
    loadMessages: (feedId: string, messages: any) => void;
    oneFeed: any;
    getOneFeedById: (feedId: string) => void;
    setOneFeed: (any) => void;
    updateFeedTerms: (feedId: string, userId: string) => void;
}

export const socket = io(Config[process.env.REACT_APP_ENV]['url']);

const FeedContext = createContext<IFeedContext>({
    feed: [],
    oneFeed: false,
    loading: false,
    loadFeed: () => false,
    sendMessage: () => false,
    deleteMessage: () => false,
    deleteResponse: () => false,
    toggleLike: () => false,
    toggleResponseLike: () => false,
    loadMessages: () => false,
    getOneFeedById: () => false,
    setOneFeed: () => false,
    updateFeedTerms: () => false,
});

const {get: getFeed} = useFetch('/api/routes/feed/get');
const {post: postMessage}: any = useFetch('/api/routes/feed/message');
const {post: postLike}: any = useFetch('/api/routes/feed/like');
const {post: postResponseLike}: any = useFetch('/api/routes/feed/response/like');
const {get: getFeedById}: any = useFetch(`/api/routes/feed/get`);

export const FeedProvider = ({children}: { children: any }) => {
    const [feed, setFeed] = useState<any[]>([]);
    const [oneFeed, setOneFeed] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const loadFeed = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getFeed();
            setLoading(false);
            if (response?.data) {
                setFeed(response?.data);
            }
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const updateFeedMessage = useCallback((newMessage) => {
        if (!newMessage || !newMessage.feedId || !newMessage._id) {
            return;
        }

        setOneFeed((prevFeed) => {
            if (!prevFeed || prevFeed?._id !== newMessage.feedId) {
                return prevFeed;
            }

            const messageIndex = prevFeed.messages.findIndex(
                (messageItem) => messageItem?._id === newMessage?._id
            );

            if (messageIndex !== -1) {
                const updatedMessages = [...prevFeed.messages];
                updatedMessages[messageIndex] = newMessage;
                return { ...prevFeed, messages: updatedMessages };
            }

            return {
                ...prevFeed,
                messages: [newMessage, ...prevFeed.messages]
            };
        });
    }, []);



    const updateFeedTerms = useCallback((feedId, userId) => {

        setOneFeed((prevFeed) => {
            if (prevFeed._id !== feedId) {
                return prevFeed;
            }
            const updatedUsersToShow = prevFeed?.gameFull?.usersToShow?.map((user) => {
                if (user._id === userId) {
                    return {...user, isTermsConfirmed: true};
                }
                return user;
            });

            return {
                ...prevFeed,
                gameFull: {
                    ...prevFeed.gameFull,
                    usersToShow: updatedUsersToShow,
                },
            };
        });

    }, []);


    const deleteFeedMessage = (message: any) => {
        const newMessageFeedId = message.feedId;

        setOneFeed((prevFeed) => {
            if (prevFeed?._id !== newMessageFeedId) {
                return prevFeed;
            }
            const feedMessages = prevFeed.messages.filter(
                (messageItem: any) => messageItem?._id !== message._id
            );

            return {
                ...prevFeed,
                messages: feedMessages
            };
        });
    };

    function loadMessages(feedId: string, messages: any) {
        setOneFeed((feedItem) => {
            if (feedItem?._id !== feedId) {
                return feedItem;
            }

            return {
                ...feedItem,
                messages: messages
            };
        });
    }

    function onMessageDelete(message) {
        socket.emit('deleteMessage', message);
    }


    function onResponseDelete(response) {
        socket.emit('deleteResponse', response);
    }

    const initWebSocket = useCallback(() => {
        socket.on('newMessage', (message: any) => {
            updateFeedMessage(message);
        });

        socket.on('messageWasDeleted', (message: any) => [
            deleteFeedMessage(message)
        ]);

        socket.on('newLike', (message: any) => {
            updateFeedMessage(message);
        });

        socket.on('feedCreated', (feed: any) => {
            setFeed((prevState) => [feed, ...prevState]);
        });
    }, []);

    useEffect(() => {
        void loadFeed();
        initWebSocket();
    }, []);

    const sendMessage = useCallback(async (message, feedId) => {
        await postMessage({
            message: message,
            feedId: feedId
        });
    }, []);

    const toggleLike = useCallback(async (feedId, messageId, emoji) => {
        await postLike({
            emoji: emoji,
            feedId: feedId,
            messageId: messageId
        });
    }, []);


    const toggleResponseLike = useCallback(async (messageId, responseId, emoji) => {
        await postResponseLike({
            emoji: emoji,
            messageId: messageId,
            responseId: responseId,
        });
    }, []);


    const getOneFeedById = useCallback(async (feedId) => {
        try {
            setLoading(true);
            const response = await getFeedById(feedId);
            setLoading(false);
            if (response?.data) {
                setOneFeed(response?.data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    return (
        <FeedContext.Provider
            value={{
                feed,
                loading,
                loadFeed,
                sendMessage,
                deleteMessage: onMessageDelete,
                deleteResponse: onResponseDelete,
                loadMessages,
                toggleLike,
                toggleResponseLike,
                oneFeed,
                getOneFeedById,
                setOneFeed,
                updateFeedTerms,
            }}
        >
            {children}
        </FeedContext.Provider>
    );
};

export const useFeed = () => useContext(FeedContext);
