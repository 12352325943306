import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from 'src/routing/routesConfig';
import AuthRoute from 'src/routing/AuthRoute';
import Layout from 'src/components/common/Layout';
import { useGetDynamicRoutes } from './DynamicRoutes';

function RouterContainer() {
  const [platformRoutes, setPlatformRoutes] = React.useState([]);
  const { dynamicRoutes } = useGetDynamicRoutes();

  async function fetchPlatformRoutes() {
    const data = await Promise.resolve(routes);
    setPlatformRoutes(data)
  };

  React.useEffect(() => {
    fetchPlatformRoutes();
  }, [])

  const allRoutes = React.useMemo(() => {
    return [...dynamicRoutes, ...platformRoutes]
  }, [dynamicRoutes, platformRoutes])

  
  if (!dynamicRoutes.length) {
    return null;
  }

  return allRoutes.length > 0 ? (
    <Switch>
      {allRoutes.map((route, i) => {
        return (
          <RouteWithSubRoutes
            key={`${route.path}_${i}`}
            {...route}
          />
        );
      })}
    </Switch>
  ) : (
    <div />
  );
}

export default RouterContainer;

function RouteWithSubRoutes(route) {
  if (route.authRequired) {
    return (
      <AuthRoute
        path={route.path}
        component={(props) => (
          <Layout
            hasNavBar={route.hasNavBar}
            hasBottomBar={route.hasBottomBar}
          >
            <route.component {...props} routes={route.routes} />
          </Layout>
        )}
      />
    );
  }
  
  return (
    <Route
      path={route.path}
      render={(props) => {
        return (
          <Layout
            hasNavBar={route.hasNavBar}
            hasBottomBar={route.hasBottomBar}
          >
            <route.component
              {...props}
              gameType={route.gameType}
              routes={route.routes}
            />
          </Layout>
        );
      }}
    />
  );
}