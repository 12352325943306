module.exports = {
    HUB: "hub",
    FAVORITE_COLOR: "color",
    CREATIVE: "creative",
    IMAGINATION: "imagination",
    PHONE: "phone",
    GREETING_USER: "greeting_user",
    PHONE_VERIFICATION: "phone_verification",
    EMAIL_VERIFICATION: "verifyEmail",
    SHOW_COIN: "showGPC",
    AVATAR: "avatar",
    END: "end",
    MESSAGE: "message",
    POTENTIAL_EARNING: 'potentialEarning',
    REMIND_USER_FOR_PLAY: 'remidUserOfGameInPlay',
    COINS_DISTRIBUTED: 'coinsDistributed'
}
