import { Config } from '../config';

export const getEtherscanLink = () => {
  const ethNetwork = Config[process?.env?.REACT_APP_ENV || 'development']['ethNetwork'];

  switch (ethNetwork) {
    case "mainnet":
      return 'https://etherscan.io';
    default:    
      return 'https://goerli.etherscan.io';
  }
}
