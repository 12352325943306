module.exports = {
    DATA: 'data',
    CHAPTER: 'imagination',
    DRRT: 'royalty',
    TOKEN_TYPE: {
        0: "DRRT",
        1: "IT",
        2: "PORT",
    }
};
