export const TOGGLE_SHOW_USD = 'TOGGLE_SHOW_USD';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const USER_SAVED = 'USER_SAVED';
export const USER_VERIFIED = 'USER_VERIFIED';
export const USER_ONBOARDED = 'USER_ONBOARDED';
export const SAVE_USER_ONBOARDING_INDEX = 'SAVE_USER_ONBOARDING_INDEX';
export const SET_GLOBAL_STATS = 'SET_GLOBAL_STATS';
export const USER_CREATED_GPC_ACCOUNT = 'USER_CREATED_GPC_ACCOUNT';
export const SET_BALANCE = 'SET_BALANCE';

export const SET_DESKTOP_LAYOUT_RIGHT = 'SET_DESKTOP_LAYOUT_RIGHT';
export const SET_DESKTOP_LAYOUT_CHAT = 'SET_DESKTOP_LAYOUT_CHAT';
export const SET_CHAT_LAYOUT = 'SET_CHAT_LAYOUT';
