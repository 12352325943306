export const sortTokensByNumber = (tokens) => {
    return tokens.sort((tokenA, tokenB) => {
        const numberA = tokenA?.number || tokenA?.storyFull?.number || 0;
        const numberB = tokenB?.number || tokenB?.storyFull?.number || 0;

        return numberA > numberB ? 1 : numberA < numberB ? -1 : 0;
    });
};

export const sortTokensByType = (tokens) => {
    return tokens.sort((tokenA, tokenB) => {
        const isAStory = tokenA.type === 'STORY';
        const isBStory = tokenB.type === 'STORY';

        return !isAStory && isBStory ? 1 : isAStory && !isBStory ? -1 : 0;
    });
};
