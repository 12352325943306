import React, { forwardRef, Ref } from 'react';

import {
    Circle,
    Typing
  } from '../ChatMessage.styles';

  const TypingIndicator = forwardRef<HTMLDivElement>((props, ref: Ref<HTMLDivElement>) => {
  return (
    <Typing ref={ref}>
      <Circle />
      <Circle />
      <Circle />
    </Typing>
  );
});

export default TypingIndicator;
