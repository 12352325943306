
import styled from 'styled-components';
import { Form } from 'formik';

export const LoginBox = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #fff;
    align-items: center;
    border: 1px solid #223b95;
    border-radius: 20px;
`;
export const LoginSection = styled.div`
    display: flex;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    font-size: 16px;

    @media screen and (max-width: 1024px) {
        padding: 20px 5%;
    }
`;
export const MemberstackForm = styled(Form)`
    position: relative;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1024px) {
        padding-top: 15px;
    }
`;
export const LoginContainer = styled.div`
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Separator = styled.span`
    border: 0.5px solid #000000;
    width: 50%;
    margin: 30px 0;

    @media screen and (max-width: 1024px) {
        margin: 10px 0;
    }
`;

export const ConnectWalletButton = styled.button`
    background: #ffff00;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    padding: 8px;
    width: 100%;
    max-width: 247px;
    &:hover {
        background: rgba(255, 255, 0, 0.3);
    }
`;

export const Warning = styled.div`
    color: red;
    margin-bottom: 15px;
    margin-top: 0px;
    max-width: 247px;
    font-size: 10px;
`;

export const WalletText = styled.span`
    padding-top: 10px;
    opacity: 0.7;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #000000;
`;

export const StyledImg = styled.img`
    height: 70px;
    width: 70px;

    @media screen and (max-width: 1024px) {
        width: 48px;
        height: 48px;
    }
`;