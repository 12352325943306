module.exports = {
    UTILITY: 'utility',
    EXPERIENCE: 'experience',
    RESOURCE: 'resource',
    CHAT: 'chat',
    MAIN: 'imagine',
    GAME: 'game',
    PROFILE: 'profile',
    POINTS: 'points',
    DEFAULT: 'default'
};
