import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import coreTheme from 'src/theme/CoreTheme/coreTheme';
import { IAppReducer } from './appTypes';

const sortedGpcUserStorage = localStorage.getItem('gpc-user');
const gpcUserStorage = sortedGpcUserStorage
    ? JSON.parse(sortedGpcUserStorage)
    : null;
// const gpcUserStorage = JSON.parse(localStorage.getItem('gpc-user'));
const gameToSubmitCounter = localStorage['games-to-submit'] || 0;
const gameToVoteCounter = localStorage['games-to-vote'] || 0;
const sortedValue = localStorage.getItem('user-message-counter');
const usersMessagesCounter = sortedValue ? JSON.parse(sortedValue) : false;
// const usersMessagesCounter = JSON.parse(localStorage.getItem('user-message-counter')) || false;
const savedCart = localStorage.getItem('cart') || '[]';

// feature/fix-package-dependencies
const initialState: IAppReducer = {
    gameOpenedFromSubmission: false,
    gameOpenedFromVoting: false,
    gameOpenedFromRanks: false,
    openedChat: false,
    gamesToSubmit: gameToSubmitCounter,
    gamesToVote: gameToVoteCounter,
    usersMessagesCounter: JSON.parse(usersMessagesCounter) === true,
    showUsd: localStorage.getItem('showUsd') || false,
    gameSubmission: '',
    globalStats: {
        coins_paid: '0',
        games_in_play: '...loading',
        users_engaged: '...loading',
        hub_in_lead: '...loading',
        most_popular_color: '...loading'
    },
    gpcUser: gpcUserStorage,
    balance: 0,
    mainToken: {},
    ethPrice: 0,
    isColorActive: false,
    cart: JSON.parse(savedCart) || [],
    userTokens: [],
    user: {
        data: {
            coins: 0,
            goBorbBalance: null
        }
    }
};

const app_slice = createSlice({
    name: 'appSlice',
    initialState: initialState,
    reducers: {
        userCashedOut(state, action: PayloadAction<any>) {
            state.user.data.coins = 0;
        },
        userHasNotifications(state, action: PayloadAction<any>) {
            localStorage.setItem(
                'games-to-vote',
                JSON.stringify(action.payload.gamesToVoteCounter)
            );
            localStorage.setItem(
                'games-to-submit',
                JSON.stringify(action.payload.gamesToSubmitCounter)
            );
            // state.app.gamesToSubmit = action.payload.gamesToSubmitCounter;
            // state.app.gamesToVote = action.payload.gamesToVoteCounter;
            return {
                ...state,
                gamesToSubmit: action.payload.gamesToSubmitCounter,
                gamesToVote: action.payload.gamesToVoteCounter
            };
        },
        userMessageCounter(state, action: PayloadAction<any>) {
            localStorage.setItem(
                'user-message-counter',
                JSON.stringify(action.payload.usersNotifications)
            );

            // state.app.usersMessagesCounter = action.payload.usersNotifications;
            return {
                ...state,
                usersMessagesCounter: action.payload.usersNotifications
            };
        },
        setUserTokens(state, action: PayloadAction<any>) {
            localStorage.setItem(
                'ic-user-tokens',
                JSON.stringify(action.payload)
            );
            // state.app.userTokens = action.payload;
            return {
                ...state,
                userTokens: action.payload
            };
        },
        gameOpenedFromSubmission(state, action: PayloadAction<any>) {
            // localStorage.setItem('game-opned-from-submission', true);
            // localStorage.setItem('game-opned-from-ranks', false);
            // localStorage.setItem('opned-chat', false);
            // localStorage.setItem('game-opned-from-voting', false);

            // state.app.gameOpenedFromSubmission = true;
            // state.app.gameOpenedFromVoting = false;
            // state.app.gameOpenedFromRanks = false;
            // state.app.openedChat = false;

            return {
                ...state,
                gameOpenedFromSubmission: true,
                gameOpenedFromVoting: false,
                gameOpenedFromRanks: false,
                openedChat: false
            };
        },
        gameOpenedFromNothing(state, action: PayloadAction<any>) {
            // localStorage.setItem('game-opned-from-submission', false);
            // localStorage.setItem('game-opned-from-ranks', false);
            // localStorage.setItem('opned-chat', false);
            // localStorage.setItem('game-opned-from-voting', false);

            // state.app.gameOpenedFromSubmission = false;
            // state.app.gameOpenedFromVoting = false;
            // state.app.gameOpenedFromRanks = false;
            // state.app.openedChat = false;

            return {
                ...state,
                gameOpenedFromSubmission: false,
                gameOpenedFromVoting: false,
                gameOpenedFromRanks: false,
                openedChat: false
            };
        },
        gameOpenedFromVoting(state, action: PayloadAction<any>) {
            // localStorage.setItem('game-opned-from-submission', false);
            // localStorage.setItem('game-opned-from-ranks', false);
            // localStorage.setItem('opned-chat', false);
            // localStorage.setItem('game-opned-from-voting', true);

            // state.app.gameOpenedFromSubmission = false;
            // state.app.gameOpenedFromVoting = true;
            // state.app.gameOpenedFromRanks = false;
            // state.app.openedChat = false;
            return {
                ...state,
                gameOpenedFromSubmission: false,
                gameOpenedFromVoting: true,
                openedChat: false,
                gameOpenedFromRanks: false
            };
        },
        gameOpenedFromRanks(state, action: PayloadAction<any>) {
            // localStorage.setItem('game-opned-from-submission', false);
            // localStorage.setItem('game-opned-from-voting', false);
            // localStorage.setItem('opned-chat', false);
            // localStorage.setItem('game-opned-from-ranks', true);

            // state.app.gameOpenedFromSubmission = false;
            // state.app.gameOpenedFromVoting = false;
            // state.app.gameOpenedFromRanks = false;
            // state.app.openedChat = true;

            return {
                ...state,
                gameOpenedFromSubmission: false,
                gameOpenedFromVoting: false,
                openedChat: false,
                gameOpenedFromRanks: true
            };
        },
        openedChat(state, action: PayloadAction<any>) {
            // localStorage.setItem('game-opned-from-submission', false);
            // localStorage.setItem('game-opned-from-voting', false);
            // localStorage.setItem('game-opned-from-ranks', false);
            // localStorage.setItem('opned-chat', true);

            // state.app.gameOpenedFromSubmission = false;
            // state.app.gameOpenedFromVoting = false;
            // state.app.gameOpenedFromRanks = false;
            // state.app.openedChat = true;

            return {
                ...state,
                gameOpenedFromSubmission: false,
                gameOpenedFromVoting: false,
                gameOpenedFromRanks: false,
                openedChat: true
            };
        },
        setShowUsd(state, action: PayloadAction<any>) {
            state.showUsd = !state.showUsd;
        },
        setGameSubmission(state, action: PayloadAction<any>) {
            state.gameSubmission = action.payload;
        },
        setGlobalStats(state, action: PayloadAction<any>) {
            state.globalStats.most_popular_color =
                coreTheme.colors[
                    action.payload.most_popular_color.toLowerCase()
                ];
        },
        setBalance(state, action: PayloadAction<any>) {
            state.user.data.goBorbBalance = action.payload;
        },
        setMainTokenInfo(state, action: PayloadAction<any>) {
            state.mainToken = action.payload;
        },
        setEthPrice(state, action: PayloadAction<any>) {
            state.ethPrice = action.payload.price;
        },
        updateCart(state, action: PayloadAction<any>) {
            localStorage.setItem('cart', JSON.stringify(action.payload));
            state.cart = action.payload;
        },
        toggeColorActive(state, action: PayloadAction<any>) {
            state.isColorActive = action.payload;
        }
    }
});
export const {
    userCashedOut,
    userHasNotifications,
    userMessageCounter,
    setUserTokens,
    gameOpenedFromSubmission,
    gameOpenedFromNothing,
    gameOpenedFromVoting,
    gameOpenedFromRanks,
    openedChat,
    setShowUsd,
    setGameSubmission,
    setGlobalStats,
    setBalance,
    setMainTokenInfo,
    setEthPrice,
    updateCart,
    toggeColorActive
} = app_slice.actions;
export default app_slice.reducer;
