import coreTheme from 'src/theme/CoreTheme/coreTheme';
// import { colors } from 'src/constants/colors';
// import { hubs } from 'src/constants/hubs';
// import { creativities } from 'src/constants/creativity';
const colors = [];
const hubs = [];
const creativities = [];

class UserService {
    isUserLoggedIn = () => {
        let userLocalStorage = JSON.parse(localStorage.getItem('user'));

        if (
            !userLocalStorage ||
            userLocalStorage === 'undefined' ||
            !userLocalStorage.user.isLogged
        )
            return false;

        return true;
    };

    getColorToUse = (color) => {
        if (color && (color.toLowerCase() === 'white' ||
            color === '#C4C3C4' ||
            color.toLowerCase() === 'yellow' ||
            color.toLowerCase() === 'orange' ||
            color === '#FFD700' ||
            color === coreTheme.colors.userYellow ||
            color.toUpperCase() === coreTheme.colors.white ||
            color.toUpperCase() === coreTheme.colors.shortWhite ||
            color === coreTheme.colors.yellow ||
            color === coreTheme.colors.yellowPortal ||
            color === coreTheme.colors.orange
        )) {
            return '#000';
        }
        return '#FFF';
    };

    getSvgIconFill = (color) => {
        if (color && color === '#040403') return '#FFF';
        else return '#000';
    };

    groupByRole = (items, key) => {
        return items.reduce(
            (result, item) => ({
                ...result,
                [item[key]]: [...(result[item[key]] || []), item]
            }),
            {}
        );
    };
    isEmail = (email) => {
        let myRegexp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return myRegexp.exec(email);
    };

    getUserTokens = (userColor, userHub, userCreativity) => {
        const defaultObj = { image: '', name: '', price: 0 };
        const color = colors.filter(
            (c) => c?.name?.toLowerCase() === userColor.toLowerCase()
        );
        const hub = hubs.filter((h) => h.name === userHub);
        const creativity = creativities.filter(
            (c) => c.name.toLowerCase() === userCreativity.toLowerCase()
        );

        return {
            color: color[0] ? color[0] : defaultObj,
            hub: hub[0] ? hub[0] : defaultObj,
            creative: creativity[0] ? creativity[0] : defaultObj
        };
    };
}
export default new UserService();
