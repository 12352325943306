import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import numbro from 'numbro';
import { ICButton } from 'src/components/common/ICButton/ICButton';
import { otherPortals } from 'src/constants/otherPortals';
import { ExperiencePortals } from 'src/constants/experiencePortals';
import { ResourcePortals } from 'src/constants/resourcePortals';
import { UtilityPortals } from 'src/constants/utilityPortals';
import { ReactComponent as EthToken } from 'src/assets/portals/eth-token.svg';
import { ReactComponent as DollarToken } from 'src/assets/portals/dollar-token.svg';
import { ReactComponent as MainToken } from 'src/assets/portals/goborb/goborb-icon.svg';
import { ReactComponent as ScrollIcon } from 'src/assets/svg/scroll-icon.svg';

// import { ReactComponent as ContactIcon } from 'src/assets/svg/contact.svg';
// import { ReactComponent as FaqIcon } from 'src/assets/svg/faq.svg';
import { ReactComponent as TermsIcon } from 'src/assets/svg/terms.svg';
import { ReactComponent as PrivacyIcon } from 'src/assets/svg/privacy.svg';

import * as PortalConst from 'src/constants/portalDetails';
import PortalDetails from 'src/components/common/PortalDetails';
import UserService from 'src/services/user';
import ImaginePortal from './ImaginePortal';
import styled from 'src/theme/styled';
import portalTypes from 'src/constants/portalTypes';
import useFetch from 'src/services/useFetch';
import { connect } from 'react-redux';
import EthAddress from 'src/components/common/EthAddress';
import { useSettings } from 'src/contexts/SettingsContext';

const { get: getTokenForPortal } = useFetch(
  `/api/routes/products/getTokenForPortal`
);
interface IWrapper {
  color: string;
  scroll?: {
    thumb: string;
    background: string;
  };
  isMobile?: boolean;
  backgroundImage?: string;
}

const StyledDollarSign = styled(DollarToken) <IWrapper>`
    & path {
        fill: ${(props) => props.color};
    }
`;

const Wrapper = styled.div<IWrapper>`
    min-height: 100%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    background: ${(props) => props.color};
    ${(props) => props?.backgroundImage ?
    `background-image: ${props?.backgroundImage};background-repeat: no-repeat;background-size: cover;` :
    ''};
`;

const PortalImageDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 60%;
    min-height: 60%;
    width: 100%;
`;

const PortalImageWrapper = styled.div`
  height: 350px;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const PortalImage = styled.img`
    height: 90%;
    width: 90%;
    object-fit: contain;
`;

const EmptyDiv = styled.div`
    min-height: 50%;
    max-height: 50%;
`;

const Block = styled.div<IWrapper>`
    margin: 30px 50px;
    height: 100%;
    font-size: 14px;
    line-height: 16px;

    color: ${(props) => props?.color};
    & > div {
        margin-bottom: 8px;
    }

    @media (max-width: 750px) {
      margin: 20px;
      min-height: 100%;
      height: auto;
    }
`;

const PortalInfoTextSeparator = styled.div`
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 20px;
    border-bottom: ${(props) =>
    props?.color ? `0.5px solid ${props.color}` : '0.5px solid black'};
    color: ${(props) => props?.color};
`;

const PortalInfoText = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    color: ${(props) => props?.color};
    display: flex;
    align-items: center;
`;

const PortalTokenUrl = styled.a`
    display: block;
    word-break: break-word;
    margin-top: 8px;
    text-decoration: none !important;
    cursor: pointer;
    font-size: 12px;
    color: ${(props) => `${props.color}`} !important;
`;

const PortalTokenAddress = styled.a`
    text-transform: uppercase;
    display: block;
    word-break: break-word;
    margin-top: 8px;
    text-decoration: none !important;
    cursor: pointer;
    color: ${(props) => `${props.color}`} !important;
`;

const Sparator = styled.div`
    border: ${(props) =>
    props?.color ? `1px solid ${props.color}` : '1px solid black'};
    margin: 20px 0;
`;
const SparatorLight = styled.div`
    border: ${(props) =>
    props?.color ? `0.5px solid ${props.color}` : '0.5px solid black'};
    margin: 20px 10px;
`;

const BellowSeparatorContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40%;
`;

const PortalInfo = styled.div`
    margin-top: 20px;

    & > svg {
      max-width: 60px;
    }
`;

const ScrollWrapper = styled.div`
    width: 100%;
    height: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const TokenSectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
    & > svg {
        width: 20px;
        height: 20px;
    }
`;

const PortalInfoTitle = styled.p`
    margin-bottom: 6px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--primary-text-color);
`;

const PortalInfoSubTitle = styled.p`
    margin-bottom: 6px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--primary-text-color);
`;

const TokenIconWrapper = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    padding: 6px;
    
    & > img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const RedButton = styled.button`
  background: #EB3225;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.025em;
  padding: 8px 12px;
  border: none;
  outline: none;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
  cursor: pointer;
`;

const RightsText = styled.p<{ colorToUse?: string }>`
    color: ${(props) => (props?.colorToUse ? props?.colorToUse : '#fff')};
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
`
const BottomDivWrapper = styled.div`
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const PrivacyTermsWrapper = styled.div`
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      height: 32px;
      cursor: pointer;
      margin: 0 12px;
    }
`;

const enableScrollIcon = false;

function Portal({
  portalPassed,
  user,
  mainTokenInfo,
  isLogged,
  ethPrice,
  most_popular_color
}: any) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isMobile, setShowTerms, setShowPrivacy,
    // setShowFaq, setShowContact
  } = useSettings();
  const [currentPortal, setCurrentPortal]: any = useState({});
  const [tokenData, setTokenData]: any = useState({});
  const [color, setColor] = useState('');
  const [iconColor, setIconColor] = useState<string>('#000');
  const [isImagine, setIsImagine] = useState(false);
  const [portalDetails, setPortalDetails] = useState<any>(PortalConst.imaginecouncil);
  const [isGame, setIsGame] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);
  const AllPortals = [
    ...ExperiencePortals,
    ...ResourcePortals,
    ...UtilityPortals,
    ...otherPortals
  ];
  useEffect(() => {
    if (portalPassed && portalPassed?.key) {
      setCurrentPortal(portalPassed);
    } else {
      let portal = AllPortals.find((p) =>
        pathname.includes(p?.base)
      );
      if (portal) setCurrentPortal(portal);
      else
        setCurrentPortal(otherPortals.find((p) => p.key === 'landing'));
    }
  }, [portalPassed]);

  useEffect(() => {
    if (currentPortal && currentPortal?.key) {
      setColor(currentPortal?.color ? currentPortal?.color : '');
    }

    setIsImagine(
      currentPortal &&
      (currentPortal?.type === portalTypes.MAIN ||
        currentPortal?.type === portalTypes.DEFAULT)
    );
    setIsGame(currentPortal && currentPortal?.key === 'ftc');

    // remove '-' in portal key
    const keyToSearch = currentPortal?.key?.replace('-', '');

    if (currentPortal?.key === portalTypes.DEFAULT) {
      setPortalDetails(PortalConst.imaginecouncil);
    } else if (PortalConst[keyToSearch]) {
      let portalSections = PortalConst[keyToSearch];
      if (currentPortal?.type === 'resource') {
        portalSections.sections = [{
          subheader: 'CURRENTLY WE ARE BUILDING CHAPTER TOKENS FOR STORY ONE - FREE THE CARTOON',
          description: `Chapter tokens unlock access to own physical and digital collectibles from the story’s chapter from props, wardrobe, music, to even parts of set design. All collectibles are created by brand portals.`,
        }]
      } else if (currentPortal?.type === 'experience') {
        portalSections.sections = [{
          subheader: 'CURRENTLY WE ARE IMAGINING CHAPTER TOKENS FOR STORY ONE - FREE THE CARTOON',
          description: `Chapter tokens unlock access to own physical and digital collectibles from the story’s chapter from props, wardrobe, music, to even parts of set design. All collectibles are created by brand portals.`,
        }]
      }
      setPortalDetails(portalSections);
    }

    if (currentPortal?.key) {
      setIconColor(
        UserService.getSvgIconFill(currentPortal.banner.background)
      );
      setTokenLoading(true);
      setTokenData({});
      getTokenForPortal(`?portal=${currentPortal.key}`)
        .then(({ token, nftData }) => {
          setTokenData({
            ...token,
            ...nftData
          });
          setTokenLoading(false);
        })
        .catch((error) => {
          setTokenLoading(false);
        });
    }
  }, [currentPortal]);

  const portalToken = useMemo(() => {
    let type = 'Non Fungible Token';
    let title = 'Portal Token (PORT)';
    let erc = 'ERC 721';

    if (isGame) {
      title = 'Digital Royalty Rights Token (DRRT)';
    } else if (portalPassed.key === 'goborb' || portalPassed === 'goborb') {
      title = '';
      type = 'Fungible Token';
      erc = 'ERC 20'
    }

    return {
      title: title,
      type: type,
      erc: erc
    }
  }, [isGame, portalPassed]);

  const imagineColor = useMemo(() => {
    if (!isLogged) {
      return '#fff';
    }
    return "var(--primary-text-color)";
  }, [isLogged, user]);

  const filteredDetailsSections = useMemo(() => {
    const sectionsCategory = portalDetails[isLogged ? "authorized" : "unauthorized"]
    return sectionsCategory[isMobile ? "mobile" : "desktop"]
  }, [portalDetails, isLogged, isMobile])

  return isImagine ? (
    <Wrapper
      isMobile={isMobile}
      scroll={{
        background: currentPortal?.scroll?.background ? currentPortal?.scroll?.background : '',
        thumb: currentPortal?.scroll?.thumb ? currentPortal?.scroll?.thumb : ''
      }}
      color={isLogged ? "var(--primary-favorite-color)" : currentPortal?.banner?.background}
    >
      <ImaginePortal
        isLogged={isLogged}
        user={user}
        isMobile={isMobile}
        portal={currentPortal}
        textColor={imagineColor}
      />
      <PortalDetails
        color={imagineColor}
        header={portalDetails?.header}
        sub_header={portalDetails?.sub_header}
        text={portalDetails?.text}
        link={portalDetails?.link}
        sections={filteredDetailsSections}
        isMobile={isMobile}
        isLogged={isLogged}
      />
      <BottomDivWrapper>
        <PrivacyTermsWrapper>
          <TermsIcon onClick={() => setShowTerms(true)} />
          <PrivacyIcon onClick={() => setShowPrivacy(true)} />
        </PrivacyTermsWrapper>
        <RightsText colorToUse={imagineColor}> ©{new Date().getFullYear()} ALL RIGHTS RESERVED</RightsText>
      </BottomDivWrapper>
    </Wrapper>
  ) : (
    <Wrapper
      isMobile={isMobile}
      scroll={{
        background: currentPortal?.scroll?.background ? currentPortal?.scroll?.background : '',
        thumb: currentPortal?.scroll?.thumb ? currentPortal?.scroll?.thumb : ''
      }}
      color={
        currentPortal?.banner?.background ? currentPortal?.banner?.background : ''
      }
      backgroundImage={currentPortal?.banner?.backgroundImage}
    >
      <Block color={color ? color : ''}>
        {currentPortal?.banner?.title && (
          <PortalInfoTitle color={currentPortal?.banner?.color} className="mt-2">
            {currentPortal?.banner?.title}
          </PortalInfoTitle>
        )}
        {currentPortal?.banner?.description && (
          <PortalInfoSubTitle color={currentPortal?.banner?.color}>
            {currentPortal?.banner?.description}
          </PortalInfoSubTitle>
        )}
        {currentPortal?.banner?.message && (
          <PortalInfoSubTitle className='mb-3' color={currentPortal?.banner?.color}>
            {currentPortal?.banner?.message}
          </PortalInfoSubTitle>
        )}
        {!currentPortal?.banner?.backgroundImage && (
          <PortalImageDiv>
            <PortalImageWrapper>
              <PortalImage src={currentPortal?.banner?.image} />
            </PortalImageWrapper>
          </PortalImageDiv>
        )}
        {currentPortal?.banner?.backgroundImage && !isGame && (
          <EmptyDiv />
        )}
        {isMobile && (isGame || currentPortal?.type === 'experience') && !tokenLoading && (
          <RedButton onClick={() => history.push(`${currentPortal.footerIcons[0]?.path}`)}>
            {isGame ? (tokenData?.isOwner ? 'Enter Token' : 'Buy Token') : 'View Token'}
          </RedButton>
        )}
        <Sparator color={currentPortal?.banner?.color} />
        <BellowSeparatorContent>
          <PortalInfo className="w-50 d-flex flex-column">
            {currentPortal?.banner?.icon}
            {currentPortal?.url && (
              <PortalTokenUrl
                color={currentPortal?.banner?.color}
                href={currentPortal?.url}
                target="_blank"
              >
                {currentPortal?.url}
              </PortalTokenUrl>
            )}
            <PortalInfoTextSeparator
              color={currentPortal?.banner?.color}
              className="mt-2"
            >
              <div style={{ fontSize: 16 }}>
                {tokenData.name || currentPortal?.banner?.title}
              </div>
              {currentPortal?.banner?.subtitle && (
                <div style={{ fontSize: 16, lineHeight: 1.5 }}>
                  {currentPortal?.banner?.subtitle}
                </div>
              )}
              {tokenData.address && (
                <PortalTokenAddress>
                  <EthAddress
                    address={tokenData.address}
                    color={currentPortal?.banner?.color}
                    iconColor={currentPortal?.banner?.color}
                    short
                  />
                </PortalTokenAddress>
              )}
            </PortalInfoTextSeparator>
            <PortalInfoText
              color={currentPortal?.banner?.color}
            >
              {tokenData.description ||
                currentPortal?.banner?.description}
            </PortalInfoText>
          </PortalInfo>
          {currentPortal?.banner?.token && (
            <PortalInfo className="pl-4 w-50 d-flex flex-column justify-content-start">
              <div className='d-flex'>
                {tokenData?.icon && (
                  <TokenIconWrapper>
                    <img src={tokenData.icon} />
                  </TokenIconWrapper>
                )}
                <div style={{ marginLeft: 10, color: currentPortal?.banner?.color, width: 'calc(100% - 35px)' }}>
                  <div style={{ fontSize: 16, marginBottom: 4 }}>{tokenData?.symbol}</div>
                  <div style={{ fontSize: 12 }}>
                    {portalToken.title}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    {portalToken.type}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    {portalToken.erc}
                  </div>
                </div>
              </div>
              <SparatorLight color={currentPortal?.banner?.color} />
              <TokenSectionDiv>
                <MainToken />
                <PortalInfoText
                  color={currentPortal?.banner?.color}
                  className="pl-2"
                >
                  {numbro(mainTokenInfo?.price ? (tokenData?.price || 0) / (mainTokenInfo?.price || 0) : 0).format({
                    spaceSeparated: true,
                    thousandSeparated: true,
                    mantissa: 2
                  })}
                </PortalInfoText>
              </TokenSectionDiv>
              <TokenSectionDiv>
                <EthToken />
                <PortalInfoText
                  color={currentPortal?.banner?.color}
                  className="pl-2"
                >
                  {numbro(ethPrice ? (tokenData?.price || 0) / ethPrice : 0).format({
                    spaceSeparated: true,
                    thousandSeparated: true,
                    mantissa: 2
                  })}
                </PortalInfoText>
              </TokenSectionDiv>
              <TokenSectionDiv>
                <StyledDollarSign color={iconColor} />
                <PortalInfoText
                  color={currentPortal?.banner?.color}
                  className="pl-2"
                >
                  {numbro(tokenData.price || 0).format({
                    spaceSeparated: true,
                    thousandSeparated: true,
                    mantissa: 2
                  })}
                </PortalInfoText>
              </TokenSectionDiv>
            </PortalInfo>
          )}
        </BellowSeparatorContent>
        {!isMobile && enableScrollIcon && (
          <ScrollWrapper>
            <ScrollIcon />
          </ScrollWrapper>
        )}
      </Block>
      <PortalDetails
        color={currentPortal?.banner?.color || most_popular_color}
        header={portalDetails?.header}
        sub_header={portalDetails?.sub_header}
        text={portalDetails?.text}
        sections={filteredDetailsSections}
        isMobile={isMobile}
        isLogged={isLogged}
        forceColor
      />
      {isMobile && (currentPortal?.type === 'resource' || currentPortal?.type === 'experience') && (
        <BottomDivWrapper>
          <ICButton
            background={currentPortal?.banner?.color}
            color={currentPortal?.banner?.background}
            onClick={() => {
              if (currentPortal?.type === 'resource') {
                history.push('/landing/promo');
              } else {
                history.push(currentPortal?.footerIcons[0]?.path)
              }
            }}
          >
            {currentPortal?.type === 'resource' ? 'View More' : 'View Token'}
          </ICButton>
        </BottomDivWrapper>
      )}
    </Wrapper>
  );
};

export default connect(
  (state: any) => ({
    mainTokenInfo: state.app.mainToken,
    ethPrice: state.app.ethPrice,
    isLogged: state.auth?.user?.isLogged,
    user: state.auth?.user,
    most_popular_color: state?.app?.globalStats?.most_popular_color
  }),
  {}
)(Portal);
